import { useSelector } from "react-redux";
import styles from "./Greetings.module.css";
import PersonalizedAnnounce from "./PersonalizedAnnounce";
import useWindowDimensions from "../../hooks/useScreenDetails";

export const GreetingContainer = (props) => {
  const { dictionary } = props.data || [];
  const gqlData = useSelector((state) => state.gqlData);
  console.log("greetings gqldata >>>#", gqlData);
  const currHour = new Date().getHours();
  const welcomeMsg =
    currHour < 12
      ? dictionary[0].value
      : currHour < 18
      ? dictionary[1].value
      : dictionary[2].value;
  return (
    <div className={styles.messageBox}>
      <div>
        <p>{welcomeMsg}</p>
        <h2>
          {
            gqlData
              ? gqlData.profile
                ? gqlData.profile.legalFirstName
                  ? `${gqlData.profile.legalFirstName}`
                  : ""
                : ""
              : ""
          }
        </h2>
      </div>
    </div>
  );
};

const Greetings = (props) => {
  const [isMobile] = useWindowDimensions();
  return (
    <div>
      <div className={styles.messageContainer}>
      {!isMobile && <GreetingContainer {...props} />}
        {!isMobile && <PersonalizedAnnounce {...props} />}
      </div>
      {isMobile && <PersonalizedAnnounce {...props} />}
    </div>
  );
};

export default Greetings;
