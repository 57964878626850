import { useEffect, useRef, useState } from "react";
import styles from "./JobOpportunitiesFilterModal.module.css";

import { Checkbox} from "@mgmresorts/mgm-ui";

// import JobOpportunitiesFilterCheckBoxLocation from "./JobOpportunitiesFilterCheckboxLocation";
// import JobOpportunitiesFilterCheckBoxCategory from "./JobOpportunitiesFilterCheckBoxCategory";

// import JobOpportunitiesFilterCheckBox from "./JopOpportunitiesFilterCheckbox";
import { useQuery } from "@apollo/client";
import { apiMiddlewareClient } from "../../features/api-wrapper/apiMiddlewareWrapper";
import { GET_JOBOPP_DETAILS, GET_JOBOPP_FILTER } from "../../features/api-wrapper/apiQueries";
// import moment from "moment";
import Loader from "../../components/global/Loader";
import APIError from "../../pages/ErrorHandling/APIError";

const JobOpportunitiesFilterModal = (props) => {
  const [selectedProperties, setSelectedProperties] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const formatDate = (date) => {
    console.log("date ssss", date);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
    const day = String(date.getDate()).padStart(2, "0");
    console.log("year month day", `${year}-${month}-${day}`);
    return `${year}-${month}-${day}`;
  };
  // const[today,setToday]=useState("");
  const todayDate = new Date();
  const lastWeek = new Date(todayDate);
  lastWeek.setDate(lastWeek.getDate() - 7);
  // setToday(formatDate(todayDate));
  const useOnUpdate = (callback, deps) => {
    const isFirst = useRef(true);
    useEffect(() => {
      if (!isFirst.current) {
        callback();
      }
    }, deps);
  
    useEffect(() => {
      isFirst.current = false;
    }, []);
  };

  useEffect(() => {
    if (props.propList ) {
      setSelectedProperties(props.propList);
    }
    if(props.propList==undefined  && props.defaultCount !==0){
      setSelectedProperties(props.allPropData);
    }
   
    // if(props.propList==undefined && props.defaultCount ==0){
    //   setSelectedProperties(props.defaultAllAPIData?.getDynamicFilterCategories.location.map((item)=>item.wid));
    // }
  }, [props.propList]);
  useEffect(() => {
    if (props.catList) {
      setSelectedCategories(props.catList);
    }
    if(props.catList==undefined && props.defaultCount !==0){
      setSelectedCategories(props.defaultCategory.split("!"));
    }
    // if(props.catList==undefined && props.defaultCount ==0){
    //   setSelectedCategories("");
    // }
  }, [props.catList]);

  const [dateRange, setDateRange] = useState(4);
  const [startDate, setStartDate] = useState(props.startDate);
  const no_properties_found =props.data?.dictionaryLables?.no_properties_found;
  const no_categories_found =props.data?.dictionaryLables?.no_categories_found;
  
  const [isChecked, setIsChecked] = useState(false);

  const propertiesString = selectedProperties?.join("!");
  let categoriesString = selectedCategories?.join("!");
  // const [selectClick, setSelectClick] = useState(false);
  // const handleSelectClick = () => {
  //   setSelectClick(!selectClick);
  // };
  // console.log("handleSelectClick", handleSelectClick);
  // const [selectCatClick, setSelectCatClick] = useState(false);
  // const handleSelectCategoryClick = () => {
  //   setSelectCatClick(!selectCatClick);
  // };
  // console.log("handleSelectCategoryClick", handleSelectCategoryClick);

  console.log("start date in filters ", startDate, dateRange);

  // const posting_data_label = props.data?.dictionaryLables?.posting_date_label;
  // const timeLabel1 = props.data?.dictionaryLables?.day_label;
  // const timeLabel2 = props.data?.dictionaryLables?.week_label;
  // const timeLabel3 = props.data?.dictionaryLables?.month_label;
  // const timeLabel4 = props.data?.dictionaryLables?.all_posting_label;
  const property_label = props.data?.dictionaryLables?.property_label;
  const job_category_label = props.data?.dictionaryLables?.job_category_label;
  // const [radioClicked, setRadioClicked] = useState(false);
  // console.log("radio clicked", radioClicked);
  // const handleDateRangeClick = (evt) => {
  //   setDateRange(evt.target.value);
   
  //   setSelectedProperties([" "]);
  //   setSelectedCategories([" "]);
  //   setRadioClicked(true);
    
  // };
  const [oneMonthAgo, setOneMonthAgo] = useState("");
  const [oneDayAgo, setOneDayAgo] = useState("");
  const [oneWeeksAgo, setOneWeeksAgo] = useState("");
  
useEffect(()=>{
  if(props.filterClicked){
    setSelectedProperties([""]);
    setSelectedCategories([""]);
    setStartDate(" ");
  }

},[props.filterClicked])
  
  
  useOnUpdate(() => {
    setSelectedProperties([""])
    setSelectedCategories([""])
    setStartDate(" ")
    setDateRange("4")
    // console.log('startdate',startDate)
    // console.log('testing clear clicked')
  }, [props.clearClicked]);
  useEffect(() => {
    const currentDate = new Date();

    // Calculate 1 month ago
    const dateOneMonthAgo = new Date(currentDate);
    dateOneMonthAgo.setMonth(dateOneMonthAgo.getMonth() - 1);

    // Calculate 1 day ago
    const dateOneDayAgo = new Date(currentDate);
    dateOneDayAgo.setDate(dateOneDayAgo.getDate() - 1);

    const oneWeekAgo = new Date(currentDate);
    oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);
    // Convert to yyyy-mm-dd format

    setOneMonthAgo(formatDate(dateOneMonthAgo));
    setOneDayAgo(formatDate(dateOneDayAgo));
    setOneWeeksAgo(formatDate(oneWeekAgo));

    if (dateRange === "1") {
      console.log("entered 1");
      // props.handleStartDate(formattedDate);
      setStartDate(oneDayAgo);
    }
    if (dateRange === "2") {
      console.log("entered 2");
      // props.handleStartDate("");
      setStartDate(oneWeeksAgo);
    }
    if (dateRange === "3") {
      console.log("entered 3");
      // props.handleStartDate(formattedMonth);
      setStartDate(oneMonthAgo);
    }
    if (dateRange === "4") {
      console.log("entered 4");
      // props.handleStartDate(" ");
      setStartDate(" ");
    }
    // if(props.applyFiltersClicked){
    //   props.handleApiStartDate(startDate)
    // }
    console.log("formatted date", oneDayAgo, oneMonthAgo, oneWeeksAgo);
  }, [dateRange]);

  console.log("start date in filter is ", startDate);
  // const dateOptions = [
  //   { name: timeLabel1, value: "1" },
  //   { name: timeLabel2, value: "2" },
  //   { name: timeLabel3, value: "3" },
  //   { name: timeLabel4, value: "4" },
  // ];

 
  //   const handleEventClick = () => {
  //     console.log("event clicked");
  //   };
  const { loading, error, data } = useQuery(GET_JOBOPP_FILTER, {
    client: apiMiddlewareClient,
    fetchPolicy: "no-cache",
    variables: {
      getDynamicFilterCategoriesStartDate2:
        " ",
      getDynamicFilterCategoriesProperty2: " ",
      getDynamicFilterCategoriesJobCategory2: categoriesString,
    },
  });
  // useEffect(() => {
  //   if (selectClick) {
  //     setSelectedProperties(
  //       data.getDynamicFilterCategories.location.map((id) => id.wid)
  //     );
  //   }
  //   // else{
  //   //   setSelectedProperties([]);
  //   // }
  // }, [selectClick]);

  //   useEffect(()=>{
  // props.handleDefaultCat(data.getDynamicFilterCategories.defaultJobCategory.wid);
  //   },[]);
  const {
    error: errorcategory,
    loading: loadingCategory,
    data: CategoryAPIData,
  } = useQuery(GET_JOBOPP_FILTER, {
    client: apiMiddlewareClient,
    fetchPolicy: "no-cache",
    variables: {
      getDynamicFilterCategoriesStartDate2:" ",
      getDynamicFilterCategoriesProperty2: propertiesString,
      getDynamicFilterCategoriesJobCategory2: " ",
    },
  });

  const checkboxClassname = "globalCheckbox"


  
  // let catTotal = AllAPIData?.getDynamicFilterCategories.jobCategory.map(
  //   (item) => item.wid
  // );
  useEffect(() => {
    
    if (
      props.applyClicked &&
      (categoriesString == "" || categoriesString == " ")
    ) {
      const categoryCount =
        CategoryAPIData.getDynamicFilterCategories.jobCategory.length;
      props.handleCategoryCount(categoryCount);
    }
    if (props.applyClicked && categoriesString !== "" && categoriesString !== " ") {

      let newCat = categoriesString;
console.log("categories string",categoriesString);
      if (categoriesString.trim().startsWith("!")) {
        newCat = categoriesString.trim().slice(1);

      }

      let parts = newCat.split("!");

      const count = parts.length;

      props.handleCategoryCount(count);
    }
    // if(!isChecked){
    //   setSelectedProperties(props.property.split("!"));
    // }
  }, [props.applyClicked]);

  // useEffect(() => {
  //   if (selectCatClick) {
  //     setSelectedCategories(
  //       CategoryAPIData.getDynamicFilterCategories.jobCategory.map(
  //         (id) => id.wid
  //       )
  //     );
  //   }
  //   // else{
  //   //   setSelectedCategories([]);
  //   // }
  // }, [selectCatClick]);
  useEffect(() => {
    if (props.applyClicked) {
      props.handleStartClicked(startDate==""?oneWeeksAgo:startDate);
      props.handleDateRange(dateRange);
      props.applyClickedHandler(false);
      let areArraysOfObjectsEqual=((arr1, arr2)=>{
        if (arr1.length !== arr2.length) return false;
        const sortedArr1 = [...arr1].sort((a, b) => JSON.stringify(a).localeCompare(JSON.stringify(b)));
        const sortedArr2 = [...arr2].sort((a, b) => JSON.stringify(a).localeCompare(JSON.stringify(b)));
     
        // Compare each object in the arrays
        return sortedArr1.every((obj, index) => {
            return JSON.stringify(obj) === JSON.stringify(sortedArr2[index]);
        });
      })

      if(areArraysOfObjectsEqual(data?.getDynamicFilterCategories.location,CategoryAPIData?.getDynamicFilterCategories.location)){
        // console.log("entered half",data);
        props.handlePropertyHandle(data?.getDynamicFilterCategories.location.map((item)=>item.wid).join("!"));
      }
      else{
        // console.log("entered half not",data);
        
      props.handlePropertyHandle(
        propertiesString == "" || propertiesString==" "
          ? " "
          : propertiesString
      );
      }
     
      if(areArraysOfObjectsEqual(data?.getDynamicFilterCategories.jobCategory,CategoryAPIData?.getDynamicFilterCategories.jobCategory)){
   
        props.handleCateogoryHandler(data?.getDynamicFilterCategories.jobCategory.map((item)=>item.wid).join("!"));
      }
      else{
        
      props.handleCateogoryHandler(
        categoriesString == "" ||  categoriesString == " "
          ? " "
          : categoriesString
      );
      }
    }
  }, [props.applyClicked]);
  // useEffect(()=>{
  // if(props.applyClicked){
  //   if(props.propList==undefined && propertiesString==""){
  //     console.log("entered .....",props.defaultAllAPIData?.getDynamicFilterCategories.location.map((item)=>item.wid));
  //     setSelectedProperties(props.defaultAllAPIData?.getDynamicFilterCategories.location.map((item)=>item.wid));
  //   }
  //   if(props.catList==undefined && categoriesString==""){
  //     setSelectedCategories(props.defaultAllAPIData?.getDynamicFilterCategories.jobCategory.map((item)=>item.wid));
  //   }
  // }
  // },[props.applyClicked])
  const {  data: jobDetails } = useQuery(GET_JOBOPP_DETAILS, {
    fetchPolicy: "no-cache",
    client: apiMiddlewareClient,
    variables: {
      startDate: " ",
      property: propertiesString==""?" ":propertiesString,
      jobCategory: categoriesString==""?" ":categoriesString,
    },
  });
  
  
  const handleEventClick = (e) => {
    setIsChecked(!isChecked);
    let newSelectedProperties;
    if (selectedProperties.includes(e.target.value)) {
      newSelectedProperties = selectedProperties.filter(
        (id) => id !== e.target.value
      );
    } else {
      // newSelectedProperties.push(props.property);
      newSelectedProperties = [...selectedProperties, e.target.value];
    }
    setSelectedProperties(newSelectedProperties);
    
  };
  const handleCategoryChange = (e) => {
    // const newCategories = selectedCategories.includes(e.target.value)
    //   ? selectedCategories.filter((id) => id !== e.target.value)
    //   : [...selectedCategories, e.target.value];
    // setSelectedCategories(newCategories);
    let newCategories;
    if (selectedCategories.includes(e.target.value)) {
      newCategories = selectedCategories.filter((id) => id !== e.target.value);
    } else {
      // newSelectedProperties.push(props.property);
      newCategories = [...selectedCategories, e.target.value];
    }
    setSelectedCategories(newCategories);
    
  };
  useEffect(()=>{
   
props.handleCount(jobDetails?.getJobPostingsList.length);
  },[jobDetails]);

  if (loading || loadingCategory) {
    props.handleFilterModalLoading(true);
    return <Loader />;
  } else {
    props.handleFilterModalLoading(false);

  }
  if (error || errorcategory) return <APIError error={error} />;

  return (
    <div className={styles.jobCardQuickViewContainer}>
      {/* <section>
        <div className={styles.title}>{posting_data_label}</div>

        <div className={styles.dateContainer}>
          {dateOptions.map((item, id) => {
            return (
              <Radio
                onChange={(e) => handleDateRangeClick(e)}
                key={id}
                id={id}
                variant="small"
                value={item.value}
                checked={dateRange === item.value}
                label={item.name}
                aria-label={item.name}
              />
            );
          })}
         
        </div>
      </section> */}
      <section>
        <div className={styles.title}>{property_label}</div>
        {/* <div className={styles.checkboxContainer}>
        <div className={styles.checkbox}> <Checkbox
                onChange={handleSelectClick}
                checked={selectClick}
                variant="small"
                id={1}
                value={"select"}
                label="Select all"
              /></div>
          </div>*/}
        <div className={styles.checkboxContainer}>
          {data.getDynamicFilterCategories.location.length==0?no_properties_found:data.getDynamicFilterCategories.location.map((item, index) => {
            // <JobOpportunitiesFilterCheckBoxLocation
            //   filter={filter}
            //   setFilter={setFilter}
            //   key={index}
            //   item={item}
            // />item.

            return (
              <div key={index} className={styles.checkboxWrapper}>
                {" "}
                <Checkbox
                  className={`${checkboxClassname} ${(selectedProperties?.includes(item.wid)) }`}
                  onChange={handleEventClick}
                  checked={selectedProperties?.includes(item.wid)}
                  variant="small"
                  id={`${index}-prop`}
                  value={item.wid}
                  label={
                    `${item.name.replace("&amp;", "&")}` +
                    "  " +
                    `(${item.count})`
                  }
                />
              </div>
            );
          })}
        </div>
      </section>
      <section>
        <div className={styles.title}>{job_category_label}</div>

        {/* <div className={styles.checkboxContainer}>
        <div className={styles.checkbox}> <Checkbox
                onChange={handleSelectCategoryClick}
                checked={selectCatClick}
                variant="small"
                id={1}
                value={"select"}
                label="Select all"
              /></div>
          
        </div>*/}
        <div className={styles.checkboxContainer}>
          {CategoryAPIData?.getDynamicFilterCategories.jobCategory.length==0?no_categories_found:CategoryAPIData?.getDynamicFilterCategories.jobCategory.map(
            (item, index) => {
              // <JobOpportunitiesFilterCheckBoxLocation
              //   filter={filter}
              //   setFilter={setFilter}
              //   key={index}
              //   item={item}
              // />
              return (
                <div className={styles.checkboxWrapper} key={index}>
                  {" "}
                  <Checkbox
                  className={`${checkboxClassname} ${selectedCategories.includes(item.wid)}`}
                    onChange={handleCategoryChange}
                    checked={selectedCategories.includes(item.wid)}
                    variant="small"
                    id={`${index}-cat`}
                    value={item.wid}
                    label={
                      `${item.name.replace("&amp;", "&")}` +
                      "  " +
                      `(${item.count})`
                    }
                  />
                </div>
              );
            }
            // (
            //   <JobOpportunitiesFilterCheckBoxProperty
            //     filter={filter}
            //     setFilter={setFilter}
            //     key={index}
            //     item={item}
            //   />
            // )
          )}
        </div>
      </section>
    </div>
  );
};

export default JobOpportunitiesFilterModal;
