// import React from 'react';
import { useQuery } from "@apollo/client";
import { useDispatch } from "react-redux";
import { updateGqlData } from "../../data/gqlDataReducer";
import { GET_ONLY_PROFILE_DETAILS } from "../../features/api-wrapper/apiQueries";
import { apiMiddlewareClient } from "../../features/api-wrapper/apiMiddlewareWrapper";
import APIError from "../../pages/ErrorHandling/APIError";
import useFetchData from "../../hooks/useFetchData";
import { setNotification } from "../../data/notification-slice";
import { authActions } from "../../data/authentication-slice";
import { useEffect } from "react";
import React from "react";


function GraphQLComponent() {
  const dispatch = useDispatch();

  const { loading, error, data } = useQuery(GET_ONLY_PROFILE_DETAILS, {
    client: apiMiddlewareClient,
  });
  console.log('data_profile >>>#',data);
  const { data: systemError } = useFetchData("", "globals");

  const languages = "en-us|es|tl|zh-chs".split("|");
  let preferredLanguage = data?.profile?.preferredLanguage
    ? data?.profile?.preferredLanguage.toLowerCase()
    : "en-us";
  if (!languages.includes(preferredLanguage)) {
    preferredLanguage = "en-us";
  }
  let newData = {};
  if(data){
  Object.assign(newData, JSON.parse(JSON.stringify(data)));
  }
  if (preferredLanguage && newData && newData.profile) {
    newData.profile.preferredLanguage = preferredLanguage;
  }

  dispatch(updateGqlData(newData));
  

  useEffect(() => {
    if (systemError && systemError.data) {
      dispatch(setNotification(systemError.data));
    }
  }, [systemError]);

  useEffect(()=>{
    console.log('data_profile 2 >>>#',data);
    if(data && data.profile && typeof(data.profile.workdayDown)!='undefined')
    {
      console.log("data.profile.workdayDown >>>#",data.profile.workdayDown)
      dispatch(authActions.setWorkDayDownState(data.profile.workdayDown));
    }
   
    
  },[data])

  if (loading) return <></>;
  if (error) return <APIError error={error} />;

  return <div></div>;
}

export default React.memo(GraphQLComponent);
