import { CenterModal } from "@mgmresorts/mgm-ui";
import { useEffect } from "react";
import RecognitionReGiContent from "./RecognitionReGiContent";
import styles from "./RecognitionReGiModal.module.css";

const RecognitionReGiModal = (props) => {
  const closeModal = () => {
    // e.currentTarget.tagName.toLowerCase() == "button" &&
    props.closeModal(false);
  };

  // // recheck this
  useEffect(() => {
    //hide scrollbar
    document.body.style.height = "100vh";
    document.body.style.overflow = "hidden";
    //Test case : comment it | fix: remove extra space from footer of modal
    document.getElementsByTagName("dialog")[0].style.height = "min-content";
    document.getElementsByTagName("dialog")[0].style.maxWidth = "776px";

    return () => {
      document.body.style.overflow = "visible";
    };
  }, []);

  return (
    <CenterModal
      title={
        <span style={{ fontSize: "24px", padding: "14px" }}>
          {props.heading}
        </span>
      }
      open
      size="medium"
      onClose={(e) => closeModal(e)}
      className={styles.filterModal}
    >
      <RecognitionReGiContent
        {...props}
        fname={
          props.type == "rGiven"
            ? props.feedbackToName.replace(/;/g, ",")
            : props.feedbackFromName
        }
      />
    </CenterModal>
  );
};
export default RecognitionReGiModal;
