import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isAuthenticated: false,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setIsAuthendicated(state, action) {
      state.isAuthenticated = action.payload;
    },
    setIsPageUnderMaintananace(state,action){
      state.showSiteMaintanance = action.payload;
    },
    setWorkDayDownState(state, action)
    {
      state.workdayApiState = action.payload;
    },
    setBadgeData(state,payload)
    {
      state.badgeData = payload;
    }
  },
});

export const authActions = authSlice.actions;
export default authSlice;
