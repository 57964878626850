import moment from "moment";
import "moment/locale/es";
import styles from "./FeaturedTile.module.css";
// import { Link } from "react-router-dom";
import InnerHtml from "../../../components/dynamic/InnerHtml";
import { useSelector } from "react-redux";
//import useFetchData from "../../../hooks/useFetchData";
import ChooseCta from "../../../hooks/ChooseCta";

const FeaturedTile = (props) => {
  const { images, newsSummary, newsTitle, newsPublishedDate, leoLink } =
    props.data;
  const globalProperties = useSelector(
    (state) => state.globalNotification?.globalProperties
  );
  const language = useSelector(
    (state) => state.gqlData?.profile?.preferredLanguage
  );

  if (language) {
    moment.locale(language);
  }
  const dateNum = new Date(newsPublishedDate).getMonth();
  const publishedDate = `${globalProperties?.short_month[dateNum]} ${moment(
    newsPublishedDate
  ).format("D, YYYY")}`;

  //const { data: csGlobalData } = useFetchData("", "globals");
  const csGlobalData = useSelector((state) => state.globalNotification);

  return (
    <div className={styles.tile}>
      <div className={styles.image}>
        <img src={images[0].imageURL} alt="tile image" />
      </div>
      <div className={styles.content}>
        <div className={styles.dateFormat}>{publishedDate}</div>
        <h3>{newsTitle}</h3>
        <InnerHtml description={newsSummary} style={styles.description} />
        {leoLink && (
          <ChooseCta
            iconLeft="arrow-new-window"
            data={{
              label: csGlobalData?.globalProperties?.learn_more,
              url: leoLink,
              openIn: "new_window",
            }}
            size="small"
            type="secondary"
          />
        )}
      </div>
    </div>
  );
};

export default FeaturedTile;
