import {
  Button,
  CardContainer,
  CenterModal,
  TextLink,
} from "@mgmresorts/mgm-ui";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import EDRMenuBanner from "../../assets/images/EDRMenuBanner.png";
import useWindowDimensions from "../../hooks/useScreenDetails";
import HeroText from "../common/HeroText";
import CTAButton from "./CTAButton";
import style from "./EDRLandingPage.module.css";
import HeroBanner from "./HeroBanner";
import UploadEDRModal from "./UploadEDRModal";

const EDRLandingPage = (props) => {
  console.log("Props", props);
  const [showPopup, setShowPopup] = useState(false);
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [isMobile] = useWindowDimensions();
  const [sharePointUrl, setSharePointUrl] = useState();
  const globalProperties = useSelector(
    (state) => state.globalNotification?.globalProperties
  );

  const onPopupClose = () => {
    setShowPopup(false);
  };

  const onSuccessPopupClose = () => {
    setShowSuccessPopup(false);
  };

  const edrLandingObj = {
    image: EDRMenuBanner,
    heading: "EDR Menu",
  };

  const EDRMenu = {
    heading: "Manage Your Menu",
    description: "Follow the instructions below to upload your menu.",
  };

  const onSetSuccessModal = () => {
    setShowPopup(false);
    setShowSuccessPopup(true);
  };

  const modalFooterArea = (
    <div className={style.modalFooterContainer}>
      <Button
        className="globalDarkBlueBtn"
        variant="primary"
        size="large"
        onClick={() => {
          onSuccessPopupClose();
        }}
      >
        Close
      </Button>
    </div>
  );

  useEffect(() => {
    if (
      globalProperties &&
      globalProperties.edr_menu_sharepoint_url &&
      globalProperties.edr_menu_sharepoint_url.length > 0
    ) {
      const urlEndPoints = globalProperties.edr_menu_sharepoint_url;
      urlEndPoints.forEach((obj) => {
        const endpoints = obj.split("|");
        if (process.env.REACT_APP_ENV == endpoints[0] && obj !== "") {
          setSharePointUrl(endpoints[1]);
        }
      });
    }
  }, [globalProperties]);

  return (
    <div className={`${style.EDRLandingPageContainer} col-12`}>
      <HeroBanner data={edrLandingObj} />
      <div className={`mt-4 ${style.heroComponent}`}>
        <HeroText data={EDRMenu} />
        <div
          className={
            !isMobile ? style.uploadDesktopWrapper : style.uploadMobWrapper
          }
        >
          <CTAButton
            size="small"
            type="primary"
            style={style.uploadMenuBtn}
            openModal={() => {
              setShowPopup(true);
            }}
            data={{
              label: "Upload Menu",
              url: "",
              type: "primary",
              openIn: "modal",
            }}
          ></CTAButton>
        </div>
      </div>
      <div className={style.instructionContainer}>
        <CardContainer variant="explicit">
          <h6 className="mb-3">Instructions:</h6>
          <ol className={style.instructionPoints}>
            <li>
              Click the <b>Upload Menu</b> button
            </li>
            <li>
              Choose the <b>Property Name, Start Date</b> and <b>End Date</b>{" "}
            </li>
            <li>
              <b>Upload a PDF</b> of your menu{" "}
            </li>
            <li>
              Click <b>Next.</b> You will see a Success message.
            </li>
            <li> To upload a new menu, repeat steps 1-4</li>
          </ol>
          <p className={style.notes}>
            <span>NOTE: </span>To access the folder with your menus, click{" "}
            <TextLink
              href={sharePointUrl ? sharePointUrl : ""}
              target={sharePointUrl ? "_blank" : "_self"}
            >
              here.
            </TextLink>
          </p>
        </CardContainer>
      </div>
      <>
        {showPopup && (
          <UploadEDRModal
            onClose={onPopupClose}
            responseEDR={onSetSuccessModal}
            isOpen={showPopup}
          />
        )}

        <div className={style.successEDRContainer}>
          <CenterModal
            open={showSuccessPopup}
            onClose={onSuccessPopupClose}
            title="Menu Upload Successful"
            size="small"
            actions={modalFooterArea}
          >
            <div className={style.successDescription}>
              Your EDR menu has been uploaded successfully and will now be
              available to access and view on My MGM. Thank you!
            </div>
          </CenterModal>
        </div>
      </>
    </div>
  );
};

export default EDRLandingPage;
