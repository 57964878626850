
import styles from "./InternalSystemOthers.module.css"
import GetDynamicComponent from "../../hooks/GetDynamicComponent";
import GetRightRailComponent from "../../hooks/GetRightRailComponent";
import { useMemo } from "react";
const InternalSystemOthers = (props) => {
    console.log('props @@@@', props);
    const { data } = props;
    const pageHeaderBlockItems = useMemo(() => {
      return data.filter(
        (item) => item.block === "hero" || item.block === "in_page_alert"
      );
    }, [data]);
    const pageBlockItems = useMemo(() => {
        return data.filter((item) => item.block === "page_block");
    }, [data]);
    const rightRailBlockItems = useMemo(() => {
        return data.filter((item) => item.block === "right_rail_block");
    }, [data]);
    return (<div className={styles.cHolder}>
        {pageHeaderBlockItems.map((item, index) => (
        <section className={styles.headerBlocks} key={index}>
          <GetDynamicComponent
            comp={item.key}
            data={item.data}
            error={item.error}
          />
        </section>
      ))}
        <section className={styles.pageContent}>
            <section className={styles.leftContent}>
            {pageBlockItems &&
            pageBlockItems.map((item, index) => (
              <section key={index}>
                <GetDynamicComponent
                  comp={item.key}
                  data={item.data}
                  error={item.error}
                />
              </section>
            ))}
            </section>
            <section className={styles.rightContent}>
            {rightRailBlockItems.map((item, index) => (
            <section key={index}>
              <GetRightRailComponent
                comp={item.key}
                data={item.data}
                error={item.error}
              />
            </section>
          ))}
            </section>
        </section>

    </div>)
}
export default InternalSystemOthers;