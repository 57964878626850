import { useState } from "react";
import Modal from "../global/ui/Modal";
import InnerHtml from "./InnerHtml";
import ChooseCta from "../../hooks/ChooseCta";
import styles from "./OneColumnV2.module.css";

// SavingsPlan401K_NtEn_L
const OneColumnV2 = (props) => {
  let [showPopup, setShowPopup] = useState(false);
  let [selectedModal, setSelectedModal] = useState();

  const openModal = (data) => {
    if (data != null && data != undefined) {
      setSelectedModal({
        heading: data.heading,
        body: data.content,
      });
    } else {
      setSelectedModal({ heading: "", body: "" });
    }
    setShowPopup(true);
  };

  const onPopupClose = () => {
    setShowPopup(false);
  };
  console.log('props.data >>>',props.data);
  return (
    <div className={styles.oneColumnV2}>
      <div className={styles.notEnrolledLContainer}>
        <h2>
          {props.data?.leftColumnHeading}
        </h2>
        <ul>
          {props?.data?.leftReference.map((d, index) => (
            <li key={"oneCol" + index}>
              <h5>{d?.heading}</h5>
              <p className={styles.content}>
                <InnerHtml
                  description={d?.description}
                  // hash={props.data?.$?.description}
                />
              </p>
              {d?.enable_cta && (
                <div className={styles.buttonHolder}>
                  {/* #CS: may be needs to update in contentstack file or by CS team .. openIn vs open_in  */}
                  <ChooseCta
                    // data={{ ...d.cta}}
                    data={{ ...d.cta, openIn: d.cta.open_in }}
                    size="small"
                    type="text_link"
                    openModal={openModal}
                  />
                </div>
              )}
            </li>
          ))}
        </ul>
      </div>

      {showPopup && (
        <Modal onClose={onPopupClose} heading={selectedModal.heading}>
          <InnerHtml description={selectedModal.body} />
        </Modal>
      )}
    </div>
  );
};

export default OneColumnV2;
