import InnerHtml from "../dynamic/InnerHtml";
import styles from "./NewHireAccess.module.css";

const NewHireAccess = (props) => {
  const { icon, heading, description } = props.newHireAccess[0] || {};
  return (
    <>
      {props.newHireAccess && (
        <section
          className={`${styles.siteNotification} ${
            props.isMaintenAvailable ? styles.marginSpace : ""
          }`}
        >
          <div className={styles.container}>
            <div className={styles.leftContent}>
              <div className={styles.heading}>
                <div
                  className={styles.exclamationMark}
                  style={{
                    background: `url("${icon?.url}") no-repeat`,
                  }}
                ></div>
                <h3>{heading}</h3>
              </div>
              <InnerHtml description={description} style={styles.innerHtml} />
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default NewHireAccess;
