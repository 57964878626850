import moment from "moment";
import Show_Stopper from "../../assets/badges/Show_Stopper.svg";
import Win_Together from "../../assets/badges/Win_Together.svg";
import Inspire_Excellence from "../../assets/badges/Inspire_Excellence.svg";
import Captivate_Our_Audience from "../../assets/badges/Captivate_Our_Audience.svg";
import Planet_Protector from "../../assets/badges/Planet_Protector.svg";
import Champion_Inclusion from "../../assets/badges/Champion_Inclusion.svg";

export const getBadgeIcon = (iconName) => {
  switch (iconName) {
    case "Show_Stopper.svg":
      return Show_Stopper;
    case "Win_Together.svg":
      return Win_Together;
    case "Inspire_Excellence.svg":
      return Inspire_Excellence;
    case "Captivate_Our_Audience.svg":
      return Captivate_Our_Audience;
    case "Planet_Protector.svg":
      return Planet_Protector;
    case "Champion_Inclusion.svg":
      return Champion_Inclusion;
  }
};

export const toTitleCase = (str) => {
  return (
    str &&
    str.replace(
      /\w\S*/g,
      (text) => text.charAt(0).toUpperCase() + text.substring(1).toLowerCase()
    )
  );
};

export const formatDate = (date, language) => {
  moment.locale(language);
  return moment(date).format("MMM D, Y");
};
