import { ApolloClient, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { getLocalStoreValue } from "../../utils/localStore";
import createUploadLink from 'apollo-upload-client/createUploadLink.mjs'

const uploadLink = createUploadLink({
  uri: `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_MIDDLEWARE_GRAPHQL}`,
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from session storage if it exists
  //const token = sessionStorage.getItem("token");
  //const token = process.env.REACT_APP_BEARER_TOKEN;
  const token = getLocalStoreValue("token");
  const apigee_token = getLocalStoreValue("apigee_token");
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: apigee_token ? `Bearer ${apigee_token}` : "",
      jwt: token ? token : "",
    },
  };
});

export const apiMiddlewareUploadClient = new ApolloClient({
  link: authLink.concat(uploadLink),
  cache: new InMemoryCache(),
});
