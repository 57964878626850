
import { useNavigate } from "react-router";
import mgmofferstyle from "./MgmNews.module.css";

const MgmNewsCard = (props) => {
  const navigate = useNavigate();
  return (
    <div className={mgmofferstyle.imageHolder}>
      <div
        className={`${mgmofferstyle.offerCard} ${
          mgmofferstyle[props.salaried]
        }`}
      >
        <div className={mgmofferstyle.cardimg}>
          <img src={props.image} />
        </div>
        <div className={mgmofferstyle.cardSubHolder}>
          <div className={mgmofferstyle.cardDate}>{props.header}</div>
          <div className={mgmofferstyle.cardalignment}>
            <div className={mgmofferstyle.cardTitle}>{props.cardtitle}</div>
            <div className={mgmofferstyle.cardBtn}>{props.content}</div>
          </div>
          <div className={mgmofferstyle.learnmorepara}>
            <button
              className={mgmofferstyle.learnmorebtn}
              onClick={() => {
                navigate(props.readMoreUrl?.value,  {
                  state: { news: props.news, isExternalNews: true },
                });
              }}
            >
              {props.csGlobalData?.globalProperties.read_more}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MgmNewsCard;
