import { Icon, TextLink } from "@mgmresorts/mgm-ui";
import styles from "./RecognitionReGi.module.css";
import React from "react";
import "react-alice-carousel/lib/alice-carousel.css";
import InitialsAvatar from "react-initials-avatar";
import "react-initials-avatar/lib/ReactInitialsAvatar.css";
import { getBadgeIcon, toTitleCase } from "./RecognizationUtility";

const RecognitionReGiItem = ({
  heading,
  date,
  content,
  iconData,
  cs_global,
  isReadMore,
  isNew,
  cs_text,
  openModalFn,
}) => {

  const newHeading = heading && heading?.split(";");
  return (
    <div>
      <div className={`${styles.rTilesBig} ${styles.boxStyling}`}>
        <div className={`${styles.rContent1}`}>
          {newHeading.length > 1 ? (
            <div className={styles.rTileIcon}>
              <div className={`${styles.threePeople}`}>
                <Icon
                  name="three-people-plus"
                  variant="filled"
                  className={`${styles.threePeopleImg}`}
                />
              </div>
            </div>
          ) : (
            <div className={styles.rTileIcon}>
              <InitialsAvatar name={heading} />
            </div>
          )}

          <div style={{ width: "100%", height: "50px", display: "flex" }}>
            <div style={{ alignContent: "center" }}>
              {newHeading.length > 1 ? (
                <h5>{heading.replace(/;/g, ",")}</h5>
              ) : (
                <h5>{heading}</h5>
              )}
              <p className={styles.subHeading}>{date}</p>
            </div>
          </div>
        </div>

        <div className={`${styles.rContent2}`}>
          <p className={styles.content} style={{ marginTop: "12px" }}>
            {content}
          </p>
          <div className={styles.rTilesBigFooter}>
            <div>
              {isReadMore && (
                <>
                  {/* Read More */}
                  <TextLink
                    className={styles.readMore}
                    onClick={(event) => {
                      event.preventDefault();
                      openModalFn(event);
                    }}
                  >
                    {cs_global?.read_more}
                  </TextLink>
                  {/* <Icon
                      className={styles.downIcon}
                      name="chevron-down"
                      size="small"
                    /> */}
                </>
              )}
            </div>
            <div className={styles.footerRight}>
              {iconData && <>
                <img src={getBadgeIcon(iconData?.icon)} />
                <p className={styles.footerRightText}>
                  {toTitleCase(iconData?.value)}
                </p>
              </>}

            </div>
          </div>
        </div>
        {isNew && (
          <div className={styles.tag}>
            {/* <p>New Recognition</p> */}
            <p>{cs_text.new_recognition_label}</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default React.memo(RecognitionReGiItem);
