// import { Icon } from "@mgmresorts/mgm-ui";
import React, { useRef } from "react";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import "react-initials-avatar/lib/ReactInitialsAvatar.css";
import useWindowDimensions from "../../hooks/useScreenDetails";
import styles from "./RecognitionReGi.module.css";
import RecognitionReGiItem from "./RecognitionReGiItem";
import { formatDate } from "./RecognizationUtility";
import CarouselArrows from "./CarouselArrows";

const RecognitionReGiList = ({
  data,
  type,
  openModalFn,
  setModalData,
  cs_text,
  cs_link,
  cs_global,
  badgesA,
  language,
}) => {
  const carousel = useRef(null);
  const [isMobile] = useWindowDimensions(500);

  const responsive = {
    0: { items: 1 },
    568: { items: 2 },
    // 1024: { items: 3 },
  };

  return (
    <div className={styles.carousel}>
      {/* arrow */}
      {/* temporary - marginTop */}
      <div className={styles.carouselNav}>
        {!isMobile && (
          <p className={`${styles.viewAllBtn} ${styles.linkButton}`}>
            <a href={cs_link[0]?.link?.href} target="_blank" rel="noreferrer">
              {cs_link[0]?.link?.title}
            </a>
          </p>
        )}

        <div className={`col-6`}>
          {!isMobile && data && data?.length > 2 && (
            <>
              <CarouselArrows
                title={{ prev: cs_global?.previous, next: cs_global?.next }}
                onClick={(e, state) => {
                  state == "prev"
                    ? carousel?.current?.slidePrev(e)
                    : carousel?.current?.slideNext(e);
                }}
                totalRecords={data}
                itemsPerSlide="2"
              />
              {/* <>
                <button
                  className={`${styles.carouselNavArrowP}`}
                  // disabled={carousel?.current?.isPrevSlideDisabled}
                  title={cs_global?.previous}
                  onClick={(e) => {
                    carousel?.current?.slidePrev(e);
                  }}
                >
                  <Icon name="chevron-left" size="small" />
                </button>
                <button
                  className={`${styles.carouselNavArrowN}`}
                  title={cs_global?.next}
                  onClick={(e) => {
                    carousel?.current?.slideNext(e);
                  }}
                >
                  <Icon name="chevron-right" size="small" />
                </button>
              </> */}
            </>
          )}
        </div>
      </div>

      {/* if we need arrow even if less than 2 items */}
      {/* // ( // <div className={`${styles.carousalTopSpacing}`} /> */}
      {data?.length && (
        <div className={styles.recogReceivedList}>
          <AliceCarousel
            key="carousel"
            activeIndex={3}
            slideToIndex={4}
            responsive={responsive}
            infinite={false}
            ref={carousel}
            controlsStrategy="responsive"
            buttonsDisabled={true}
            centerMode={false}
            dotsDisabled={isMobile ? false : true}
            mouseTracking
            keyboardNavigation={true}
            items={
              data &&
              data.map((d, index) => {
                let iconData =
                  badgesA &&
                  badgesA
                    .map((badge) => badge.name == d.badgeID && badge)
                    .filter((d) => d)[0];

                return (
                  <div
                    className={` ${
                      (index - carousel?.current?.state.currentIndex) % 2 == 0
                        ? styles.tileSpaceL
                        : styles.tileSpaceR
                    }`}
                    key={index}
                    // key={data.newsId}
                  >
                    <RecognitionReGiItem
                      key={index}
                      heading={
                        type == "Received"
                          ? d.feedbackFromName
                          : d.feedbackToName
                      }
                      date={formatDate(d.feedbackDate, language)}
                      content={d.feedback}
                      iconData={iconData}
                      cs_global={cs_global}
                      isReadMore={true}
                      isNew={d.isNew}
                      cs_text={cs_text}
                      openModalFn={(event) => {
                        event.preventDefault();
                        openModalFn();
                        setModalData({ ...d, iconData });
                      }}
                    />
                  </div>
                );
              })
            }
          />
          {/* {isMobile && (
            <p className={`${styles.viewAllBtn} ${styles.linkButton}`}>
              <a href={cs_link[0]?.link?.href} target="_blank" rel="noreferrer">
                {cs_link[0]?.link?.title}
              </a>
            </p>
          )} */}
        </div>
      )}
      <div>
        {" "}
        {data?.length && isMobile && (
          <p className={`${styles.viewAllBtn} ${styles.linkButton}`}>
            <a href={cs_link[0]?.link?.href} target="_blank" rel="noreferrer">
              {cs_link[0]?.link?.title}
            </a>
          </p>
        )}
      </div>
    </div>
  );
};

export default React.memo(RecognitionReGiList);
