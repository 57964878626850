import styles from "./BookmarkPopup.module.css";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import useWindowDimensions from "../../hooks/useScreenDetails";

const BookmarkPopup = (props) => {
  const { heading, manage, no_data_text } = props.csData || {};
  const gqlData = useSelector((state) => state.gqlData);
  const { profile } = gqlData;
  const { favourites } = profile;
  const latestFavourites = [...favourites].reverse();
  const [isMobile] = useWindowDimensions();

  return (
    <div
      className={`${styles.container} ${
        isMobile ? styles.setMWidth : styles.setDWidth
      }`}
      style={props.style}
    >
      <div className={styles.bookmarkHeader}>
        <h3 className={`text-left ${styles.h2}`}>{heading}</h3>
        <Link
          className="text-right"
          to="/profile"
          state={{ isFavouriteTab: "true" }}
          onClick={props.favClick}
        >
          {manage}
        </Link>
      </div>
      <div className={styles.scrollingContainer}>
        {favourites && latestFavourites && favourites.length > 0 ? (
          latestFavourites.slice(0, 10).map((fav) => {
            return (
              <Link to={fav.url} key={fav.url} className={styles.bookmarkItem} onClick={props.favClick}>
                <h4>{fav.title}</h4>
                {/* <span>{fav.subTitle}</span> */}
                <span>{fav.description ? fav.description : fav.subTitle}</span>
              </Link>
            );
          })
        ) : (
          <p className={styles.noBookmark}>{no_data_text}</p>
        )}
      </div>
    </div>
  );
};

export default BookmarkPopup;
