import { useEffect, useState } from "react";
import { createPortal } from "react-dom";
import { useSelector } from "react-redux";
import style from "./NewHireAccesDelayModal.module.css";

const Backdrop = (props) => {
  return <div className={style.backdrop} onClick={props.onClose} />;
};

export const NewHireAccesDialogue = (props) => {
  const [newHireAccess, setNewHireAccess] = useState();
  const globalNotification = useSelector((state) => state.globalNotification);

  useEffect(() => {
    if (globalNotification?.systemErrors) {
      const { systemErrors } = globalNotification || {};
      const newHireDelay = systemErrors.filter((err) => {
        return err.type == "new_hire_access_delay";
      });
      setNewHireAccess(newHireDelay[0]);
    }
  }, [globalNotification]);

  return (
    <div className={style.modal}>
      {newHireAccess && (
        <div className={style.innerHolder}>
          <div className={style.coreHolder}>
            <div className={style.cross} onClick={props.onClose} aria-label="close"></div>
            <div
              className={style.errorIcon}
              style={{
                background: `url("${newHireAccess.image?.url}") no-repeat`,
              }}
            ></div>
            <div className={style.head}>{newHireAccess.heading}</div>
            <div
              className={style.body}
              dangerouslySetInnerHTML={{
                __html: newHireAccess.description,
              }}
            ></div>
            <button className={style.contBtn} onClick={props.onClose} aria-label="close">
              {newHireAccess.continue_button_text}
            </button>
          </div>
        </div>
      )}
    </div>
  );
};
const portalElement = document.getElementById("portal");

const NewHireAccesDelayModal = (props) => {
  return (
    <>
      {createPortal(<Backdrop onClose={props.onClose} />, portalElement)}
      {createPortal(
        <NewHireAccesDialogue
          onClose={props.onClose}
          heading={props.heading}
        >
          {props.children}
        </NewHireAccesDialogue>,
        portalElement
      )}
    </>
  );
};

export default NewHireAccesDelayModal;
