import { useQuery } from "@apollo/client";
import moment from "moment";
import { useEffect, useState } from "react";
import Loader from "../../components/global/Loader";
import { apiMiddlewareClient } from "../../features/api-wrapper/apiMiddlewareWrapper";
import { GET_ANNOUNCEMENTS } from "../../features/api-wrapper/apiQueries";
import useWindowDimensions from "../../hooks/useScreenDetails";
import APIError from "../../pages/ErrorHandling/APIErrorCompact";
import { GreetingContainer } from "./Greetings";
import styles from "./PersonalizedAnnounce.module.css";
// import { greetingContainer } from "./Greetings";

const PersonalizedAnnounce = (props) => {
  const announcement = props.data.dictionary_with_icon || [];
  const [isActive, setIsActive] = useState();
  const [birthday, setBirthday] = useState(false);
  const [anniversary, setAnniversary] = useState(false);
  const [newHire, setNewHire] = useState(false);
  const [isMobile] = useWindowDimensions();

  const [anniversaryYear, setAnniversaryYear] = useState(0);
  const [anniversaryDay, setAnniversaryDay] = useState(0);
  const [feedback, setFeedback] = useState();
  const [newHireDay, setNewHireDay] = useState(0);
  const [feedbackIndex, setFeedbackIndex] = useState(0);

  const [lastTabSelected, setlastTabSelected] = useState(false);

  const date = new Date();
  let currentDay = String(date.getDate()).padStart(2, "0");
  let currentMonth = String(date.getMonth() + 1).padStart(2, "0");
  let currentYear = date.getFullYear();

  // we will display the date as DD-MM-YYYY
  let currentDate = `${currentYear}-${currentMonth}-${currentDay}`;
  console.log("currentdate", currentDate);

  const {
    loading,
    error,
    data: announcements,
  } = useQuery(GET_ANNOUNCEMENTS, {
    variables: { currentDate: currentDate },
    fetchPolicy: "no-cache",
    client: apiMiddlewareClient,
  });

  useEffect(() => {
    if (announcements?.getAnnouncements.length > 0) {
      let { bday, ann, feeds, newHire } = false;

      let tempFeedback = [];
      let tempLastName = [];
      announcements?.getAnnouncements.map((value) => {
        if (value.__typename === "NewHireAnnouncement") {
          setNewHire(value);
          setNewHireDay(value.milestone);
          newHire = true;
        }
        if (value.__typename === "FeedbackAnnouncement") {
          feeds = true;

          //1. original message with variable$
          const feedbackMessage$ = props?.data?.dictionary_with_icon[2]?.value;  
          
          //2. message with name - This fix is done for Chinese
          const FeedbackMessage = feedbackMessage$
            ?.replace('<span translate="no">{variable-fromname}</span>', value.feedbackFrom)
            .replace('<span translate="no">{variable-fromname}</span>', value.feedbackFrom);

          //3. Adding to array
          tempFeedback.push({
            ...value,
            FeedbackMessage: FeedbackMessage + " ",
          });
        }
        if (value.__typename === "BirthdayAnnouncement") {
          setBirthday(value);
          bday = true;
        }
        if (value.__typename === "AnniversaryAnnouncement") {
          setAnniversary(value);
          setAnniversaryYear(value.yearsCompleted);
          setAnniversaryDay(moment(value.anniversaryDate).format("MMM Do"));
          ann = true;
        }
      });

      setFeedback(tempFeedback, tempLastName);

      if (newHire) {
        setIsActive("newHire");
        return;
      }
      if (feeds) {
        setIsActive(`feedback${0}`);
        return;
      }
      if (bday) {
        setIsActive("birthday");
        return;
      }
      if (ann) {
        setIsActive("Anniversary");
        return;
      }
    }
  }, [announcements]);

  const BirthdayMessage = props?.data?.dictionary_with_icon[1]?.value;
  const BirthValue =
    BirthdayMessage.length > 80
      ? BirthdayMessage.substring(0, 80) + "..."
      : BirthdayMessage;

  const AnniversaryAnnounceYear = props?.data?.dictionary_with_icon[0]?.value
    ?.replace('<span translate="no">{variable-noofyear}</span>', anniversaryYear)
    .replace('<span translate="no">{variable-anniversaryday}</span>', anniversaryDay);

  const AnniversaryValue =
    AnniversaryAnnounceYear.length > 80
      ? AnniversaryAnnounceYear.substring(0, 80) + "..."
      : AnniversaryAnnounceYear;

  const NewHireAnnounceDay =
    props?.data?.dictionary_with_icon[3]?.value?.replace(
      '<span translate="no">{variable-noofday}</span>',
      newHireDay
    );
  const NewHireValue =
    NewHireAnnounceDay.length > 80
      ? NewHireAnnounceDay.substring(0, 80) + "..."
      : NewHireAnnounceDay;

  if (loading) return <Loader loadingText="Fetching..." />;
  if (error) return <APIError error={error} />;

  return (
    <div className={styles.messageBox1}>
      {isMobile && (
        <>
          <div className={`${isMobile ? styles.isMobileContainer : ""}`}>
            <div> {isMobile && <GreetingContainer {...props} />}</div>
            <div className={styles.wrapperContainer}>
              {newHire && (
                <div className={styles.feedbackIconWrapper}>
                  <>
                    <div
                      className={`${styles.announcements} ${
                        isActive === "newHire"
                          ? styles.activeAnnounce
                          : styles.inActiveAnnounce
                      } `}
                    >
                      <button onClick={() => setIsActive("newHire")}>
                        {" "}
                        <img
                          className={styles.imgSize}
                          src={announcement[3]?.url}
                        />
                      </button>
                    </div>
                  </>
                </div>
              )}
              {feedback &&
                feedback.map((feed, index) => {
                  return (
                    <div key={index} className={styles.feedbackIconWrapper}>
                      <div>
                        <div
                          className={`${styles.announcements} ${
                            isActive === `feedback${index}`
                              ? styles.activeAnnounce
                              : styles.inActiveAnnounce
                          }`}
                        >
                          <div className={styles.gap30}>
                            <button
                              onClick={() => {
                                if (index == feedback.length - 1) {
                                  setlastTabSelected(true);
                                } else {
                                  setlastTabSelected(false);
                                }
                                setIsActive(`feedback${index}`);
                                setFeedbackIndex(index);
                              }}
                            >
                              {" "}
                              <img
                                className={styles.imgSize}
                                src={announcement[2]?.url}
                              />
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              {birthday && (
                <div className={styles.feedbackIconWrapper}>
                  <div
                    className={`${styles.announcements} ${
                      isActive === "birthday"
                        ? styles.activeAnnounce
                        : styles.inActiveAnnounce
                    }`}
                  >
                    <button onClick={() => setIsActive("birthday")}>
                      {" "}
                      <img
                        className={styles.imgSize}
                        src={announcement[1]?.url}
                      />
                    </button>
                  </div>
                </div>
              )}
              {anniversary && (
                <div className={styles.feedbackIconWrapper}>
                  <div
                    className={`${styles.announcements} ${
                      isActive === "Anniversary"
                        ? styles.activeAnnounce
                        : styles.inActiveAnnounce
                    }`}
                  >
                    <button onClick={() => setIsActive("Anniversary")}>
                      {" "}
                      <img
                        className={styles.imgSize}
                        src={announcement[0]?.url}
                      />
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className={styles.feedbackDescriptionWrapper}>
            <div
              className={styles.description}
              style={{
                display: isActive === "newHire" ? "block" : "none",
              }}
            >
              <div className={styles.content}>{NewHireAnnounceDay}</div>
            </div>
          </div>
          <div className={styles.feedbackDescriptionWrapper}>
            <div
              className={`${styles.description} ${styles.content} ${
                lastTabSelected && styles.contentBorderR
              }`}
              style={{
                display:
                  isActive === `feedback${feedbackIndex}` ? "block" : "none",
              }}
            >
              {feedback && feedback.length > 0 && (
                <div>
                  {feedback[feedbackIndex]?.FeedbackMessage}
                  <span>
                    <a
                      className={styles.viewfeedback}
                      href={props?.data?.links_dictionary[0].link.href}
                      target="_self"
                      rel="noreferrer"
                    >
                      {props?.data?.links_dictionary[0].link.title}
                    </a>
                  </span>
                </div>
              )}
            </div>
          </div>
          <div className={styles.feedbackDescriptionWrapper}>
            <div
              className={styles.description}
              style={{
                display: isActive === "birthday" ? "block" : "none",
              }}
            >
              <div className={styles.content}>{BirthdayMessage}</div>
            </div>
          </div>
          <div className={styles.feedbackDescriptionWrapper}>
            <div
              className={styles.description}
              style={{
                display: isActive === "Anniversary" ? "block" : "none",
              }}
            >
              <div className={styles.content}> {AnniversaryAnnounceYear}</div>
            </div>
          </div>
        </>
      )}
      {!isMobile && (
        <div className={styles.announcebox}>
          {newHire && (
            <div className={styles.announcements}>
              <button onClick={() => setIsActive("newHire")}>
                {" "}
                <img className={styles.imgSize} src={announcement[3]?.url} />
              </button>
              <div
                className={styles.description}
                style={{ display: isActive === "newHire" ? "block" : "none" }}
              >
                <div>{NewHireValue}</div>
              </div>
            </div>
          )}
          {feedback &&
            feedback.map((feed, index) => {
              return (
                <div key={index} className={styles.announcements}>
                  <div className={styles.announcebox}>
                    <button
                      onClick={() => {
                        setIsActive(`feedback${index}`);
                        setFeedbackIndex(index);
                      }}
                    >
                      {" "}
                      <img
                        className={styles.imgSize}
                        src={announcement[2]?.url}
                      />
                    </button>

                    <div
                      className={styles.description}
                      style={{
                        display:
                          isActive === `feedback${index}` ? "block" : "none",
                      }}
                    >
                      {feedback && feedback.length > 0 && (
                        <div>
                          {feedback[feedbackIndex]?.FeedbackMessage}
                          <span>
                            <a
                              className={styles.viewfeedback}
                              href={props?.data?.links_dictionary[0].link.href}
                              target="_self"
                              rel="noreferrer"
                            >
                              {props?.data?.links_dictionary[0].link.title}
                            </a>
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
          {birthday && (
            <div className={styles.announcements}>
              <button onClick={() => setIsActive("birthday")}>
                {" "}
                <img className={styles.imgSize} src={announcement[1]?.url} />
              </button>
              <div
                className={styles.description}
                style={{ display: isActive === "birthday" ? "block" : "none" }}
              >
                <div>{BirthValue}</div>
              </div>
            </div>
          )}
          {anniversary && (
            <div className={styles.announcements}>
              <button onClick={() => setIsActive("Anniversary")}>
                {" "}
                <img className={styles.imgSize} src={announcement[0]?.url} />
              </button>
              <div
                className={styles.description}
                style={{
                  display: isActive === "Anniversary" ? "block" : "none",
                }}
              >
                <div> {AnniversaryValue}</div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default PersonalizedAnnounce;