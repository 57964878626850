import { useEffect, useState } from "react";
import styles from "./JobOpportunitiesApi.module.css";
// import { useSelector } from "react-redux";
import {
  Button,
  CardContainer,
  CenterModal,
  Chip,
  FullScreenModal,
  Icon,
  Pagination,
  TextLink,
} from "@mgmresorts/mgm-ui";
import JobOpportunitiesCard from "./JobOpportunitiesCard";
import JobOpportunitiesFilterModal from "./JobOpportunitiesFilterModal";
// import JobOpportunitiesQuickViewModal from "./JobOpportunitiesQuickViewModal";
import { useQuery } from "@apollo/client";
import { apiMiddlewareClient } from "../../features/api-wrapper/apiMiddlewareWrapper";
import { GET_JOBOPP_DETAILS, GET_JOBOPP_FILTER } from "../../features/api-wrapper/apiQueries";
// import moment from "moment";
import Loader from "../../components/global/Loader";
import APIError from "../../pages/ErrorHandling/APIError";

const JobOpportunities = (props) => {
  const [deviceSize, changeDeviceSize] = useState(window.innerWidth);
  const [isMobile, setIsMobile] = useState(deviceSize);
  const [startDate, setStartDate] = useState("");
  const [property, setProperty] = useState("");
  const [category, setCategory] = useState("");
  const [selectPropList,setSelectPropList] = useState([]);
  const [selectCatList,setSelectCatList] = useState([]);
  const [visibleCards, setVisibleCards] = useState(8);
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
  const [filterModalLoading, setFilterModalLoading] = useState(false);

  const heading = props.data?.dictionaryLables?.heading;

  // const heading = props.data.isDataLoaded ? props.data.dictionary[0]?.value : props.data.dictionary[0]?.html_value;
  const filters_label = props.data?.links_dictionary?.filter(
    (item) => item.name == "filters"
  )[0]?.link?.title;
  const apply_filters_label = props.data?.dictionaryLables?.apply_filters_label;
  const property_label = props.data?.links_dictionary?.filter((item) => item.name == "property")[0]?.link.title;
const load_more_label = props.data?.dictionaryLables?.load_more;
  const job_category_label = props.data?.links_dictionary?.filter((item) => item.name == "job_category")[0]?.link.title;
  const clear_all_label = props.data?.dictionaryLables?.reset_filters_label;
  // const no_jobs_foundLabel = props.data?.dictionaryLables?.no_of_jobs_found;
  // const no_jobs_found_description = props.data?.dictionary_with_icon[1]?.value;
  const no_jobs_found_img = props.data?.dictionary_with_icon[1]?.url;
  const noResultsLine1 = props.data?.dictionary_with_icon[1]?.value;
  const noResultsLine2 = props.data?.dictionaryLables?.no_job_opportunities_select_others;

// const formatJobsFoundLabel = (template, count) => {

//   return template?.replace("$variable:noofjobs$", count);

// };

  useEffect(() => {
    if (isFilterModalOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "visible";
    }
  }, [isFilterModalOpen]);
  const [applyClicked, setApplyClicked] = useState(false);
  const [clearClicked, setClearClicked] = useState(false);


  const openQuickViewModal = () => {

    setIsFilterModalOpen(true);
    setApplyClicked(false);
  };
  const loadMoreCards = () => {

    setVisibleCards((prev) => prev + 8);

  };
  const closeQuickViewModal = () => {
    setIsFilterModalOpen(false);
    setApplyClicked(false);
  };
  const clearAllModal = () => {
    setClearClicked(!clearClicked);
    // setStartDate("");
    // setProperty("");
    // setCategory("");
  }
  const submitNewFilters = (newFilters) => {
    setApplyClicked(true);
    console.log("new filters", newFilters);
  };

  const applyClickedHandler = (childData) => {
    setIsFilterModalOpen(childData);

  };
  const handlePropertyHandler = ((childData) => {
    console.log("prop list and cat list child prop in parent",childData);

    setProperty(childData);

  })  
  const handleCateogoryHandler = ((childData) => {
    console.log("prop list and cat list child prop in parent cat",childData);

    setCategory(childData);

  })


  const handleFilterModalLoading = (data) => {
    setFilterModalLoading(data);
  }

  // const categoryCount = category.split("!");
console.log("property,category",property , category);
  const formatPropertyLabel = (template, count) => {

    return template
      .replace('<span translate="no">{variable-propertynumber}</span>', count);

  };
  const formatCatLabel = (template, count) => {
    return template
      .replace('<span translate="no">{variable-categorynumber}</span>', count);

  };




  const modalFooterArea = (count)=>(
   
    <div className={styles.modalFooterContainer}>
      { console.log("count",count)}
      {/* <p >{formatJobsFoundLabel(no_jobs_foundLabel,count?count:"Loading")}</p> */}

      <div className={styles.modalFooterBtnArea}>
      <TextLink onClick={clearAllModal} className={styles.leftBtn}>
        {clear_all_label}
      </TextLink>
      <Button
        className={"globalDarkBlueBtn"}
        onClick={submitNewFilters}
        variant="primary"
        disabled={filterModalLoading}
      >
        {apply_filters_label}
      </Button>
      </div>

    </div>
  );


  // const jobVariables = {"startDate": "",
  // "property": "",
  // "jobCategory": ""};
  // const noResultsLine1 =
  //   "There are no job opportunities with the filters provided,";
  // const noResultsLine2 = "Please select others";

  const timeLabel1 = props.data?.dictionaryLables?.day_label;
  const timeLabel2 = props.data?.dictionaryLables?.week_label;
  const timeLabel3 = props.data?.dictionaryLables?.month_label;
  const timeLabel4 = props.data?.dictionaryLables?.all_posting_label;
  
  const [weekLabel, setWeekLabel] = useState(timeLabel2);
  console.log(weekLabel);
  const [dateRange, setDateRange] = useState("2");
  const handleDateRange = (childData) => {
    setDateRange(childData);
  };
  const formatDate = (date) => {
    console.log("date ssss", date);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
    const day = String(date.getDate()).padStart(2, '0');
    console.log("year month day", `${year}-${month}-${day}`);
    return `${year}-${month}-${day}`;
  };
  // const[today,setToday]=useState("");
  const todayDate = new Date();
  const lastWeek = new Date(todayDate);
  lastWeek.setDate(lastWeek.getDate() - 7);
  const [oneMonthAgo, setOneMonthAgo] = useState('');
  const [oneDayAgo, setOneDayAgo] = useState('');
  const [oneWeeksAgo, setOneWeeksAgo] = useState('');
  useEffect(() => {
    const resizeW = () => changeDeviceSize(window.innerWidth);
    window.addEventListener("resize", resizeW); // Update the width on resize
    // if (deviceSize < 1224) {
    if (deviceSize < 690) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
    return () => window.removeEventListener("resize", resizeW);
  }, [deviceSize]);
  useEffect(() => {
    setVisibleCards(8)
  }, [applyClicked]);

  useEffect(() => {
    const currentDate = new Date();

    // Calculate 1 month ago
    const dateOneMonthAgo = new Date(currentDate);
    dateOneMonthAgo.setMonth(dateOneMonthAgo.getMonth() - 1);

    // Calculate 1 day ago
    const dateOneDayAgo = new Date(currentDate);
    dateOneDayAgo.setDate(dateOneDayAgo.getDate() - 1);

    const oneWeekAgo = new Date(currentDate);
    oneWeekAgo.setDate(oneWeekAgo.getDate() - 7)
    // Convert to yyyy-mm-dd format


    setOneMonthAgo(formatDate(dateOneMonthAgo));
    setOneDayAgo(formatDate(dateOneDayAgo));
    setOneWeeksAgo(formatDate(oneWeekAgo))
    if (dateRange === "1") {
      setWeekLabel(timeLabel1);
      setStartDate(oneDayAgo);

    }
    if (dateRange === "2") {
      setWeekLabel(timeLabel2);
      setStartDate(oneWeeksAgo);

    }
    if (dateRange === "3") {
      setWeekLabel(timeLabel3);
      setStartDate(oneMonthAgo);

    }
    if (dateRange === "4") {
      setWeekLabel(timeLabel4);
      setStartDate(" ");

    }

  }, [startDate]);

  const { loading, error, data } = useQuery(GET_JOBOPP_DETAILS, {
    fetchPolicy: "no-cache",
    client: apiMiddlewareClient,
    variables: {
      startDate: " ",
      property: property,
      jobCategory: category,
    },
  });

  const defaultCat = data?.getDefaultJobCategory.wid;
  const {

    data: CategoryAPIData,
  } = useQuery(GET_JOBOPP_FILTER, {
    client: apiMiddlewareClient,
    fetchPolicy: "no-cache",
    variables: {
      getDynamicFilterCategoriesStartDate2: " ",
      getDynamicFilterCategoriesProperty2: property,
      getDynamicFilterCategoriesJobCategory2: category,
    },
  });
  const {

    data: defaultAPIData,
  } = useQuery(GET_JOBOPP_FILTER, {
    client: apiMiddlewareClient,
    fetchPolicy: "no-cache",
    variables: {
      getDynamicFilterCategoriesStartDate2: " ",
      getDynamicFilterCategoriesProperty2: " ",
      getDynamicFilterCategoriesJobCategory2: defaultCat,
    },
  });

  const {

    data: defaultAllAPIData,
  } = useQuery(GET_JOBOPP_FILTER, {
    client: apiMiddlewareClient,
    fetchPolicy: "no-cache",
    variables: {
      getDynamicFilterCategoriesStartDate2: " ",
      getDynamicFilterCategoriesProperty2: " ",
      getDynamicFilterCategoriesJobCategory2: " ",
    },
  });
  const {  data:defaultJobDetails } = useQuery(GET_JOBOPP_DETAILS, {
    fetchPolicy: "no-cache",
    client: apiMiddlewareClient,
    variables: {
      startDate: " ",
      property: " ",
      jobCategory: defaultCat,
    },
  });
  let defaultCount = defaultJobDetails?.getJobPostingsList.length;
  // let initialCatCount = defaultCount==0?0:defaultAPIData?.getDynamicFilterCategories.jobCategory.length;

  const [categoryCount, setCategoryCount] = useState(defaultAPIData?.getDynamicFilterCategories.jobCategory.length);
  const handleCategoryCount = ((childData) => {
    setCategoryCount(childData);
  })
  // const uniqueProperties = new Set(normalizedProperties);
  // const uniquePropArray = Array.from(uniqueProperties);
  let uniquCount;
  useEffect(()=>{

  })
  const[count,setCount]= useState(data?.getJobPostingsList.length);
  const handleCount=((childData)=>{
    setCount(childData);
  })
  // const {

  //   data: AllAPIData,
  // } = useQuery(GET_JOBOPP_FILTER, {
  //   client: apiMiddlewareClient,
  //   fetchPolicy: "no-cache",
  //   variables: {
  //     getDynamicFilterCategoriesStartDate2: startDate,
  //     getDynamicFilterCategoriesProperty2: " ",
  //     getDynamicFilterCategoriesJobCategory2: " ",
  //   },
  // });

  let allCatData = CategoryAPIData?.getDynamicFilterCategories.jobCategory.map((item)=>item.wid);
 let allPropData = CategoryAPIData?.getDynamicFilterCategories.location.map((item)=>item.wid);
 let defaultPropData = defaultAPIData?.getDynamicFilterCategories.location.map((item)=>item.wid);

 useEffect(()=>{
  // console.log("prop list and cat list entered");
  if(applyClicked){
setSelectPropList(allPropData);
setSelectCatList(allCatData);
  }else{
    setSelectPropList(defaultPropData);
    setSelectCatList([defaultCat]);
  }
 },[data])
  const [propCount, setPropCount] = useState(uniquCount);

  
  useEffect(() => {
    const normalizedProperties = defaultAPIData ? defaultAPIData.getDynamicFilterCategories.location.map(posting => posting.wid) : Array.from(new Set(data?.getJobPostingsList.map((item) => item.jobLocation)));
    uniquCount = normalizedProperties ? normalizedProperties.length : 0;
let emptyCount;
    if (applyClicked) {
if(property==""|| property==" "){
emptyCount=uniquCount
}
else{
  let uniqueStrings= property.split("!");
  emptyCount= uniqueStrings.filter((item)=>item!=='').length;
}
      setPropCount(emptyCount);
      
    }

    else {


      if (property.trim()) {

        setPropCount(0);
      }
      else {

        setPropCount(uniquCount);
      }

    }

    //      const uniqueCat = new Set(category.split("!"));
    //  const uniqCatArray = Array.from(uniqueCat);
    //  console.log("uniqueCatArray",category);
    // setCategoryCount(uniqCatArray);


  }, [data]);
  if (loading) return <Loader />;
  if (error) return <APIError error={error} />;
  console.log("api data in job is", data);

  const sortedJobOpportunities = data.getJobPostingsList.sort((a, b) => {
    return new Date(b.jobPostingDate) - new Date(a.jobPostingDate);
});
  // if (data === false) {
  //   setStartDate(" ");
  //   setProperty(" ");
  //   setCategory(" ");
  // }
  const handleStartClicked = (childData) => {
    setStartDate(childData);
  };

  const paginationLabel = props.data?.dictionaryLables?.pagination_label;
  const formatPaginationLabel = (template, visibleCount, totalCount) => {
    return template
      .replace('<span translate="no">{variable-noofpage}</span>', visibleCount)
      .replace('<span translate="no">{variable-totalnoofrecords}</span>', totalCount);
  };
 

  return (
    <div>
      <div className={styles.headingContainer}>
        <div className={styles.headingJob}>{heading}</div>
        <Chip
          variant="primary"
          // size="lg"
          // icon={<Icon name="filter" />}
          onClick={() => openQuickViewModal()}
          leftIcon={"stacked-lines-filter"}
          label={filters_label}
        >
          <Icon color="#ffffff" name="stacked-lines-filter" />
        </Chip>
      </div>
      <div className={styles.topPaginationNums}>
        {data.getJobPostingsList.length < 1 ? formatPaginationLabel(
          paginationLabel,
          `0-${Math.min(visibleCards, data.getJobPostingsList.length)}`,
          data.getJobPostingsList.length
        ) : formatPaginationLabel(
          paginationLabel,
          `1-${Math.min(visibleCards, data.getJobPostingsList.length)}`,
          data.getJobPostingsList.length
        )}
      </div>
      <div className={styles.filterChipLabels}>
        <Chip
          className={styles.filterChipLabel}
          selected={true}
          label={formatPropertyLabel(property_label, data.getJobPostingsList.length==0?0:propCount)}
          leftIcon="pin-location"
        ></Chip>
        <Chip
          className={styles.filterChipLabel}
          selected={true}
          label={formatCatLabel(job_category_label, data.getJobPostingsList.length==0?0:categoryCount?categoryCount:1)}
          leftIcon="squares-grid"
        ></Chip>

        {/* <Chip
          selected={true}
          label={weekLabel}
          leftIcon="calendar-basic"
        ></Chip> */}
      </div>
      <div className={styles.jobOpportuniesCardsContainer}>
        {!data.getJobPostingsList.length && (
          <CardContainer className={styles.noResultsContainer}>
            {/* <Icon name="exclamation-point-triangle" size="large"></Icon> */}
            <img src={no_jobs_found_img}></img>
            <div>{noResultsLine1}</div>
            <div>{noResultsLine2}</div>
          </CardContainer>
        )}

        {sortedJobOpportunities.slice(0, visibleCards).map((item, index) => {
          return (
            <JobOpportunitiesCard
              openQuickviewModal={openQuickViewModal}
              data={props.data}
              item={item}
              key={`job-opportunity-${index}`}
            />
          );
        })}
      </div>
      {/* <div className={styles.showMoreLabel}>{formatPaginationLabel(paginationLabel, Math.min(visibleCards, data.getJobPostingsList.length), data.getJobPostingsList.length)}</div>

      {
        visibleCards < data.getJobPostingsList.length && (
          <div className={styles.loadMoreBtn}> <Button

            //     style={{"marginTop":"16px",
            //   flexDirection:"column",
            // alignItems:"center"}}
            variant="secondary"
            // size="large"
            label="Load more"
            onClick={loadMoreCards}
          >

          </Button>
          </div>

        )
      } */}
      {data.getJobPostingsList.length != 0 && <div className={styles.showMoreLabel}>
        <Pagination
          id="pagination"
          label={load_more_label}
          onClick={loadMoreCards}
          maxValue={data.getJobPostingsList.length}
          value={Math.min(visibleCards, data.getJobPostingsList.length)}
        />
      </div>}
      {isFilterModalOpen && !isMobile && (
        <CenterModal
          title={"Filters"}
          open={isFilterModalOpen}
          size="medium"
          // overflow={true}
          // topOverflow={true}
          // bottomOverflow={true}
          className={styles.centerModal}
          onClose={closeQuickViewModal}
          actions={modalFooterArea(count)}
          footer={true}
        >
          <JobOpportunitiesFilterModal
            handleFilterModalLoading={handleFilterModalLoading}
            clearClicked={clearClicked}
            category={category}
            setCategory={setCategory}
            filterClicked={true}
            property={property}
            setProperty={setProperty}
            data={props.data}
            apiDataCards={data}
            applyClickedHandler={applyClickedHandler}
            applyClicked={applyClicked}
            startDate={startDate == "" ? formatDate(lastWeek) : startDate}
            handleStartClicked={handleStartClicked}
            handleDateRange={handleDateRange}
            dateRange={dateRange}
            handlePropertyHandle={handlePropertyHandler}
            handleCateogoryHandler={handleCateogoryHandler}
            defaultCategory={data?.getDefaultJobCategory.wid}
            handleCategoryCount={handleCategoryCount}
            propList={selectPropList}
            catList={selectCatList}
            allPropData={defaultPropData}
            handleCount={handleCount}
            defaultCount={defaultCount}
            defaultAllAPIData={defaultAllAPIData}
          // handleDefaultCat={handleDefaultCat}
          ></JobOpportunitiesFilterModal>
        </CenterModal>
      )}
      {isFilterModalOpen && isMobile && (
        <FullScreenModal
          title={"Filters"}
          open={isFilterModalOpen}
          size="medium"
          // overflow={true}
          // topOverflow={true}
          // bottomOverflow={true}
          onClose={closeQuickViewModal}
          actions={modalFooterArea(count)}
          footer={true}
        >
          <JobOpportunitiesFilterModal
            handleFilterModalLoading={handleFilterModalLoading}

            clearClicked={clearClicked}
            category={category}
            setCategory={setCategory}
            property={property}
            setProperty={setProperty}
            data={props.data}
            apiDataCards={data}
            filterClicked={true}
            applyClickedHandler={applyClickedHandler}
            applyClicked={applyClicked}
            startDate={startDate == "" ? formatDate(lastWeek) : startDate}
            handleStartClicked={handleStartClicked}
            handleDateRange={handleDateRange}
            dateRange={dateRange}
            handlePropertyHandle={handlePropertyHandler}
            handleCateogoryHandler={handleCateogoryHandler}
            defaultCategory={data?.getDefaultJobCategory.wid}
            handleCategoryCount={handleCategoryCount}
            propList={selectPropList}
            catList={selectCatList}
            allPropData={defaultPropData}
            defaultCount={defaultCount}
            handleCount={handleCount}
            defaultAllAPIData={defaultAllAPIData}
          ></JobOpportunitiesFilterModal>
        </FullScreenModal>
      )}
    </div>
  );
};

export default JobOpportunities;
