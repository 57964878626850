import CTAButton from "../components/dynamic/CTAButton";
import CTATextLink from "../components/dynamic/CTATextLink";
import CTAQuickAction from "../components/dynamic/CTAQuickAction";
import CTAButtonAutoType from "../components/dynamic/CTAButtonAutoType";

import ContentAuthorNote from "../pages/ErrorHandling/ContentAuthorNote";
import { useSelector } from "react-redux";

const ChooseCta = (props) => {
  const language = useSelector(
    (state) => state.gqlData?.profile?.preferredLanguage
  );
  let lang = language === undefined || language === "EN" ? "en-us" : language;
  props = {...props,'lang':lang}
  console.log('ChooseCta @@@',props);
  "primary", "secondary", "tertiary";
  const componentMapping = [
    { name: "primary", component: "CTAButton" },
    { name: "secondary", component: "CTAButton" },
    { name: "tertiary", component: "CTAButton" },
    { name: "text_link", component: "CTATextLink" },
    { name: "quick_actions", component: "CTAQuickAction" },
    { name: "auto_type", component: "CTAButtonAutoType" },
    
  ];

  const componentNames = {
    CTAButton,
    CTATextLink,
    CTAQuickAction,
    CTAButtonAutoType,
   
  };

  if (!props.data)
    return <></>;
    //return <ContentAuthorNote message={`Data not available on CTA!`} />;

  //let firstCta = Array.isArray(props.data) ? props.data[0] : props.data;

  /*
  if (!firstCta.type)
    return <ContentAuthorNote message={`Type not specified on CTA!`} />;
  */

  let type = props.type; //firstCta.type?.toLowerCase();
  let index = componentMapping.findIndex(
    (item) => item.name === props.strictType
  );

  if (index === -1)
    index = componentMapping.findIndex((item) => item.name === type);

  if (index === -1)
    return (
      <ContentAuthorNote message={`'${type}' -> This type not available!`} />
    );

  let componentName = componentMapping[index]?.component;
  let Component = componentNames[componentName];
  console.log('componentName @@@',componentName);
  return <Component {...props} />;
};

export default ChooseCta;
