import style from "./stock.module.css";
import { useEffect, useState } from "react";
import axios from "axios";
import Loader from "../../global/Loader";
import { useSelector } from "react-redux";
//import ErrorHanding from "../../../pages/ErrorHandling/ErrorHanding";
import { getLocalStoreValue } from "../../../utils/localStore";
//import useFetchData from "../../../hooks/useFetchData";

const Stock = () => {
  let token = getLocalStoreValue("token");
  let apigee_token = getLocalStoreValue("apigee_token");
  const stockState = useSelector((state) => state.dom.stockCompState);

  //const { data: csGlobalData } = useFetchData("", "globals");
  const csGlobalData = useSelector((state) => state.globalNotification);
  const stockIconUrl = csGlobalData?.property_icon
    ? csGlobalData?.property_icon[0]?.icon?.url
    : "";

  const headers = {
    Authorization: `Bearer ${apigee_token}`,
    jwt: token,
    //Authorization: `Bearer ${process.env.REACT_APP_BEARER_TOKEN}`,
  };

  const stockApiEndPoint = process.env.REACT_APP_BASE_URL;
  //const [close, setClose] = useState(true);
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [stockData, setStockData] = useState();
  /*const toggleView = () => {
        setClose(!close);
    }*/
  useEffect(() => {
    setLoading(true);
    axios
      .get(`${stockApiEndPoint}api/stock-price`, {
        headers: headers,
      })
      .then((response) => {
        //let result = response.data?response.data.GetStockQuoteListResult?response.data.GetStockQuoteListResult[0]:{}:{};
        setStockData(response?.data);

        setLoading(false);
      })
      .catch((error) => {
        setError({
          title: error.response?.data ? error.response?.data : error.message,
          message: error.message ? error.message : error.response?.data,
          status: error.response?.status ? error.response?.status : null,
        });

        setLoading(false);
      });
  }, []);

  const formatdateAMPM = (dateStr) => {
    var date = new Date(dateStr);
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;
    var strTime = hours + ":" + minutes + " " + ampm;
    var mm = date.getMonth() + 1; // getMonth() is zero-based
    var dd = date.getDate();

    return (
      [
        (mm > 9 ? "" : "0") + mm + "/",
        (dd > 9 ? "" : "0") + dd + "/",
        date.getFullYear(),
      ].join("") +
      " " +
      strTime
    );
  };

  return (
    <>
      <div className={`${stockState ? style.show : style.hide}`}>
        {isLoading ? (
          <Loader />
        ) : error ? (
          <div>{error.status}</div>
        ) : stockData ? (
          <>
            <div className={`${style.stockDesktop}`}>
              <div className={style.topDataHolder}>
                {/*<div className={`${style.stockName} ${style.dStockName}`}>{csGlobalData?csGlobalData.data?csGlobalData.data.globalProperties?csGlobalData.data.globalProperties.stock_feed_exchange_name?csGlobalData.data.globalProperties.stock_feed_exchange_name:'':'':'':''}: {csGlobalData?csGlobalData.data?csGlobalData.data.globalProperties?csGlobalData.data.globalProperties.stock_feed_stock_name?csGlobalData.data.globalProperties.stock_feed_stock_name:'':'':'':''}</div>*/}
                <div className={style.iconHolder}>
                  <img width="20px" height="20px" src={stockIconUrl} />
                </div>
                <div className={`${style.stockName} ${style.dStockName}`}>
                  {stockData?.Exchange} : {stockData?.Symbol}
                </div>

                {/*<span className={`${style.down} ${!close ? style.up : ''}`} onClick={toggleView}></span>*/}
              </div>
              {/*<div className={`${style.dateTime} ${style.toggHolder} ${!close ? style.openToggle : ''}`}>{stockData.TradeDate?formatdateAMPM(stockData.TradeDate):''}</div>*/}
              <div className={`${style.dateTimeHolder}`}>
                <span className={style.price}>
                  $
                  {stockData.TradePrice
                    ? Number(stockData.TradePrice).toFixed(2)
                    : ""}
                </span>
                <span
                  className={`${
                    stockData.PercChange == 0
                      ? style.zero
                      : stockData.PercChange < 0
                      ? style.indicatorred
                      : style.indicator
                  }`}
                ></span>
                <span className={style.percent}>
                  ({" "}
                  {stockData.PercChange == 0
                    ? stockData.PercChange
                    : stockData.PercChange
                    ? Number(stockData.PercChange).toFixed(2)
                    : ""}{" "}
                  )
                </span>
                <span className={style.dateTime}>
                  {stockData.TradeDate
                    ? formatdateAMPM(stockData.TradeDate)
                    : ""}
                </span>
              </div>
            </div>
            {/*<div className={`${style.row} ${style.stockMobile}`}>
            <div className={`${style.colxs6}`}>
                <div className={style.stockName}>{csGlobalData?csGlobalData.globalProperties?csGlobalData.globalProperties.stock_feed_exchange_name:'':''}: {csGlobalData?csGlobalData.globalProperties?csGlobalData.globalProperties.stock_feed_stock_name:'':''}</div>
                <div className={style.dateTime}>{stockData.TradeDate?formatdateAMPM(stockData.TradeDate):''}</div>
            </div>
            <div className={style.colxs6}>
                <div className={style.stockDetails}>
                    <span className={style.price}>${stockData.TradePrice?Number(stockData.TradePrice).toFixed(2):''}</span>
                    <span className={`${stockData.PercChange==0?style.zero:stockData.PercChange<0?style.indicatorred:style.indicator}`}></span>
                    <span className={style.percent}>( {stockData.PercChange==0?stockData.PercChange:stockData.PercChange?Number(stockData.PercChange).toFixed(2):''} )</span>
                </div>
            </div>
        </div>*/}
          </>
        ) : (
          <>
            <Loader />
          </>
        )}
      </div>
    </>
  );
};

export default Stock;
