import { useMutation } from "@apollo/client";
import {
  Alert,
  Button,
  DatePicker,
  DateRangePicker,
  Radio,
} from "@mgmresorts/mgm-ui";
import { CalendarValidationMessage } from "@mgmresorts/mgm-ui/lib/esm/shared";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { apiMiddlewareUploadClient } from "../../features/api-wrapper/apiMiddlewareUploadWrapper";
import { UPLOAD_EDR_MENU } from "../../features/api-wrapper/apiQueries";
import CustomSelectDropDown from "../common/customSelectDropDown/customSelectDropdownLabel";
import DragNdrop from "./DragNdrop";
import style from "./UploadEDR.module.css";

const SINGLE_DAY = "Single Date";
const DATE_RANGE = "Date Range";
const options = [{ name: SINGLE_DAY }, { name: DATE_RANGE }];

const UploadEDR = (props) => {
  const [value, setValue] = useState({
    start: "",
    end: "",
  });
  const [apiError, setAPIError] = useState({
    isAPIFailed: false,
    message: "",
  });
  const [errorState, setErrorState] = useState({
    propertyName: false,
    dateRange: false,
    uploadMenu: false,
  });
  const apiErrorRef = useRef(null);
  const [validationHint, setValidationHint] = useState("");
  const [selectedOption, setSelectedOption] = useState(SINGLE_DAY);
  const globalProperties = useSelector(
    (state) => state.globalNotification?.globalProperties
  );
  const [formData, setFormData] = useState({
    updateEdrInput: {
      startDate: "",
      endDate: "",
      propertyName: "",
    },
    file: "",
  });

  const [mutateEDR, { data: responseEDR, error }] = useMutation(
    UPLOAD_EDR_MENU,
    { client: apiMiddlewareUploadClient }
  );

  const onChangeDropDownValue = (value, text) => {
    if (text) {
      setFormData({
        ...formData,
        updateEdrInput: { ...formData.updateEdrInput, propertyName: text },
      });
      setErrorState({ ...errorState, propertyName: true });
    }
  };

  useEffect(() => {
    if (responseEDR) {
      props.responseEDR();
    }
  }, [responseEDR]);

  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);
  };

  const onSelectedFiles = (files) => {
    setFormData({
      ...formData,
      file: files,
    });
    setErrorState({ ...errorState, uploadMenu: true });
  };

  const clickNext = (event) => {
    event.preventDefault();
    mutateEDR({
      variables: formData,
      context: {
        headers: {
          "x-apollo-operation-name": "allow",
        },
      },
    });
  };

  useEffect(() => {
    if (error) {
      setAPIError({
        isAPIFailed: true,
        message: error.graphQLErrors[0]?.message,
      });
      setTimeout(() => {
        apiErrorRef.current.focus();
      }, 100);
      return;
    }
  }, [error]);

  return (
    <div>
      <form
        onSubmit={(event) => {
          `${event.preventDefault()} return false`;
        }}
      >
        <div className={style.scrollContainer}>
          <div className={style.edrDatePickerContainer}>
            <div className="mb-3">Choose the Property Name</div>
            <div>
              {globalProperties && globalProperties.edr_menu_properties && (
                <div className={`mb-3 ${style.uploadSelectDropdown}`}>
                  <CustomSelectDropDown
                    id="dateDropdown"
                    text="date"
                    isType2={true}
                    optionsList={globalProperties.edr_menu_properties}
                    onChange={(value, text) => {
                      onChangeDropDownValue(value, text);
                    }}
                    placeholderText="Property Name"
                  />
                </div>
              )}
              <div className={style.menuDateContainer}>
                <div className={style.selectDateField}>
                  <div className="mb-3">Choose a Single Date or Date Range</div>
                  <div className={style.uploadOptions}>
                    {options.map((item, index) => (
                      <div key={index} className="option-style">
                        <Radio
                          id={item.name}
                          checked={selectedOption === item.name}
                          variant="small"
                          name={item.name}
                          value={item.name}
                          onChange={(e) => handleOptionChange(e)}
                        />
                        <label htmlFor={item.name}>{item.name}</label>
                      </div>
                    ))}
                  </div>
                </div>
                {selectedOption == DATE_RANGE && (
                  <DateRangePicker
                    onCalendarOpen={() => {}}
                    onCalendarClose={() => {}}
                    hint={validationHint}
                    startLabel="Start Date"
                    endLabel="End Date"
                    onChange={(startDate, endDate, reason) => {
                      setValue({ start: startDate, end: endDate });
                      setErrorState({ ...errorState, dateRange: true });
                      setFormData({
                        ...formData,
                        updateEdrInput: {
                          ...formData.updateEdrInput,
                          startDate: moment(startDate)
                            .format("YYYY-MM-DD")
                            .concat("T00:00:00Z"),
                          endDate: moment(endDate)
                            .format("YYYY-MM-DD")
                            .concat("T23:59:59Z"),
                        },
                      });
                      setValidationHint(
                        CalendarValidationMessage(reason, {
                          startLabel: "StartDate",
                          endLabel: "EndDate",
                        })
                      );
                    }}
                    value={value}
                  />
                )}
                {selectedOption == SINGLE_DAY && (
                  <div className={style.datePickerWrapper}>
                    <DatePicker
                      value={value.start}
                      label="Select Date"
                      onChange={(value) => {
                        setValue({ start: value, end: value });
                        setErrorState({ ...errorState, dateRange: true });
                        setFormData({
                          ...formData,
                          updateEdrInput: {
                            ...formData.updateEdrInput,
                            startDate: moment(value)
                              .format("YYYY-MM-DD")
                              .concat("T00:00:00Z"),
                            endDate: moment(value)
                              .format("YYYY-MM-DD")
                              .concat("T23:59:59Z"),
                          },
                        });
                      }}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className={style.fileUploadContainer}>
            <h6>Upload Your Menu</h6>
            <div className={`mb-3 ${style.note}`}>
              File must be a PDF and 25MB or less
            </div>
            <DragNdrop
              onFilesSelected={(files) => {
                onSelectedFiles(files);
              }}
              isWarning={() => {
                setErrorState({ ...errorState, uploadMenu: false });
              }}
              width="100%"
            />
          </div>
          {apiError.isAPIFailed && (
            <div className="mt-3 mb-2" ref={apiErrorRef} tabIndex="-1">
              <Alert
                Display
                Name
                message={apiError.message}
                onClose={() => {
                  setAPIError({ isAPIFailed: false, message: "" });
                }}
                open
                title="Upload Failed"
                variant="error"
              />
            </div>
          )}
        </div>
        <div className={style.btnGroup}>
          <Button
            onClick={props.onClose}
            size="large"
            label="Cancel"
            variant="secondary"
          />
          <Button
            onClick={(e) => {
              clickNext(e);
            }}
            className={
              !Object.values(errorState).every((item) => item === true)
                ? style.disableBtn
                : style.primaryBtn
            }
            disabled={!Object.values(errorState).every((item) => item === true)}
            variant="primary"
            label="Next"
            size="large"
          />
        </div>
      </form>
    </div>
  );
};

export default UploadEDR;
