import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  searchGlobalResultPageData: {
    sort: null,
    facetcounts: null,
    totalNum: null,
    assignValue: null,
    selectionDD: null,
    topresult: null,
    resultdata: null,
    page: null,
    showfilter: null,
    btnState: null,
    catQueryString: null,
  },
  searchClicked: false,
};
const searchGlobalResultSlice = createSlice({
  name: "searchglobalresult",
  initialState,
  reducers: {
    setsearchGlobalResultPageData(state, action) {
      state.searchGlobalResultPageData = action.payload;
    },
    setsearchClicked(state, action) {
      state.searchClicked = action.payload;
    },
  },
});

export const searchGlobalResultActions = searchGlobalResultSlice.actions;
export default searchGlobalResultSlice;
