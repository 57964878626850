
// import styles from "./TwoColumn.module.css";
import styles from "./TwoColumnV2.module.css";
import CarousalBlockOneColumn from "./CarousalBlockOneColumn";
import { useEffect, useState } from "react";
import ActionPanel from "./ActionPanel";
import OneColumnV2 from "./OneColumnV2";
import { SavingsPlan401K_NtEn_R } from "./SavingsPlan401K_NtEn_R";
import { SavingsPlan401K_Contact } from "./SavingsPlan401K_Contact";
import use401K from "./use401K";

import { useLocation } from "react-router-dom";

const TwoColumnV2 = (props) => {
  console.log("props in two column", props.data);
  // const twoColAction = true;
  let typeStyle =
    "type_" + props.data?.type?.split(",").join("").split("-").join("_");

  const location = useLocation();

  //customize according to component url
  const cLocation = location.pathname.split("/").pop();
  var apiData;

  var block2 =
    cLocation == "401-k-savings-plan" &&
    props.data.leftReference[0]._content_type_uid == "one_column_v2"
      ? true
      : false;

  var block3 =
    cLocation == "401-k-savings-plan" &&
    props.data.rightReference[0]._content_type_uid == "action_panel"
      ? true
      : false;

  var isUserEnrolled;
  //if (cLocation === "401-k-savings-plan" && block2) {
    //#todo: send to Redux
    apiData = use401K();
    isUserEnrolled = apiData?.data?.get401KDetails?.isUserEnrolled;
  //}

  //   let [showPopup, setShowPopup] = useState(false);
  //   let [selectedModal, setSelectedModal] = useState();

  const [deviceSize, changeDeviceSize] = useState(window.innerWidth);
  const [isMobile, setIsMobile] = useState(deviceSize);

  useEffect(() => {
    const resizeW = () => changeDeviceSize(window.innerWidth);
    window.addEventListener("resize", resizeW); // Update the width on resize
    // if (deviceSize < 1224) {
    if (deviceSize < 1200) {
      setIsMobile(true);
    } else if (deviceSize >= 1200) {
      setIsMobile(false);
    }
    return () => window.removeEventListener("resize", resizeW);
  }, [deviceSize]);

  // const openModal = (arg) => {
  //   let modal =
  //     arg == "left"
  //       ? props.data?.leftCta?.modalContent
  //       : props.data?.rightCta?.modalContent;
  //   if (modal != null && modal != undefined) {
  //     setSelectedModal({
  //       heading: modal.heading,
  //       body: modal.content,
  //     });
  //   } else {
  //     setSelectedModal({ heading: "", body: "" });
  //   }
  //   setShowPopup(true);
  // };

  //   const onPopupClose = () => {
  //     setShowPopup(false);
  //   };

  //customize according to widget inside component. I can also give dynamic names
  // #recheck -  props.data.rightReference[0]._content_type_uid == "html_widget"

  //forTesting - isUserEnrolled - make it true
  return (
    <>
      {/* this condition is put, just to remove empty div */}
      {(cLocation != "401-k-savings-plan" ||
        (cLocation == "401-k-savings-plan" &&
          block2 == true &&
          isUserEnrolled == false) ||
        (cLocation == "401-k-savings-plan" && block3 == true)) && (
        <div
          className={`${styles.twoColumn} ${styles[typeStyle]}   
    ${
      props.data.rightReference[0]._content_type_uid == "html_widget" &&
      styles["c" + cLocation]
    }`}
        >
          <div style={isMobile ? { width: "100%" } : { width: "58%" }}>
            {props.data?.leftReference[0]?._content_type_uid ===
              "carousel_v2" && (
              <CarousalBlockOneColumn
                data={props.data?.leftReference[0]?.carousel_items}
              />
            )}
            {props.data?.leftReference[0]?._content_type_uid ===
              "action_panel" && (
              <ActionPanel
                data={{
                  heading: props.data?.rightReference[0].heading,
                  children: props.data?.rightReference[0].links,
                  description: props.data?.rightReference[0].description,
                  viewAll: props.data?.rightReference[0].view_all,
                  type: props.data?.rightReference[0].type,
                }}
              />
            )}

            {/* 401K - block2 */}
            {/* {cLocation == "401-k-savings-plan" && */}
            {props.data?.leftReference[0]?._content_type_uid ===
              "one_column_v2" && <OneColumnV2 {...props} />}

            {/* 401K - block3 */}
            {/* Note: I am using my own component instead of 50-50 - ask */}
            {props.data?.leftReference[0]?._content_type_uid ===
              "fifty_fifty_card" && <SavingsPlan401K_Contact {...props} />}
          </div>

          <div style={isMobile ? { width: "100%" } : { width: "38%" }}>
            {/* 401K also - block3  */}
            {props.data?.rightReference[0]?._content_type_uid ===
              "action_panel" && (
              <ActionPanel
                data={{
                  heading: props.data?.rightReference[0].heading,
                  children: props.data?.rightReference[0].links,
                  description: props.data?.rightReference[0].description,
                  viewAll: props.data?.rightReference[0].view_all,
                  type: props.data?.rightReference[0].type,
                }}
              />
            )}
            {props.data?.rightReference[0]?._content_type_uid ===
              "carousel_v2" && (
              <CarousalBlockOneColumn
                data={props.data?.leftReference[0]?.carousel_items}
              />
            )}

            {/* 401K - block2*/}
            {props.data?.rightReference[0]?._content_type_uid ===
              "html_widget" && <SavingsPlan401K_NtEn_R {...props.data} />}


</div>
        </div>
      )}
    </>
  );

};

export default TwoColumnV2;
