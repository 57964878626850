import axios from "axios";
import { useEffect, useState } from "react";
import style from "./SearchInput.module.css";
// import { useState, useEffect, useRef } from "react";
import { CardContainer, Icon, TextLink } from "@mgmresorts/mgm-ui";
import { useNavigate } from "react-router-dom";
import Loader from "../../components/global/Loader";
import { handleTokenExpiry } from "../../utils/error";
import ErrorHanding from "../ErrorHandling/ErrorHandingAgentSearch";
import Filter from "./FilterAs";
// import { useDetectOutsideClick } from "./useDetectOutsideClick";
import { getLocalStoreValue, setLocalStoreValue } from "../../utils/localStore";
//import searchResultSlice from "../../data/search-result-slice"
import { useDispatch, useSelector } from "react-redux";
import InnerHtml from "../../components/dynamic/InnerHtml";
import { searchResultActions } from "../../data/search-result-slice";

const SearchInput = (props) => {
  let employeeId = props.employeeId;
  const { api } = window;
  //let token =    sessionStorage.getItem("token") || process.env.REACT_APP_BEARER_TOKEN;
  let token = getLocalStoreValue("token");
  let apigee_token = getLocalStoreValue("apigee_token");
  const [error, setError] = useState();
  const [suggestions, setSuggestions] = useState([]);
  const [assignValue, setAssignValue] = useState("*");
  const [defaultSearch, setDefaultSearch] = useState(true);
  const [searchClick, setSearchClick] = useState();
  const [isLoading, setLoading] = useState(false);
  const [showfilter, setshowfilter] = useState(false);
  const [facetcounts, setfacetcounts] = useState([]);
  const [resultdata, setResultdata] = useState([]);
  const [topresult, settopresult] = useState();
  //const [maintopresult, mainsettopresult] = useState();
  const [numFound, setnumFound] = useState();
  const [totalNum, setTotalNum] = useState();
  let totalResult;
  const [totalPage, settotalPage] = useState();
  const [filterdata, setfilterData] = useState();
  const [btnState, setbtnState] = useState();
  //const [mainData, setmainData] = useState();
  const [searchterm, setsearchterm] = useState();
  //const [catQueryString, setcatQueryString] = useState('');
  let catQueryString = "";
  const [page, setPage] = useState(1);
  const [hideSuggestion, setHideSuggestion] = useState(true);
  // const dropdownRef = useRef(null);
  // const [isActive, setIsActive] = useDetectOutsideClick(dropdownRef, false);
  const navigate = useNavigate();
  // const [sort, setSort] = useState("");
  //const [stateData,setStateData] = useState();
  // const sortOption = [
  //   { name: "Relevance", data: "" },
  //   { name: "Newest to oldest", data: "pagePublishDate_dt%20desc" },
  //   { name: "Oldest to newest", data: "pagePublishDate_dt%20asc" },
  // ];
  // const [selectionDD, setselectionDD] = useState(sortOption[0]["name"]);
  const dispatch = useDispatch();
  const stateData = useSelector(
    (state) => state.searchresult.searchResultPageData
  );
  const [isSuggestionSelected, setIsSuggestionSelected] = useState();
  // let isRevisit = false;

  //

  console.log("stateData>>>>>", stateData);
  useEffect(() => {
    if (isSuggestionSelected == true) {
      doSearch();
    }
  }, [isSuggestionSelected]);
  const isEmpty = (obj) => {
    for (const prop in obj) {
      if (Object.hasOwn(obj, prop)) {
        return false;
      }
    }

    return true;
  };
  useEffect(() => {
    console.log("stateData ####################", stateData);
    if (
      isEmpty(stateData) ||
      stateData.assignValue == null ||
      typeof stateData.assignValue == "undefined"
    ) {
      console.log("##################################");
      doSearch();
    }
  }, []);
  const createCategoryStringFromStateData = () => {
    let catQueryString = "";
    if (
      stateData &&
      typeof stateData.catQueryString !== "undefined" &&
      stateData.catQueryString != null
    ) {
      catQueryString = stateData.catQueryString;
    }
    return catQueryString;
  };
  const createFatecountFromStateData = () => {
    // isRevisit = true;

    if (
      stateData &&
      typeof stateData.assignValue !== "undefined" &&
      stateData.assignValue != null
    ) {
      setDefaultSearch(false);
      setAssignValue(stateData.assignValue);
      setsearchterm(stateData.assignValue);
    }
    // if (stateData && stateData.sort && stateData.sort != null) {
    //   //setSort(stateData.sort);
    // }
    if (
      stateData &&
      typeof stateData.facet_counts !== "undefined" &&
      stateData.facet_counts != null
    ) {
      console.log("111111111111111111111");
      setfacetcounts(stateData.facet_counts);
    }
    if (
      stateData &&
      typeof stateData.totalNum !== "undefined" &&
      stateData.totalNum != null
    ) {
      setTotalNum(stateData.totalNum);
      settotalPage(Math.ceil(stateData.totalNum / 10));
    }
    // if (stateData && stateData.selectionDD && stateData.selectionDD != null) {
    //   setselectionDD(stateData.selectionDD);
    // }
    if (
      stateData &&
      typeof stateData.topresult !== "undefined" &&
      stateData.topresult != null
    ) {
      settopresult(stateData.topresult);
    }
    if (
      stateData &&
      typeof stateData.resultdata !== "undefined" &&
      stateData.resultdata != null
    ) {
      setResultdata(stateData.resultdata);
    }
    if (
      stateData &&
      typeof stateData.page !== "undefined" &&
      stateData.page != null
    ) {
      setPage(stateData.page);
    }
    if (
      stateData &&
      typeof stateData.showfilter !== "undefined" &&
      stateData.showfilter != null
    ) {
      setshowfilter(stateData.showfilter);
    }
    if (
      stateData &&
      typeof stateData.btnState !== "undefined" &&
      stateData.btnState != null
    ) {
      setbtnState(stateData.btnState);
    }
    if (
      stateData &&
      typeof stateData.catQueryString !== "undefined" &&
      stateData.catQueryString != null
    ) {
      catQueryString = stateData.catQueryString;
    }
  };
  useEffect(() => {
    console.log("stateData #################### inside >>>", stateData);
    console.log("facetcounts #################### 1111", facetcounts);
    createFatecountFromStateData();
  }, []);
  useEffect(() => {
    console.log("facetcounts final #################### 222", facetcounts);
  }, [facetcounts]);
  useEffect(() => {
    console.log("totalPage final #################### 222", totalPage);
  }, [totalPage]);
  useEffect(() => {
    console.log("page final #################### 222", page);
  }, [page]);

  // useEffect(() => {
  //   if (!isRevisit) {
  //     //doSearch();
  //     filterResult(filterdata);
  //   } else {
  //     isRevisit = false;
  //   }
  // }, [sort]);

  useEffect(() => {
    handleTokenExpiry(error);
  }, [error]);

  useEffect(() => {
    window.addEventListener("scroll", fnScroll);
    return () => window.removeEventListener("scroll", fnScroll);
  }, [isLoading, assignValue]);

  useEffect(() => {
    if (!resultdata) {
      return;
    }
    if (topresult) {
      setnumFound(resultdata.length + 1);
    } else {
      setnumFound(resultdata.length);
    }
    console.log("numFound>>>", numFound);
  }, [resultdata]);
  const headers = {
    Authorization: `Bearer ${apigee_token}`,
    jwt: token,
    //Authorization: `Bearer ${process.env.REACT_APP_BEARER_TOKEN}`,
  };
  const delay = 300;
  let timer;
  let searchAheadData;
  const baseURL = process.env.REACT_APP_BASE_URL;

  //console.log('resultdata 1>>>>', resultdata);
  //const baseURL = "https://eh-midgdleware.azurewebsites.net/api/lw-search-ahead";
  const getSearchAheadData = async (e) => {
    await axios
      .get(
        `${baseURL}api/agent/lw-search-ahead?q=${encodeURIComponent(
          e.target.value
        )}&employeeId=${employeeId}`,
        {
          headers: headers,
        }
      )
      .then((response) => {
        //console.log('searchAheadData>>>',response.data.suggest.mgmSuggestor[`${e.target.value}`].suggestions      )
        try {
          searchAheadData =
            response.data.suggest.mgmSuggestor[`${e.target.value}`].suggestions;
          if (searchAheadData && searchAheadData.length > 0) {
            let result = [];
            searchAheadData.forEach((ele) => {
              let filterStr = ele.term;
              let dropdata = {
                value: filterStr,
              };
              result.push(dropdata);
            });
            setSuggestions(result);
          }
        } catch (e) {
          console.log(e);
        }

        //console.log('suggestions>>>', suggestions)
        setError(null);
      })
      .catch((error) => {
        setError({
          title: error.response?.data ? error.response?.data : error.message,
          message: error.message ? error.message : error.response?.data,
          status: error.response?.status ? error.response?.status : null,
        });
      });
  };
  // this function will get data when the search button is clicked
  const getSearchResultData = async (type = "search") => {
    setsearchterm(assignValue);
    setLoading(true);
    await axios
      .get(
        `${baseURL}api/agent/lw-search?q=${encodeURIComponent(
          assignValue
        )}&category=${encodeURIComponent(
          catQueryString
        )}&employeeId=${employeeId}`,
        // )}&category=${catQueryString}&employeeId=${employeeId}&sort=${sort}`,
        {
          headers: headers,
        }
      )
      .then((response) => {
        settopresult(null);
        let result = response.data;
        /*console.log('search result >>>', result);
      console.log('response.facet_counts >>>', result.facet_counts);
      console.log('response.response.docs >>>', result.response.docs);
      console.log('response.highlighting >>>', result.highlighting);*/
        //console.log('dummyResponse >>>', dummyResponse);
        setLoading(false);
        if (type == "search") {
          setfacetcounts(createCategoryData(result.facet_counts));
          // value of true will disable the button
          setbtnState(true);
        }

        setResultdata(
          createResultdata(result.response.docs, result.highlighting)
        );

        setnumFound(result.response.docs.length);
        totalResult = result.response.numFound;
        setTotalNum(totalResult);
        settotalPage(Math.ceil(totalResult / 10));
        console.log(
          "totalResult >>>" + totalResult + ">>>totalPage>>>" + totalPage
        );
        //console.log('resultdata 2>>>>', resultdata);

        setshowfilter(true);
        setError(null);
      })
      .catch((error) => {
        setfacetcounts(null);
        setResultdata(null);
        setnumFound(0);
        setError({
          title: error.response?.data ? error.response?.data : error.message,
          message: error.message ? error.message : error.response?.data,
          status: error.response?.status ? error.response?.status : null,
        });
        setLoading(false);
      });
  };
  const createResultdata = (rdata, hdata, type = "search") => {
    //console.log('rdata>>', rdata);
    //console.log('hdata>>', hdata);
    if (rdata && rdata.length <= 0 && type == "search") {
      //setmainData([]);
      return [];
    }
    let newData = rdata.map((d) => {
      //d.highlight = d.pageShortDesc_t;
      let id = d.id;
      /*d.highlight =
        hdata && hdata[id] && hdata[id]["pageBody"] &&
        hdata[id]["pageBody"].length? hdata[id]["pageBody"][0] : d.pageShortDesc_t;*/

      if (hdata && hdata[id]) {
        d.highlight = hdata[id]["pageBody"]
          ? hdata[id]["pageBody"][0]
          : hdata[id]["pageHeading"]
          ? hdata[id]["pageHeading"][0]
          : d.pageShortDesc_t;
      } else {
        d.highlight = d.pageShortDesc_t;
      }

      if (!d["[elevated]"]) {
        return d;
      } else {
        if (type == "search") {
          settopresult(d);
          //mainsettopresult(d);
        }
      }
    });
    newData = newData.filter((d) => {
      if (d) {
        return d;
      }
    });
    //console.log('topresult>>>>', topresult);
    console.log("result modified data>>>", newData);
    //setmainData(prevMaindata => [...prevMaindata,...newData]);
    return newData;
  };
  const createCategoryData = (facetcounts) => {
    if (facetcounts.length <= 0) {
      return [];
    }
    let fData =
      facetcounts.facet_fields && facetcounts.facet_fields.pageCategory_t
        ? facetcounts.facet_fields.pageCategory_t
        : [];
    let newData = [];
    fData.map((d) => {
      let obj = {};
      obj.name = d[0];
      obj.checked = false;
      if (obj.name != "") {
        newData.push(obj);
      }
    });
    //console.log('newData>>>', newData);

    return newData;
  };
  const onChangeMethod = (e) => {
    setDefaultSearch(false);
    setAssignValue(e.target.value);
    if (e.target.value.length >= 3) {
      if (timer) {
        clearTimeout(timer);
      }
      timer = setTimeout(() => {
        getSearchAheadData(e);
      }, delay);
      setHideSuggestion(false);
    } else {
      setSuggestions([]);
    }
  };
  // this function will hand the click on search ahead suggestion
  const handleClick = (e) => {
    //console.log(e.currentTarget.innerHTML);
    let filterStr = e.currentTarget.innerHTML.replace(/(<([^>]+)>)/gi, "");
    filterStr = filterStr.replace("&amp;", "&");
    setAssignValue(filterStr);
    setSuggestions([]);
    setIsSuggestionSelected(true);
  };
  const doSearch = () => {
    setIsSuggestionSelected(false);
    console.log("assignValue >>>", assignValue);
    if (!assignValue) {
      return;
    } else if (assignValue.trim() == "") {
      return;
    }
    if (searchClick != true) {
      setSearchClick(true);
      doResize();
    }
    setPage(1);
    //setbtnState(true);
    setSuggestions([]);
    //on sort we want the cat data t be retained
    //setcatQueryString('');
    //catQueryString = "";
    setHideSuggestion(true);
    getSearchResultData();
  };
  const doResize = () => {
    try {
      api.send("resizeWindowtoSR", "resizeWindowtoSR");
    } catch (e) {
      //console.log("error occured");
    }
  };
  const clearfilter = () => {
    //setcatQueryString('');\
    setPage(1);
    catQueryString = "";
    getSearchResultData("filter");
    setfilterData(null);
    setbtnState(true);
  };
  const filterResult = (data) => {
    console.log("filter on this data >>>", data);
    console.log("filter on this data facetcounts >>>", facetcounts);
    //console.log('mainData >>>', mainData);
    let QueryString = "";
    if (data) {
      data.map((f, index) => {
        if (f.checked == true) {
          QueryString += f.name;
          if (index != data.length - 1) {
            QueryString += ":";
          }
        }
      });
    }

    console.log("QueryString >>>", QueryString);
    setPage(1);
    setfilterData(data);
    catQueryString = QueryString;
    //setcatQueryString(catQueryString);
    getSearchResultData("filter");
    setbtnState(false);
  };

  // the functionalities for infinite scroll
  /*const getUniqueCat = (cat) => {
    let uniqueObject = {};
    let newArray = [];
    for (let i in cat) {
      // Extract the name
      let objName = cat[i]["name"];

      // Use the name as the index
      uniqueObject[objName] = cat[i];
    }

    // Loop to push unique object into array
    for (let i in uniqueObject) {
      newArray.push(uniqueObject[i]);
    }
    return newArray;
  };*/
  const fetchNxtdata = async () => {
    setLoading(true);
    setError(null);
    let QueryString = "";
    if (facetcounts.length == 0) {
      createFatecountFromStateData();
    }
    console.log("facetcounts ############## 33333", facetcounts);
    console.log("filterdata ############## 33333", filterdata);
    if (filterdata) {
      filterdata.map((f, index) => {
        if (f.checked == true) {
          QueryString += f.name;
          if (index != filterdata.length - 1) {
            QueryString += ":";
          }
        }
      });
    } else {
      QueryString = createCategoryStringFromStateData();
    }
    await axios
      .get(
        `${baseURL}api/agent/lw-search?q=${encodeURIComponent(
          assignValue
        )}&category=${encodeURIComponent(QueryString)}&start=${
          page * 10
        }&employeeId=${employeeId}`,
        // }&employeeId=${employeeId}&sort=${sort}`,
        {
          headers: headers,
        }
      )
      .then((response) => {
        let result = response.data;
        setResultdata((prevResultdata) => [
          ...prevResultdata,
          ...createResultdata(
            result.response.docs,
            result.highlighting,
            "scroll"
          ),
        ]);
        /*setfacetcounts((prevVal) => [
          ...prevVal,
          ...createCategoryData(result.facet_counts),
        ]);*/
        setPage((prevPage) => prevPage + 1);

        console.log("filterdata>>>", filterdata);

        //let filterCat = getUniqueCat(facetcounts);

        //setfacetcounts(filterCat);
        console.log("facetcounts>>>", facetcounts);
        setLoading(false);
      })
      .catch((error) => {
        setError({
          title: error.response?.data ? error.response?.data : error.message,
          message: error.message ? error.message : error.response?.data,
          status: error.response?.status ? error.response?.status : null,
        });
        setLoading(false);
      });
  };

  const fnScroll = () => {
    /*console.log('document.documentElement.scrollTop>>>',Math.floor(document.documentElement.scrollTop)+1);
    console.log('window.innerHeight>>>',Math.floor(window.innerHeight));
    console.log('document.documentElement.offsetHeight>>>',Math.floor(document.documentElement.offsetHeight));
    console.log('assignValue>>>',assignValue);
    console.log('page>>>',page);
    console.log('totalPage>>>',totalPage);*/
    if (!assignValue) {
      return;
    } else if (assignValue.trim() == "") {
      return;
    }

    if (page >= totalPage) {
      return;
    }
    /* for browser */
    if (
      Math.floor(window.innerHeight) +
        Math.floor(document.documentElement.scrollTop) +
        1 <
        Math.floor(document.documentElement.offsetHeight) ||
      isLoading
    ) {
      return;
    }
    console.log("fetch next data called-----");

    fetchNxtdata();
  };
  const copyLink = async (url) => {
    console.log("url -----------------", url);
    let protocol = location.protocol;
    let hostName = location.hostname;
    let port = location.port != "" ? ":" + location.port : location.port;
    let fullPath = protocol + "//" + hostName + port + url;
    await navigator.clipboard.writeText(fullPath);
  };
  const generatePersistentObj = () => {
    let obj = {};
    obj.assignValue = assignValue;
    // obj.sort = sort;
    obj.facet_counts = facetcounts;
    obj.totalNum = totalNum;
    // obj.selectionDD = selectionDD;
    obj.topresult = topresult;
    obj.resultdata = resultdata;
    obj.page = page;
    obj.showfilter = showfilter;
    obj.btnState = btnState;
    console.log("44444444444444 ##### catQueryString", catQueryString);
    if (!catQueryString || catQueryString == "") {
      catQueryString = generateQueryString(facetcounts);
    }
    obj.catQueryString = catQueryString;

    return obj;
  };
  const generateQueryString = (data) => {
    let QueryString = "";
    if (data) {
      data.map((f, index) => {
        if (f.checked == true) {
          QueryString += f.name;
          if (index != data.length - 1) {
            QueryString += ":";
          }
        }
      });
    }
    return QueryString;
  };
  const preview = (url) => {
    console.log("preview -----------------", url);

    setLocalStoreValue("preview", "preview");
    console.log("generatePersistentObj >>>>>", generatePersistentObj());
    dispatch(
      searchResultActions.setsearchResultPageData(generatePersistentObj())
    );
    navigate(url);
  };

  // const openSortDD = () => {
  //   setIsActive(!isActive);
  //   console.log("isActive >>>>>>>>>>>>>>>>>>>>>>>>", isActive);
  // };

  // const setSortOption = (selection, listObj) => {
  //   console.log("selection ----", selection);
  //   // setselectionDD(selection);
  //   setSort(listObj["data"]);
  //   /*if (selection == sortOption[0]["name"]) {
  //     setSort(sortOption[0]["data"]);
  //   } else if (selection == sortOption[1]["name"]) {
  //     setSort(sortOption[1]["data"]);
  //   }*/
  //   console.log("sort>>>>>>>", sort);
  //   setIsActive(false);

  //   console.log("isActive >>>>>>>>>>>>>>>>>>>>>>>>", isActive);
  // };

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div className={style.inputcomph}>
          {showfilter && (
            <div className={style.filterH}>
              <Filter
                facetcounts={facetcounts}
                filterResult={filterResult}
                clearfilter={clearfilter}
                btnState={btnState}
                setbtnState={setbtnState}
                setfacetcounts={setfacetcounts}
              ></Filter>
            </div>
          )}

          <div
            className={searchClick ? style.inputHMainless : style.inputHMain}
          >
            <div>
              <div
                className={
                  searchClick ? style.inputholderless : style.inputholder
                }
              >
                <div className={style.icon}></div>
                <div className={style.inputh}>
                  <input
                    type="text"
                    value={defaultSearch ? "" : assignValue}
                    className={style.input}
                    placeholder="Search by keyword"
                    onChange={(e) => {
                      onChangeMethod(e);
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        setSuggestions([]);
                        doSearch();
                      }
                    }}
                  />
                </div>
                <button
                  type="button"
                  disabled={!assignValue ? true : false}
                  className={style.button}
                  onClick={doSearch}
                >
                  Search
                </button>
              </div>
            </div>
            {suggestions.length > 0 && !hideSuggestion ? (
              <div className={style.suggestionH}>
                {suggestions.map((obj, index) => {
                  return (
                    <div
                      key={index}
                      className={style.drop}
                      onClick={(e) => handleClick(e)}
                      dangerouslySetInnerHTML={{
                        __html: obj.value,
                      }}
                    ></div>
                  );
                })}
              </div>
            ) : (
              <></>
            )}
            {error ? <ErrorHanding error={error}></ErrorHanding> : <></>}
            {showfilter && (
              <>
                <div className={style.resultHC}>
                  <div className={style.st}>
                    &quot;{searchterm}&quot;&nbsp;&nbsp;
                  </div>
                  <div className={style.num}>
                    {totalNum} {totalNum > 1 ? "results" : "result"}
                  </div>

                  {/* {totalNum > 1 && (
                    <div className={style.sortddHolder}>
                      <div ref={dropdownRef} className="menu-container">
                        <button onClick={openSortDD} className="menu-trigger">
                          <span>Sort by</span>
                          <Icon name="chevron-down" size="small" />
                        </button>
                        <nav
                          className={`menu ${isActive ? "active" : "inactive"}`}
                        >
                          <ul>
                            {sortOption.map((list, index) => (
                              <li
                                key={index}
                                className={
                                  selectionDD == list["name"] ? "selected" : ""
                                }
                                onClick={() =>
                                  setSortOption(list["name"], list)
                                }
                              >
                                {list["name"]}
                              </li>
                            ))}
                          </ul>
                        </nav>
                      </div>
                    </div>
                  )} */}
                </div>

                {topresult && (
                  <CardContainer
                    className={style.con}
                    style={{
                      padding: "24px",
                      marginTop: "40px",
                      marginBottom: "16px",
                      height: "auto",
                    }}
                  >
                    <div className={style.topResult}>Top result</div>
                    <div className={style.resultitemtop}>
                      {topresult.pageTemplate_s == "documents" ? (
                        <>
                          <div className={style.titleDoc}>
                            {topresult.pageBody}
                          </div>
                          <InnerHtml
                            description={`<a href="{title:${topresult.pageTitle_t}&contentType:${topresult.pageTemplate_s}}" target="_blank"  oncontextmenu="return false">View/Download</a>`}
                          />
                        </>
                      ) : (
                        <>
                          <div className={style.cat}>
                            {topresult.pageCategory_t}
                          </div>
                          <div className={style.title}>
                            {topresult.pageTitle_t}
                          </div>
                          {topresult.highlight && (
                            <div
                              className={style.highlight}
                              dangerouslySetInnerHTML={{
                                __html: `...${topresult.highlight}...`,
                              }}
                            ></div>
                          )}
                          <div className={style.url}>{topresult.pageURL_s}</div>
                          <div className={style.linkh}>
                            <div
                              className={style.preview}
                              onClick={() => preview(topresult.pageURL_s)}
                            >
                              <Icon name="arrow-new-window" size="small"></Icon>
                              <TextLink variant="small">Preview</TextLink>
                            </div>
                            <div
                              className={style.clink}
                              onClick={() => copyLink(topresult.pageURL_s)}
                            >
                              <Icon
                                name="symbol-plus-square"
                                size="small"
                              ></Icon>
                              <TextLink variant="small">Copy Link</TextLink>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </CardContainer>
                )}
                {resultdata && resultdata.length > 0 ? (
                  resultdata.map((d, index) => (
                    <div key={index} className={style.resultitem}>
                      {d.pageTemplate_s == "documents" ? (
                        <>
                          <div className={style.titleDoc}>{d.pageBody}</div>
                          <InnerHtml
                            description={`<a href="{title:${d.pageTitle_t}&contentType:${d.pageTemplate_s}}" target="_blank"  oncontextmenu="return false">View/Download</a>`}
                          />
                        </>
                      ) : (
                        <>
                          <div className={style.cat}>{d.pageCategory_t}</div>
                          <div className={style.title}>{d.pageTitle_t}</div>
                          {d.highlight && (
                            <div
                              className={style.highlight}
                              dangerouslySetInnerHTML={{
                                __html: `...${d.highlight}...`,
                              }}
                            ></div>
                          )}
                          <div className={style.url}>{d.pageURL_s}</div>
                          <div className={style.linkh}>
                            <div
                              className={style.preview}
                              onClick={() => preview(d.pageURL_s)}
                            >
                              <Icon name="arrow-new-window" size="small"></Icon>
                              <TextLink variant="small">Preview</TextLink>
                            </div>
                            <div
                              className={style.clink}
                              onClick={() => copyLink(d.pageURL_s)}
                            >
                              <Icon
                                name="symbol-plus-square"
                                size="small"
                              ></Icon>
                              <TextLink variant="small">Copy Link</TextLink>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  ))
                ) : (
                  <></>
                )}
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default SearchInput;
