import { configureStore } from "@reduxjs/toolkit";
import authSlice from "./authentication-slice";
import navigationSlice from "./navigation-slice";
import userSlice from "./user-slice";
import domSlice from "./dom-slice";
import profileSlice from "./profile-user-slice";
import gqlDataReducer from "./gqlDataReducer";
import gqlFTODataReducer from "./gqlFTODataReducer";
import liveSlice from "./live-preview-slice";
import searchResultSlice from "./search-result-slice";
import searchGlobalResultSlice from "./search-global-result-slice";
import notificationSlice from "./notification-slice";
import bookmarkDescSlice from "./bookmark-desc-slice";
import recognitionSlice from "./recognition-slice";

const store = configureStore({
  reducer: {
    auth: authSlice.reducer,
    navigation: navigationSlice.reducer,
    user: userSlice.reducer,
    dom: domSlice.reducer,
    userProfile: profileSlice.reducer,
    gqlData: gqlDataReducer,
    gqlFTOData: gqlFTODataReducer,
    live: liveSlice.reducer,
    searchresult: searchResultSlice.reducer,
    searchglobalresult: searchGlobalResultSlice.reducer,
    globalNotification: notificationSlice.reducer,
    bookmarkDescription: bookmarkDescSlice.reducer,
    recognition: recognitionSlice.reducer,
  },
});

export default store;
