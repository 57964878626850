import { useEffect, useState } from "react";
import APIError from "../../ErrorHandling/APIError";
import styles from "./LocationSelectDropdown.module.css";

import { useQuery } from "@apollo/client";
import { TextLink } from "@mgmresorts/mgm-ui";
import moment from "moment";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import dollarRounded from "../../../assets/images/dollar-rounded.svg";
import CustomSelectDropDown from "../../../components/common/customSelectDropDown/customSelectDropdown";
import Loader from "../../../components/global/Loader";
import { apiMiddlewareClient } from "../../../features/api-wrapper/apiMiddlewareWrapper";
import { GET_PAYPERIODS_DROPDOWN } from "../../../features/api-wrapper/apiQueries";
import ChooseCta from "../../../hooks/ChooseCta";
import LocationSpecificTimesheet from "./LocationSpecificTimesheet";

const LocationSelectDropdown = (props) => {
  const [locationValue, setLocationValue] = useState();
  const [selectedValue, setSelectedValue] = useState("0");
  const [selectedText, setSelectedText] = useState("");
  const [isPayslipUrl, setIsPaylipUrl] = useState(false);
  const [startDates, setStartDates] = useState();
  const navigate = useNavigate();

  const { loading, error, data } = useQuery(GET_PAYPERIODS_DROPDOWN, {
    client: apiMiddlewareClient,
  });
 
  const globalProperties = useSelector(
    (state) => state.globalNotification?.globalProperties
  );

  useEffect(() => {
    if (props.currentLoc  && props.currentLoc !== "" && data?.payperiodsDropDown) {
      let selectedIndex;
      data.payperiodsDropDown.forEach((row, index) => {
        if (row.companyCode == props.currentLoc) {
          selectedIndex = index;
          setLocationValue(index.toString());
        }
      });
      const allStartDates = [];
        const defaultLocationData = data.payperiodsDropDown[selectedIndex];
        defaultLocationData && defaultLocationData.payPeriods.forEach((row) => {
          allStartDates.push(
            helperConvertDates(row.payPeriodStartDate, row.payPeriodEndDate)
          );
        });
        setStartDates(allStartDates);
        setSelectedValue("0");
        setSelectedText(allStartDates[0])
    }
  }, [props.currentLoc, data]);

  const helperConvertDates = (startDate, endDate) => {
    const month = globalProperties.short_month;
    const isCurrentDate = moment().isBetween(
      startDate,
      endDate,
      undefined,
      "[]"
    )
      ? "(Current)"
      : "";
    const str =
      month[startDate.slice(5, 7) - 1] +
      " " +
      startDate.slice(8, 10) +
      " - " +
      month[endDate.slice(5, 7) - 1] +
      " " +
      endDate.slice(8, 10) +
      ", " +
      endDate.slice(0, 4) +
      " " +
      isCurrentDate;
    return str;
  };

  useEffect(() => {
    if (props.selectedDropdownState) {
      const filterdText = props.selectedDropdownState;
      const selectedTextDate = helperConvertDates(
        moment(filterdText.startDate).format("YYYY-MM-DD"),
        moment(filterdText.endDate).format("YYYY-MM-DD")
      );
      setSelectedText(selectedTextDate);
      setIsPaylipUrl(true);
      navigate("", { state: {} });
    }
  }, [props.selectedDropdownState]);

  useEffect(() => {
    if (data && data.payperiodsDropDown && data.payperiodsDropDown.length > 0) {
      if (startDates && startDates.length > 0) {
        if (isPayslipUrl) {
          const selectedValue = startDates.findIndex(
            (item) => item == selectedText
          );
          setSelectedValue(selectedValue !== -1 ? selectedValue : "0");
        } else {
          setSelectedValue("0");
          setSelectedText("")
        }
      } 
      // else {
      //   const allStartDates = [];
      //   const defaultLocationData = data.payperiodsDropDown[0];
      //   defaultLocationData.payPeriods.forEach((row) => {
      //     allStartDates.push(
      //       helperConvertDates(row.payPeriodStartDate, row.payPeriodEndDate)
      //     );
      //   });
      //   setStartDates(allStartDates);
      // }
    }
  }, [data, isPayslipUrl]);

  const onChangeDropDownValue = (value, text) => {
    const selecteTxt = text.includes("Current")
      ? text.split("(Current)")[0]
      : text;
    setSelectedValue(value.toString());
    setSelectedText(selecteTxt);
  };

  const clickHandler = (e, link) => {
    e.preventDefault();
    navigate(link);
  };

  const payPeriodText =
    props?.staticData?.dictionaryLables?.select_your_pay_period;
  const paySlipText = props.viewAllTimecards[1]?.link.title;
  const paySlipHref = props.viewAllTimecards[1]?.link.href;
  const viewAllTimecardsText = props.viewAllTimecards[0]?.link.title;
  const viewAllTimecardsHref = props.viewAllTimecards[0]?.link.href;

  console.log('startDates', startDates);

  if (loading) return <Loader />;
  if (error) return <APIError error={error} />;
  
  return (
    <>
      <div className={styles.dropDownRowContainer}>
        <div className={styles.dropdownOuterContainer}>
          <span>{payPeriodText}</span>
          <div className={styles.dropdownContainer}>
            {startDates && startDates.length > 0 && (
              <>
                <CustomSelectDropDown
                  id="dateDropdown"
                  text="date"
                  optionsList={startDates}
                  onChange={(value, text) => onChangeDropDownValue(value, text)}
                  selected={selectedText == "" ? startDates[0] : selectedText}
                />
              </>
            )}
          </div>
        </div>
        <TextLink
          href={paySlipHref}
          onClick={(e) => clickHandler(e, paySlipHref)}
          variant="large"
        >
          {/* className={styles.payslipImage} */}
          <img src={dollarRounded} />
          <div className={styles.payslipText}>{paySlipText}</div>
        </TextLink>
      </div>
      {selectedValue && locationValue && (
        <LocationSpecificTimesheet
          staticData={props.staticData}
          currLocCode={props.currentLoc}
          payPeriodStartDate={
            data?.payperiodsDropDown[locationValue].payPeriods[selectedValue]
              .payPeriodStartDate
          }
          payPeriodEndDate={
            data?.payperiodsDropDown[locationValue].payPeriods[selectedValue]
              .payPeriodEndDate
          }
        />
      )}
      <ChooseCta
        type="text_link"
        style={styles.viewAllTimecardsLink}
        data={{
          label: viewAllTimecardsText,
          url: viewAllTimecardsHref,
          openIn: "new_window",
          leadingIcon: "arrow-new-window",
          trailingIcon: null,
        }}
        size="large"
      />
    </>
  );
};

export default LocationSelectDropdown;
