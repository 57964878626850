import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useFetchData from "../../hooks/useFetchData";
import TabPanel from "../common/tab-panel/TabPanel";
import { Tab } from "@mgmresorts/mgm-ui";
import styles from "./RecognitionReGi.module.css";
import RecognitionReGiModal from "./RecognitionReGiModal";
import { useLazyQuery } from "@apollo/client";
import "react-alice-carousel/lib/alice-carousel.css";
import "react-initials-avatar/lib/ReactInitialsAvatar.css";
import { recognitionActions } from "../../data/recognition-slice";
import { apiMiddlewareClient } from "../../features/api-wrapper/apiMiddlewareWrapper";
import { FEEDBACK_RE_GI } from "../../features/api-wrapper/apiQueries";
import APIError from "../../pages/ErrorHandling/APIError";
import RecognitionReGiList from "./RecognitionReGiList";

const max_recognition_count = 6;

const RecognitionReGi = (props) => {
  console.log('props ###',props);
  const {
    dictionaryLables: cs_text,
    links_dictionary: cs_link,
  } = props.data;

  const [apiData, setapiData] = useState();
  const [tabMenu, settabMenu] = useState();
  const [selectedType, setSelectedType] = useState("rReceived");
  const [badgesA, setBadgesA] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [modalData, setModalData] = useState();
  const data_global = useSelector((state) => state.globalNotification);
  const cs_global = data_global?.globalProperties;

 

  const dispatch = useDispatch();

  const { data: badgeData } = useFetchData("", "recognition_badges");

  const language = useSelector(
    (state) => state.gqlData?.profile?.preferredLanguage
  );

  // feedbackReGi
  const [getData, { loading, error, data: apiDataN }] = useLazyQuery(
    FEEDBACK_RE_GI,
    {
      client: apiMiddlewareClient,
      // fetchPolicy: "cache-first",
      fetchPolicy: "network-only",
      // pollInterval: 5000,
    }
  );

  console.log(loading, error);

  const isRecognitionSubmited = useSelector(
    (state) => state.recognition.recognitionSubmited
  );

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    //graphql provides its own way to call the API multiple times using - useLazyQuery and cache:network-only
    if (isRecognitionSubmited) {
      getData();
      // setTimeout(() => {
      !loading && dispatch(recognitionActions.setRecognitionSubmited(false));
      // }, 3000);
    }
  }, [isRecognitionSubmited]);

  //may remove this
  useEffect(() => {
    badgeData &&
      setBadgesA(
        badgeData[0]?.data.badges.map((d) => {
          return { ...d, icon: d?.icon?.filename };
        })
      );
  }, [badgeData]);

  useEffect(() => {
    if (
      apiDataN?.feedbackReceived?.recognitionReceived?.length ||
      apiDataN?.feedbackGiven?.length
    ) {
      var fgiven =
        apiDataN?.feedbackGiven?.length &&
        [...apiDataN.feedbackGiven]
          .reverse()
          // .sort((a, b) => new Date(b.feedbackDate) - new Date(a.feedbackDate))
          ?.slice(0, max_recognition_count);

      // Data is already sorted from backend
      var freceived =
        apiDataN?.feedbackReceived?.recognitionReceived?.length &&
        [...apiDataN.feedbackReceived.recognitionReceived]
          .reverse()
          // .sort((a, b) => new Date(b.feedbackDate) - new Date(a.feedbackDate))
          ?.slice(0, max_recognition_count);
      const result = {
        ...apiDataN,
        feedbackGiven: fgiven,
        feedbackReceived: { recognitionReceived: freceived },
      };
      if (result) {
        // if (apiData?.rReceived?.length) {
        if (result?.feedbackReceived?.recognitionReceived?.length) {
          setSelectedType("rReceived");
        } else {
          setSelectedType("rGiven");
        }

        settabMenu([
          {
            id: "rReceived",
            label: cs_text.recognition_received_label,

            disabled:
              result?.feedbackReceived?.recognitionReceived == 0 ||
              result?.feedbackReceived?.recognitionReceived?.length == 0,
          },
          {
            id: "rGiven",
            label: cs_text.recognition_given_label,
            disabled:
              result?.feedbackGiven == 0 || result?.feedbackGiven.length == 0,
          },
        ]);
        setapiData(result);
      }
    }
  }, [apiDataN]);

  const openModalFn = useCallback(() => {
    setOpenModal(true);
  }, []);
  
  const obj = {
    openModalFn,
    setModalData,
    cs_global,
    cs_text,
    cs_link,
    badgesA,
    language,
  };

  return (
    <>
      {error ? (
        <APIError error={error} />
      ) : (
        <>
          {apiData?.feedbackReceived?.recognitionReceived?.length ||
          apiData?.feedbackGiven?.length ? (
            <div className={styles.recogReceived}>
              {/* {tabMenu && (
                <Tabs
                  onClick={setSelectedType}
                  selectedType={selectedType}
                  tabs={tabMenu}
                  theme="golden"
                  type="type2"
                />
              )} */}
              <div className={styles.tabHolder}>
                <Tab
                  label={cs_text.recognition_received_label}
                  className={`${styles.tabItem} ${
                    selectedType == "rReceived" ? styles.active : ""
                  }`}
                  onClick={() => setSelectedType("rReceived")}
                />
                <Tab
                  label={cs_text.recognition_given_label}
                  className={`${styles.tabItem} ${
                    selectedType == "rGiven" ? styles.active : ""
                  }`}
                  onClick={() => setSelectedType("rGiven")}
                />
              </div>

              {tabMenu && apiData && (
                <>
                  {selectedType == "rReceived" && (
                    <TabPanel selectedType={selectedType} id={"rReceived"}>
                      <RecognitionReGiList
                        type="Received"
                        data={apiData?.feedbackReceived?.recognitionReceived}
                        {...obj}
                      />
                    </TabPanel>
                  )}
                  {selectedType == "rGiven" && (
                    <TabPanel selectedType={selectedType} id={"rGiven"}>
                      <RecognitionReGiList
                        type="Given"
                        data={apiData?.feedbackGiven}
                        {...obj}
                      />
                    </TabPanel>
                  )}
                </>
              )}
              {openModal && (
                <RecognitionReGiModal
                  {...modalData}
                  language={language}
                  type={selectedType}
                  heading={
                    selectedType == "rReceived"
                      ? cs_text.recognition_received_label
                      : cs_text.recognition_given_label
                  }
                  closeModal={() => setOpenModal(false)}
                />
              )}
            </div>
          ) : null}
        </>
      )}
    </>
  );
};

export default RecognitionReGi;
