import { createSlice } from "@reduxjs/toolkit";

const notificationSlice = createSlice({
  name: "notifications",
  initialState: {
    back:null,
    continue:null,
    confirmationPopupOn:false,
    
  },
  reducers: {
    setNotification(state, action) {
      if (Object.keys(state).length == 0) {
        state = action.payload;
      }
      console.log("Redux >>> ", action.payload);
      return action.payload;
    },
    setGoBack(state, action){
      state.back =  action.payload;
    },
    setContinue(state, action){
      state.continue =  action.payload;
    },
    setConfirmationPopupVisibility(state, action)
    {
      state.confirmationPopupOn = action.payload;
    },
    
  },
});

export const { setNotification } = notificationSlice.actions;
export const {setGoBack} = notificationSlice.actions;
export const {setContinue,setConfirmationPopupVisibility} = notificationSlice.actions;
export default notificationSlice;
