import { useEffect, useMemo, useRef, useState } from "react";
import GetDynamicComponent from "../../hooks/GetDynamicComponent";
import styles from "./HorizontalGroup.module.css";
import { useSelector } from "react-redux";
import { Icon } from "@mgmresorts/mgm-ui";
import useWindowDimensions from "../../hooks/useScreenDetails";

const HorizontalGroup = (props) => {
  //console.log("HorizontalGroup >> ", props);

  const bodyRef = useRef();
  const [carouselPositions, setCarouselPositions] = useState([]);
  const [currentItem, setCurrentItem] = useState(0);
  const [disablePrevious, setDisablePrevious] = useState(false);
  const [disableNext, setDisableNext] = useState(false);
  const [halfContainer, setHalfContainer] = useState(0);
  const [isMobile] = useWindowDimensions();
  const globalProperties = useSelector(
    (state) => state.globalNotification?.globalProperties
  );

  const packingHalfWidth = (array) => {
    let newData = [];
    let firstHalf = null;
    for (let i = 0; i < array.length; i++) {
      if (array[i]?.data?.halfWidth) {
        if (firstHalf !== null) {
          newData.push({
            firstHalf,
            secondHalf: { ...array[i] },
            halfWidth: true,
            packed: true,
          });
          firstHalf = null;
        } else {
          firstHalf = { ...array[i] };
        }
      } else {
        if (firstHalf !== null) {
          newData.push({
            firstHalf,
            secondHalf: null,
            halfWidth: true,
            packed: true,
          });
          firstHalf = null;
        }
        newData.push({ ...array[i] });
      }
    }
    if (firstHalf !== null) {
      newData.push({
        firstHalf,
        secondHalf: null,
        halfWidth: true,
        packed: true,
      });
    }
    return newData;
  };

  const getCarouselPositions = () => {
    let positions = [];
    bodyRef.current.querySelectorAll(".item-holder").forEach(function (div) {
      positions.push([div.offsetLeft, div.offsetLeft + div.offsetWidth]);
    });
    setCarouselPositions([...positions]);
    setHalfContainer(bodyRef.current.offsetWidth / 2);
  };

  const goCarousel = (direction) => {
    var currentScrollLeft = bodyRef.current.scrollLeft;
    var currentScrollRight = currentScrollLeft + bodyRef.current.offsetWidth;
    if (currentScrollLeft === 0 && direction === "next") {
      setCurrentItem(1);
    } else if (
      currentScrollRight === bodyRef.current.scrollWidth &&
      direction === "previous"
    ) {
      setCurrentItem(carouselPositions.length - 2);
    } else {
      var currentMiddlePosition = currentScrollLeft + halfContainer;
      for (var i = 0; i < carouselPositions.length; i++) {
        if (
          currentMiddlePosition > carouselPositions[i][0] &&
          currentMiddlePosition < carouselPositions[i][1]
        ) {
          let item = i;
          if (direction === "next") {
            let tempItem = item >= carouselPositions.length - 1 ? 0 : item + 1;
            setCurrentItem(tempItem);
            break;
          } else if (direction === "previous") {
            let tempItem = item === 0 ? carouselPositions.length - 1 : item - 1;
            setCurrentItem(tempItem);
            break;
          }
        }
      }
    }
  };

  useEffect(() => {
    setDisablePrevious(currentItem === 0);
    setDisableNext(currentItem >= carouselPositions.length - 1);
  }, [currentItem, carouselPositions]);

  useEffect(() => {
    if (carouselPositions.length > 0) moveCarousel();
  }, [currentItem]);

  const moveCarousel = () => {
    bodyRef.current.scrollTo({
      left: carouselPositions[currentItem][0],
      behavior: "smooth",
    });
  };

  useEffect(() => {
    getCarouselPositions();
    window.addEventListener("resize", getCarouselPositions);
    return () => {
      window.removeEventListener("resize", getCarouselPositions);
    };
  }, []);

  const packedData = useMemo(
    () => packingHalfWidth(props.groupList),
    [props.groupList]
  );

  return (
    <div className={styles.horizontalGroup}>
      <div className={styles.horizontalHeading}>
        <h2>{props.groupList[0].horizontalGroupHeading}</h2>
        <div className={styles.navigation}>
          <p>
            {isMobile ? props.groupList?.length : props.groupList?.length}{" "}
            {globalProperties?.items_label}
          </p>
          {!isMobile && (
            <>
              <div
                className={`${styles.leftArrow} ${
                  disablePrevious ? styles.disable : ""
                }`}
                onClick={() => goCarousel("previous")}
              >
                <Icon name="chevron-left" size="large" />
              </div>
              <div
                className={`${styles.rightArrow} ${
                  disableNext ? styles.disable : ""
                }`}
                onClick={() => goCarousel("next")}
              >
                <Icon name="chevron-right" size="large" />
              </div>
            </>
          )}
        </div>
      </div>
      <div ref={bodyRef} className={styles.horizontalBody}>
        {!isMobile &&
          packedData.map((item, index) => (
            <div className={`${styles.itemHolder} item-holder`} key={index}>
              {item.packed ? (
                <>
                  {item.halfWidth && (
                    <>
                      <section key={index} className={styles.fiftyFifty}>
                        <GetDynamicComponent
                          comp={item.firstHalf.key}
                          type={item.firstHalf.type}
                          data={item.firstHalf.data}
                          error={item.firstHalf.error}
                        />
                        {item.secondHalf !== null && (
                          <GetDynamicComponent
                            comp={item.secondHalf?.key}
                            type={item.secondHalf?.type}
                            data={item.secondHalf?.data}
                            error={item.secondHalf?.error}
                          />
                        )}
                      </section>
                    </>
                  )}
                </>
              ) : (
                <section key={index}>
                  <GetDynamicComponent
                    comp={item.key}
                    type={item.type}
                    data={item.data}
                    error={item.error}
                  />
                </section>
              )}
            </div>
          ))}
        {isMobile &&
          props.groupList?.map((item, index) => (
            <div className={`${styles.itemHolder} item-holder`} key={index}>
              <section key={index} className={styles.onMobile}>
                <GetDynamicComponent
                  comp={item.key}
                  type={item.type}
                  data={item.data}
                  error={item.error}
                />
              </section>
            </div>
          ))}
      </div>
    </div>
  );
};

export default HorizontalGroup;
