import { Button } from "@mgmresorts/mgm-ui";
import { useNavigate } from "react-router-dom";
import ContentAuthorNote from "../../pages/ErrorHandling/ContentAuthorNote";
import ConfirmationPopup from "../global/ConfirmPrompt";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { downloadFile } from "../../utils/fileDownloadPreview";
import NewHireAccesDelayModal from "../global/NewHireAccesDelayModal";

const CTAButtonAutoType = (props) => {
  //console.log(props);
  console.log('CTAButtonAutoType props @@@',props);
  let fullWidth = props.fullWidth ? props.fullWidth : false;

  if (props.data === null) return;
  if (props.data.length === 0) return;

  return (
    <>
      {props.data && (
        <>
          {Array.isArray(props.data) &&
            props.data.length !== 0 &&
            props.data.map((item, index) => (
              <CTAButtonAutoTypeSingle
                {...props}
                key={index}
                ctaIndex={index}
                data={item}
                fullWidth={fullWidth}
              />
            ))}
          {!Array.isArray(props.data) && (
            <CTAButtonAutoTypeSingle fullWidth={fullWidth} {...props} />
          )}
        </>
      )}
    </>
  );
};

export default CTAButtonAutoType;

const CTAButtonAutoTypeSingle = (props) => {
  //console.log("props >> ", props);
  console.log('CTAButtonAutoTypeSingle props @@@',props);
  let url = props.data?.url;
  const globalNotification = useSelector((state) => state.globalNotification);
  const { whiteListedDomain } = globalNotification || [];
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isContinue, setIsContinue] = useState(false);
  const [isNewHireModal, setNewHireModal] = useState(false);
  const isNewHire = useSelector((state) => state.gqlData?.profile?.newHire);

  const hidePopup = () => {
    setNewHireModal(false);
  };

  let buttonVariants = ["primary", "secondary", "tertiary"];
  const navigate = useNavigate();
  const openURL = (url)=>{
    if (navigator.userAgent.indexOf('Mac OS X') != -1) {
      window.location.assign(url);
    } else {
    window.open(url, "_blank");
    }
  }
  const clickHandler = (url, opensIn) => {
    if(props.data?.download == true)
    {
      downloadFile(props);
      return;
    }
    if (opensIn === "new_window") {
      if (isNewHire && url.includes("neocase")) {
        setNewHireModal(true);
      } else {
        whiteListedDomain.some((v) => url.includes(v))
          ? //window.open(url, "_blank")
          openURL(url): setIsModalOpen(true);
      }
    } else if (opensIn === "same_window") {
      navigate(url);
    } else if (opensIn === "modal") {
      if (props.ctaIndex != undefined) {
        props.openModal(props.ctaIndex);
      } else {
        props.openModal(props.args);
      }
    }
  };

  useEffect(() => {
    if (isContinue) {
      setIsModalOpen(false);
      setIsContinue(false);
      openURL(url);
      //window.open(url, "_blank");
    }
  }, [isContinue]);

  if (props.data === null) return;
  if (props.data.length === 0) return;

  let size = props.size
    ? props.size
    : props.data.size
    ? props.data.size
    : "small";

  return (
    <>
      {props.data?.label !== "" && (
        <>
          {buttonVariants.findIndex(
            (variant) => variant === props.data.type?.toLowerCase()
          ) !== -1 ? (
            <Button
              icon={props.data?.leadingIcon}
              data-cslp={props.data?.labelHash ? props.data?.labelHash : null}
              label={props.data?.label}
              size={size || "small"}
              fullWidth={props.fullWidth}
              variant={
                props.data.type ? props.data.type.toLowerCase() : "primary"
              }
              className={props.style}
              onClick={() => clickHandler(props.data?.url, props.data?.openIn)}
            />
          ) : (
            <ContentAuthorNote
              message={`'${props.type}' -> this varient of CTA will not be supported for this component!`}
            />
          )}
          {isModalOpen && (
            <ConfirmationPopup
              isModalOpen={isModalOpen}
              onContinue={setIsContinue}
              onClose={() => {
                setIsModalOpen(false);
              }}
            />
          )}
          {isNewHireModal && <NewHireAccesDelayModal onClose={hidePopup} />}
        </>
      )}
    </>
  );
};
