import styles from "./MainNavigation.module.css";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
// changes in main nav
const MainNavigation = (props) => {
  const gqlData = useSelector((state) => state.gqlData);
  const payRateGroup = gqlData?.profile?.payRateGroup;
  const rawResponse = useSelector((state) => state.navigation.rawResponse);
  //const csViewAll = useSelector((state) => state.navigation.csViewAll);
  const selectedNavigation = useSelector(
    (state) => state.navigation.selectedNavigation
  );
  const headerHeight = useSelector((state) => state.dom.headerHeight);
  //const [initialLoad, setInitialLoad] = useState(true);
  const [subNavVisibility, setSubNavVisibility] = useState([]);
  const [selectedNavIndex, setSelectedNavIndex] = useState(-1);
  const [thirdLevelNav, setThirdLevelNav] = useState({});
  const [thirdLevelNavItems, setThirdLevelNavItems] = useState([]);

  useEffect(() => {
    //if (initialLoad && selectedNavigation.length !== 0) {
    if (selectedNavigation.length !== 0) {
      setSubNavVisibility(
        rawResponse.map((item) => {
          return {
            hasSubNav: item.Children === undefined ? false : true,
            isActive: selectedNavigation[0]?.label === item.Title,
          };
        })
      );

      setSelectedNavIndex(
        rawResponse.findIndex(
          (item) => selectedNavigation[0]?.label === item.Title
        )
      );
      if (selectedNavigation.length === 3) {
        setThirdLevelNavItems([
          ...rawResponse[selectedNavigation[0]?.index].Children[
            selectedNavigation[1]?.index
          ].Children,
        ]);
        setThirdLevelNav({
          showPage: selectedNavigation[1]?.showPage,
          title: selectedNavigation[1]?.label,
          link: selectedNavigation[0]?.link + selectedNavigation[1]?.link,
        });
      }
      //setInitialLoad(false);
    } else if (
      selectedNavigation[0]?.label === "Dashboard" &&
      selectedNavIndex !== 0
    ) {
      handleNavClick(null, 0);
      setThirdLevelNav({});
      setThirdLevelNavItems([]);
    }
    if (selectedNavigation.length === 3 && thirdLevelNavItems.length === 0) {
      showThirdLevelNav(
        selectedNavigation[1].index,
        selectedNavigation[0].index,
        selectedNavigation[1].absoluteLink
      );
    }
    if (selectedNavigation.length < 3) {
      setThirdLevelNav({});
      setThirdLevelNavItems([]);
    }
  }, [selectedNavigation]);

  const handleNavClick = (e, index) => {
    if (subNavVisibility.length > 0) {
      let newSub = [...subNavVisibility];
      if (newSub[index]?.hasSubNav && e !== null) e.preventDefault();
      if (props.isMobile && !newSub[index]?.hasSubNav) props.toggleNavigation();
      newSub[index].isActive = !newSub[index]?.isActive;
      if (selectedNavIndex !== -1 && index !== selectedNavIndex) {
        newSub[selectedNavIndex].isActive = !newSub[selectedNavIndex].isActive;
      }
      setSubNavVisibility(newSub);
      setSelectedNavIndex((prev) => (prev === index ? -1 : index));
    }
  };

  const handleSubnavClick = (e, subIndex, index, pLink, blockDefault) => {
    if (blockDefault) e.preventDefault();
    if (index) {
      if (props.isMobile) props.toggleNavigation();
      if (rawResponse[index].Children[subIndex].showPage === "false") {
        showThirdLevelNav(subIndex, index, pLink);
      }
    } else {
      if (props.isMobile) props.toggleNavigation();
    }
  };

  const showThirdLevelNav = (subIndex, index, pLink) => {
    setThirdLevelNav({
      showPage: rawResponse[index].Children[subIndex].showPage,
      title: rawResponse[index].Children[subIndex].Title,
      link: pLink,
    });
    setThirdLevelNavItems([...rawResponse[index].Children[subIndex].Children]);
  };

  const hideThirdLevelNav = () => {
    /*if (thirdLevelNav.showPage !== "true") {
      e.preventDefault();
    }*/
    if (props.isMobile) props.toggleNavigation();
    setThirdLevelNav({});
    setThirdLevelNavItems([]);
  };

  const handleThirdLevelNav = () => {
    if (props.isMobile) props.toggleNavigation();
  };

  return (
    <>
      {payRateGroup && (
        <nav
          className={styles.mainNav}
          style={{
            top: headerHeight,
            maxHeight: !props.isMobile
              ? `calc(100vh - ${headerHeight}px)`
              : "none",
          }}
        >
          <ul
            className={`${styles.navList} ${
              thirdLevelNavItems.length != 0 ? styles.navHide : ""
            }`}
          >
            {rawResponse.map((nav, index) => (
              <li key={nav.AbsoluteUrl}>
                {nav.Children == undefined &&
                  /* !(
                    (nav.denySalaryWorker == "true" &&
                      payRateGroup == "Salary") ||
                    (nav.denyHourlyWorker == "true" &&
                      payRateGroup == "Hourly")
                  ) &&*/ (
                    <NavLink
                      to={nav.AbsoluteUrl}
                      onClick={(e) => handleNavClick(e, index)}
                      className={`${
                        subNavVisibility[index]?.isActive
                          ? styles.navActive
                          : ""
                      }`}
                    >
                      <img className={styles.onNormal} src={nav.icon} />
                      <img className={styles.onHover} src={nav.hoverIcon} />
                      <img className={styles.onActive} src={nav.activeIcon} />
                      <img
                        className={styles.hasChildOpen}
                        src={nav.activeBlueIcon}
                      />
                      {nav.Title}
                    </NavLink>
                  )}
                {nav.Children != undefined &&
                  /* !(
                    (nav.denySalaryWorker == "true" &&
                      payRateGroup == "Salary") ||
                    (nav.denyHourlyWorker == "true" &&
                      payRateGroup == "Hourly")
                  ) &&*/ (
                    <>
                      <a
                        href="#"
                        className={`${styles.hasChild} ${
                          subNavVisibility[index]?.isActive
                            ? styles.navActive
                            : ""
                        }`}
                        onClick={(e) => handleNavClick(e, index)}
                      >
                        <img className={styles.onNormal} src={nav.icon} />
                        <img className={styles.onHover} src={nav.hoverIcon} />
                        <img className={styles.onActive} src={nav.activeIcon} />
                        <img
                          className={styles.hasChildOpen}
                          src={nav.activeBlueIcon}
                        />
                        {nav.Title}
                      </a>
                      <ul
                        className={`${styles.subNavList} ${
                          subNavVisibility[index]?.isActive ? styles.show : ""
                        }`}
                      >
                        {nav.Children.map((subnav, subIndex) => (
                          <li key={subnav.AbsoluteUrl}>
                            {subnav.Children == undefined ||
                            subnav.showChild == "false" ? (
                              <>
                                {/* (csViewAll ||
                                  !(
                                    (subnav.denySalaryWorker == "true" &&
                                      payRateGroup == "Salary") ||
                                    (subnav.denyHourlyWorker == "true" &&
                                      payRateGroup == "Hourly")
                                  )) &&*/ (
                                  <NavLink
                                    onClick={(e) =>
                                      handleSubnavClick(e, subIndex)
                                    }
                                    to={
                                      subnav.AbsoluteUrl
                                    }
                                    /*to={
                                      subnav.pageType == "page_external"
                                        ? subnav.pageExtUrl
                                        : subnav.AbsoluteUrl
                                    }*/
                                    target={
                                      subnav.pageType == "page_external"
                                        ? "_blank"
                                        : ""
                                    }
                                    className={({ isActive }) =>
                                      isActive ? styles.subNavActive : undefined
                                    }
                                  >
                                    {subnav.Title}
                                  </NavLink>
                                )}
                              </>
                            ) : (
                              <>
                                {subnav.showPage === "true" ? (
                                  <>
                                    {/* (csViewAll ||
                                      !(
                                        (subnav.denySalaryWorker == "true" &&
                                          payRateGroup == "Salary") ||
                                        (subnav.denyHourlyWorker == "true" &&
                                          payRateGroup == "Hourly")
                                      )) && */ (
                                      <NavLink
                                        onClick={(e) =>
                                          handleSubnavClick(
                                            e,
                                            subIndex,
                                            index,
                                            subnav.AbsoluteUrl
                                          )
                                        }
                                        to={
                                          subnav.pageType == "page_external"
                                            ? subnav.pageExtUrl
                                            : subnav.AbsoluteUrl
                                        }
                                        target={
                                          subnav.pageType == "page_external"
                                            ? "_blank"
                                            : ""
                                        }
                                        className={({ isActive }) =>
                                          isActive
                                            ? styles.subNavActive
                                            : undefined
                                        }
                                      >
                                        {subnav.Title}
                                      </NavLink>
                                    )}
                                  </>
                                ) : (
                                  <a
                                    href="#"
                                    onClick={(e) =>
                                      handleSubnavClick(
                                        e,
                                        subIndex,
                                        index,
                                        subnav.AbsoluteUrl,
                                        true
                                      )
                                    }
                                  >
                                    {subnav.Title}
                                  </a>
                                )}
                              </>
                            )}
                          </li>
                        ))}
                      </ul>
                    </>
                  )}
              </li>
            ))}
          </ul>
          <div
            className={`${styles.thirdLevelNav} ${
              thirdLevelNavItems.length == 0 ? styles.navHide : ""
            }`}
          >
            <div className={styles.thirdNavTitle}>
              <NavLink onClick={hideThirdLevelNav} to={thirdLevelNav.link}>
                {thirdLevelNav.title}
              </NavLink>
            </div>
            <ul>
              {thirdLevelNavItems.map((item) => (
                <li key={item.AbsoluteUrl}>
                  <NavLink
                    onClick={handleThirdLevelNav}
                    to={
                      item.pageType == "page_external"
                        ? item.pageExtUrl
                        : item.AbsoluteUrl
                    }
                    target={item.pageType == "page_external" ? "_blank" : ""}
                    className={({ isActive }) =>
                      isActive ? styles.subNavActiveL2 : undefined
                    }
                  >
                    {item.Title}
                  </NavLink>
                </li>
              ))}
            </ul>
          </div>
        </nav>
      )}
    </>
  );
};

export default MainNavigation;
