import InnerHtml from "../../dynamic/InnerHtml"
import styles from "./ActionGroupItem.module.css"
const ActionGroupItem = (props) => {
    console.log('props $$$$', props);
    return (<div className={styles.cHolder}>
        <div className={styles.header}>{props?.data?.heading}</div>
        {props?.data?.action_items.map((entry) => {
            return (<>
                {

                    entry?.items.map((eItem, index) => {
                        return (<><div className={styles.bHolder}><InnerHtml description={eItem?.action_description} /></div>
                            <div key={index} style={{ background: `url("${eItem?.image_url}")`, backgroundRepeat: 'no-repeat' }} className={styles.tileImg}><img src={eItem?.image_url} className={styles.dummyImg} style={{ visibility: 'hidden' }} /></div>

                        </>)
                    })


                }</>)

        })}
    </div>)
}
export default ActionGroupItem