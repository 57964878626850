import { Button } from "@mgmresorts/mgm-ui";
import InnerHtml from "./InnerHtml";
import { useNavigate } from "react-router-dom";
import EqualToIcon from "../../assets/equal-icon.svg";
import PlusIcon from "../../assets/plus-icon.svg";
import styles from "./SavingsPlan401K_NtEn_R.module.css";

export const SavingsPlan401K_NtEn_R = (props) => {
  const data = props?.rightReference?.length && props.rightReference[0];
  const navigate = useNavigate();
  const redirectToExternalURL = (entry) => {
    if (entry?.open_in == "new_window") {
      window.open(entry?.link?.href, "_blank");
    } else if (entry?.open_in === "same_window") {
      navigate(entry?.link?.href);
    }
  };
  const getStartedLinkObj = data?.links_dictionary.reduce((entry) => {
    if (entry && entry?.name == "get_started_button") {
      return entry;
    }
  });

  return (
    <div className={styles.notEnrolledContainer}>
      <div className={styles.dollarGroup}>
        <div className={styles.dollarElement}>
          <img
            src={data && data?.dictionary_with_icon[0].icon.url}
            className={styles.iconDollar}
          />
          <div className={styles.innerhtmlText}>
            <InnerHtml
              description={data && data?.dictionary_with_icon[0].html_value}
              // hash={modalContent?.$?.description}
            />{" "}
          </div>
        </div>
        <img className={styles.iconSign} src={PlusIcon} />
        <div className={styles.dollarElement}>
          <img
            src={data && data?.dictionary_with_icon[0].icon.url}
            className={styles.iconDollar}
          />
          <div className={styles.innerhtmlText}>
            <InnerHtml
              description={data && data?.dictionary_with_icon[1].html_value}
              // hash={modalContent?.$?.description}
            />{" "}
          </div>
        </div>
        <img className={styles.iconSign} src={EqualToIcon} />
        <div className={styles.dollarElement} style={{ margin: "0 15px" }}>
          <img
            src={data && data?.dictionary_with_icon[0].icon.url}
            className={styles.iconDollarBig}
          />
          <div className={styles.innerhtmlText}>
            <InnerHtml
              description={data && data?.dictionary_with_icon[2].html_value}
              // hash={modalContent?.$?.description}
            />{" "}
          </div>
        </div>
      </div>

      <Button
        className={styles.getStartedBtn}
        variant="outline"
        onClick={
          () => redirectToExternalURL(getStartedLinkObj)
          // redirectToExternalURL(
          //   data &&
          //     data?.links_dictionary.length &&
          //     data?.links_dictionary[0]?.link?.href
          // )
        }
      >
        {getStartedLinkObj?.link?.title}
      </Button>
    </div>
  );
};

export default SavingsPlan401K_NtEn_R;
