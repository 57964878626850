import ContentAuthorNote from "../pages/ErrorHandling/ContentAuthorNote";
//import RequestTimeOffForm from "../components/global/forms/RequestTimeOffForm";
//import RequestLoaForm from "../components/global/forms/RequestLoaForm";
import CallingOutForm from "../components/global/forms/CallingOutForm";
import SubmitRecognitionForm from "../components/global/forms/SubmitRecognitionForm";
const GetForm = (props) => {
  const componentMapping = [
    //{ formURL: "/forms/request-time-off", component: "RequestTimeOffForm" },
    //{ formURL: "/forms/leave-of-absence", component: "RequestLoaForm" },
    { formURL: "/forms/recognition-form", component: "SubmitRecognitionForm" },
    { formURL: "/forms/call-out", component: "CallingOutForm" },
  ];

  const componentNames = {
    //RequestTimeOffForm,
    //RequestLoaForm,
    CallingOutForm,
    SubmitRecognitionForm
  };

  console.log("PROPS >> ", props);

  if (props.error)
    return (
      <ContentAuthorNote
        message={`'${props.formURL}' -> ${props.error?.message}`}
      />
    );

  let index = componentMapping.findIndex(
    (item) => item.formURL === props.formURL
  );
  if (index === -1)
    return (
      <ContentAuthorNote
        message={`'${props.formURL}' -> This Form not yet created in frontend!`}
      />
    );

  let componentName = componentMapping[index]?.component;

  if (!componentName)
    return (
      <ContentAuthorNote
        message={`Something went wrong! Component not derived properly!`}
      />
    );

  let Component = componentNames[componentName];

  if (!Component)
    return (
      <ContentAuthorNote
        message={`Something went wrong! Component not derived properly!`}
      />
    );
  return <Component {...props} />;
};

export default GetForm;
