import styles from "./MultiSelection.module.css"
import { useEffect, useState, useRef } from "react";
import { Icon, Checkbox } from "@mgmresorts/mgm-ui";
import { GET_EMPLOYEE_NAME } from "../../../features/api-wrapper/apiQueries";
import { useLazyQuery } from "@apollo/client";
import { apiMiddlewareClient } from "../../../features/api-wrapper/apiMiddlewareWrapper";

const MultiSelection = (props) => {
    const dropdownWrapperRef = useRef(null);
    const delay = 300;
    let timer;
    let timer2;
    const [options, setOptions] = useState([]);
    const [inputStr, setInputStr] = useState();
    const [selection, setSelection] = useState([]);
    const [isOptionsOpen, setIsOptionsOpen] = useState(false);
    const handleClickOutside = (e) => {
        if (!dropdownWrapperRef.current.contains(e.target)) {
            setIsOptionsOpen(false);
        }
    };
    const updateChecked = (obj) => {
        for (var i = 0; i < options.length; i++) {
            if (options[i].employeeName == obj.employeeName) {
                options[i].checked = !options[i].checked
            }
        }
        setOptions([...options], options)
    }
    const checkIfPresent = (obj) => {
        let returnVal = false;
        for (var i = 0; i < selection.length; i++) {
            if (selection[i].employeeName == obj.employeeName) {
                returnVal = true;
            }
        }
        return returnVal;
    }
    const optionSelected = (obj) => {

        let isAlreadyPresent = checkIfPresent(obj);

        if (isAlreadyPresent == true) {
            removeItem(options, obj, false);
            return;
        }
        updateChecked(obj);
        if (timer2) {
            clearTimeout(timer2);
        }
        timer2 = setTimeout(() => {
            removeItem(options, obj, false);
            addItem(selection, obj, true);
        }, 10)



    }
    const selectionClicked = (obj) => {
        obj.checked = false;
        removeItem(selection, obj, true);
        addItem(options, obj, false);



    }
    const addItem = (arr, obj, isSelection) => {
        arr.push(obj);
        if (isSelection) {
            setSelection([...arr], arr)
        }
        else {
            setOptions([...arr], arr)
        }

    }
    const removeItem = (arr, obj, isSelection) => {
        for (var i = 0; i < arr.length; i++) {
            if (arr[i].employeeName == obj.employeeName) {
                arr.splice(i, 1);
            }
        }
        if (isSelection) {
            setSelection([...arr], arr)
        }
        else {
            setOptions([...arr], arr)
        }
        setOptions([...arr], arr)
    }
    const [getNameSearchAheadData, { data }] = useLazyQuery(GET_EMPLOYEE_NAME, {
        enabled: false, manual: true, fetchPolicy: 'no-cache', client: apiMiddlewareClient
    });

    useEffect(() => {
        if (selection && selection.length > 0) {

            let empNameArray = selection.map((obj) => {
                return obj.employeeName;
            })
            let empObjArray = selection.map((obj) => {
                return {
                    employeeID: obj.employeeID, employeeName: obj.employeeName, location: obj.location, position: obj.position
                };
            })

            props.employeeSelected(empObjArray)
            props.employeeNames(empNameArray)
        }
        else if (selection && selection.length == 0) {
            props.employeeSelected([])
            props.employeeNames([])
        }

    }, [selection])
    useEffect(() => {
        if (isOptionsOpen) {
            document.addEventListener("mousedown", handleClickOutside);
        }
        return () => document.removeEventListener("mousedown", handleClickOutside);
    }, [isOptionsOpen]);

    useEffect(() => {
        let nameList = data?.searchByEmployeeName ? data.searchByEmployeeName : []
        if (nameList) {
            nameList.map((obj, index) => {
                obj.id = index;
                obj.checked = false;
                return { ...obj, obj }
            })
        }

        if (data) {
            setOptions(nameList);
            setIsOptionsOpen(true);
        }

    }, [data]);
    useEffect(() => {
        if (inputStr && inputStr.length < 3) {
            setOptions([]);
            setIsOptionsOpen(false);
        }
    }, [inputStr, data]);

    const onClick = () => {

        setIsOptionsOpen(true);
    }
    const resetInput = () => {

        setInputStr("");
        setOptions([]);
        setIsOptionsOpen(false);
    }
    const onNameSearch = (e) => {

        setInputStr(e.target.value);
        if (e.target.value == "") {
            setOptions([]);
            setIsOptionsOpen(false);
            return;
        }
        if (e.target.value.length >= 3) {
            if (timer) {
                clearTimeout(timer);
            }
            timer = setTimeout(() => {
                getNameSearchAheadData({ variables: { "searchString": String(e.target.value) } })
            }, delay);

        }
        else {
            setOptions([]);
        }
    }
    return (
        <>
            <div className={styles.multiselectContainer} ref={dropdownWrapperRef}>
                <div className={styles.selectionHolder}>
                    {!selection ? <></> : selection.map((obj, index) => {
                        return <div className={styles.chip} key={index} onClick={() => { selectionClicked(obj) }}><span className={styles.chipCross}><Icon name='symbol-x' size="small"></Icon></span><span>{obj.employeeName}</span></div>
                    })}
                </div>
                <div>
                    <div className={styles.searchInputHolder}>
                        <input className={styles.searchInput} placeholder="Search by Name" value={inputStr} onClick={(e) => { onClick(e) }} onChange={(e) => { onNameSearch(e) }}></input> <div>{inputStr && inputStr.length > 0 ? <span onClick={(e) => { resetInput(e) }} ><Icon name="symbol-x-circle" size="small"></Icon></span> : <span><Icon name="magnifying-glass" size="small"></Icon></span>}</div>
                    </div>
                    {options.length == 0 ? <></> : (isOptionsOpen && <div className={styles.resultHolder}>
                        {options.map((obj, index) => {
                            return <span key={index} className={styles.optionItem} onClick={() => optionSelected(obj)}><Checkbox key={index} id={index} variant="small" checked={obj.checked} onChange={() => { }} /><div><div><label className={styles.empName}>{obj.employeeName}</label></div><div className={styles.otherDetails}><span>{obj.position}</span>|<span>{obj.location}</span></div></div></span>
                        })}
                    </div>)}
                </div>
            </div>
        </>
    )

}
export default MultiSelection;