import React, { useMemo } from "react";
import styles from "./LayoutAllInOne.module.css";
import GetDynamicComponent from "../../hooks/GetDynamicComponent";
import useLivePreview from "../../hooks/useLivePreview";
import WithFetchData from "../../hoc/WithFetchData";
import { livePreviewInfo } from "../../utils/live-preview";
import { useSelector } from "react-redux";
import HorizontalGroup from "../../components/dynamic/HorizontalGroup";

const LayoutAllInOne = (props) => {
  console.log("LayoutAllInOne", props.data);
  const breadcrumbForceEnable = useSelector(
    (state) => state.navigation.breadcrumbForceEnable
  );

  const packingHorizontalBlock = (array) => {
    let newData = [];
    let horiGroup = [];
    for (let i = 0; i < array.length; i++) {
      //console.log(i, " --HORI-- ", array[i]?.horizontalGroup);
      if (array[i]?.horizontalGroup === "") {
        newData.push({ ...array[i] });
      } else {
        if (array[i]?.horizontalGroup === "START") {
          horiGroup = [];
          horiGroup.push(array[i]);
        } else if (
          array[i]?.horizontalGroup === "STARTEND" ||
          array[i]?.horizontalGroup === "END"
        ) {
          if (array[i]?.horizontalGroup === "STARTEND") horiGroup = [];
          horiGroup.push(array[i]);
          if (horiGroup.length !== 0) {
            newData.push({
              key: "horizontalgroup",
              horiGroup: [...horiGroup],
              packed: true,
            });
          }
        } else if (array[i]?.horizontalGroup === "IN") {
          horiGroup.push(array[i]);
        }
      }
    }
    //console.log("newData >>>> ", newData);
    return newData;
  };

  const packingHalfWidth = (array) => {
    let newData = [];
    let firstHalf = null;
    for (let i = 0; i < array.length; i++) {
      if (array[i]?.data?.halfWidth) {
        if (firstHalf !== null) {
          newData.push({
            firstHalf,
            secondHalf: { ...array[i] },
            halfWidth: true,
            packed: true,
          });
          firstHalf = null;
        } else {
          firstHalf = { ...array[i] };
        }
      } else {
        if (firstHalf !== null) {
          newData.push({
            firstHalf,
            secondHalf: null,
            halfWidth: true,
            packed: true,
          });
          firstHalf = null;
        }
        newData.push({ ...array[i] });
      }
    }
    if (firstHalf !== null) {
      newData.push({
        firstHalf,
        secondHalf: null,
        halfWidth: true,
        packed: true,
      });
    }
    return newData;
  };

  const packingTiles = (array) => {
    let newData = [];
    let tiles = [];
    let definedTypes = ["tiles", "bio_card", "offers_card"];
    for (let i = 0; i < array.length; i++) {
      if (definedTypes.findIndex((item) => item === array[i]?.key) !== -1) {
        tiles.push(array[i]);
      } else {
        if (tiles.length !== 0) {
          newData.push({ key: "tileslist", tiles: [...tiles], packed: true });
          tiles = [];
        }
        newData.push({ ...array[i] });
      }
    }
    if (tiles.length !== 0) {
      newData.push({ key: "tileslist", tiles: [...tiles], packed: true });
    }
    return newData;
  };

  const addUncontrolledKey = (array) => {
    let definedTypes = ["leadership_messages_list", "mgm_news"];
    for (let i = 0; i < array.length; i++) {
      if (array[i]?.key === "api_widget") {
        if (definedTypes.findIndex((item) => item === array[i]?.type) !== -1) {
          array[i].itsUncontrolled = true;
        }
      } else {
        if (definedTypes.findIndex((item) => item === array[i]?.key) !== -1) {
          array[i].itsUncontrolled = true;
        }
      }
    }
    return array;
  };

  if (livePreviewInfo.onLivePreview) {
    useLivePreview(props.data);
  }

  const packedData = useMemo(
    () =>
      addUncontrolledKey(
        packingTiles(packingHalfWidth(packingHorizontalBlock(props.data)))
      ),
    [props.data]
  );

  return (
    <>
      <div className={styles.layoutFullWidthHolder}>
        <div className={styles.layoutFullWidth}>
          {packedData.map((item, index) => (
            <React.Fragment key={index}>
              {!(breadcrumbForceEnable && !item.itsUncontrolled) && (
                <>
                  {item.packed ? (
                    <>
                      {item.halfWidth && (
                        <section key={index} className={styles.fiftyFifty}>
                          <GetDynamicComponent
                            comp={item.firstHalf.key}
                            type={item.firstHalf.type}
                            data={item.firstHalf.data}
                            error={item.firstHalf.error}
                          />
                          {item.secondHalf !== null && (
                            <GetDynamicComponent
                              comp={item.secondHalf?.key}
                              type={item.secondHalf?.type}
                              data={item.secondHalf?.data}
                              error={item.secondHalf?.error}
                            />
                          )}
                        </section>
                      )}
                      {item.key === "tileslist" && (
                        <section key={index} className={styles.tiles}>
                          {item.tiles.map((tile, index) => (
                            <GetDynamicComponent
                              key={index}
                              comp={tile.key}
                              type={tile.type}
                              data={tile.data}
                              errror={tile.error}
                            />
                          ))}
                        </section>
                      )}
                      {item.key === "horizontalgroup" && (
                        <section key={index} className={styles.horiGroup}>
                          <HorizontalGroup groupList={item.horiGroup} />
                        </section>
                      )}
                    </>
                  ) : (
                    <section key={index}>
                      <GetDynamicComponent
                        comp={item.key}
                        type={item.type}
                        data={item.data}
                        error={item.error}
                      />
                    </section>
                  )}
                </>
              )}
            </React.Fragment>
          ))}
        </div>
      </div>
    </>
  );
};

export default WithFetchData(LayoutAllInOne);
