import { useRef, useState } from "react";
import styles from "./DragNdrop.module.css";
import fileUpload from "../../assets/images/fileUpload.svg";
import greenTick from "../../assets/images/greenTick.svg";
import uploadLoader from "../../assets/images/uploadLoader.gif";
import { Alert } from "@mgmresorts/mgm-ui";

const DragNdrop = ({ onFilesSelected, width, height, isWarning }) => {
  const [files, setFiles] = useState([]);
  const errorDisplayWrapperRef = useRef(null);
  const uploadedFileRef = useRef(null);
  const [warningMessage, setWarningMessage] = useState({
    title: "",
    isWarning: false,
    message: "",
  });
  const [loaderEnable, setLoaderEnable] = useState(false);

  const uploadFile = (file) => {
    if (file && file.length > 0) {
      if (file[0].type == "application/pdf") {
        const fileSize = file[0].size / 1024 / 1024;
        const maxAllowedSize = 25;
        if (fileSize > maxAllowedSize) {
          setWarningMessage({
            isWarning: true,
            title: "Maximum file size is exceeded",
            message: "Your PDF file is larger than 25MB.",
          });
          setTimeout(() => {
            errorDisplayWrapperRef.current.focus();
          }, 100);
          setLoaderEnable(false);
        } else {
          const newFiles = Array.from(file);
          setFiles((prevFiles) => [...prevFiles, ...newFiles]);
          onFilesSelected(newFiles[0]);
          setTimeout(() => {
            uploadedFileRef.current.focus();
            setLoaderEnable(false);
          }, 500);
        }
      } else {
        setWarningMessage({
          isWarning: true,
          title: "File type not supported",
          message: "Upload a compatible file type. Only supported .pdf files.",
        });
        setTimeout(() => {
          errorDisplayWrapperRef.current.focus();
        }, 100);
        isWarning(true);
      }
    }
  };

  const handleFileChange = (event) => {
    setLoaderEnable(true);
    setWarningMessage({ title: "", isWarning: false, message: "" });
    const selectedFiles = event.target.files;
    uploadFile(selectedFiles);
    event.target.value = "";
  };

  const handleDrop = (event) => {
    setLoaderEnable(true);
    setWarningMessage({ title: "", isWarning: false, warningMessage: "" });
    event.preventDefault();
    const droppedFiles = event.dataTransfer.files;
    uploadFile(droppedFiles);
    event.target.value = "";
  };

  const handleRemoveFile = (index) => {
    setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
    isWarning(true);
  };

  return (
    <section
      className={styles.dragDrop}
      style={{ width: width, height: height }}
    >
      <div
        className={`${styles.documentUploader} ${
          files.length == 0 ? styles.uploadBox : styles.uploadBoxDisabled
        }`}
        onDrop={handleDrop}
        onDragOver={(event) => event.preventDefault()}
      >
        <>
          <div className={styles.uploadInfo}>
            <div>
              <div>
                <img src={fileUpload} className={styles.fileUploadImage} />
              </div>
              <p>Drag and drop your files here</p>
              <p className={styles.option}>or</p>
            </div>
          </div>
          <input
            type="file"
            id="browse"
            onChange={handleFileChange}
            // accept=".pdf,.docx,.pptx,.txt,.xlsx"
            accept=".pdf"
            disabled={files.length >= 1}
            multiple
          />
          <label htmlFor="browse" className={styles.browseBtn}>
            Browse files
          </label>
        </>
      </div>
      {warningMessage.isWarning && (
        <div className="mt-3 mb-1" ref={errorDisplayWrapperRef} tabIndex={-1}>
          <Alert
            Display
            Name
            message={warningMessage.message}
            onClose={() => {
              setWarningMessage(false);
            }}
            open
            title={warningMessage.title}
            variant="error"
          />
        </div>
      )}
      <div>
        {files.length > 0 && (
          <div className={styles.fileList} ref={uploadedFileRef} tabIndex={-1}>
            <div className={styles.container}>
              {files.map((file, index) => (
                <div
                  className={`${styles.fileItem} ${
                    loaderEnable && styles.fileItemLoader
                  }`}
                  key={index}
                >
                  <div className={styles.fileInfo}>
                    <p>{file.name}</p>
                  </div>
                  <div className={styles.fileActions}>
                    <button onClick={() => handleRemoveFile(index)}>X</button>
                  </div>
                </div>
              ))}
            </div>
            <div className={styles.successWrapper}>
              {loaderEnable ? (
                <img src={uploadLoader} alt="loader" width="24px" />
              ) : (
                <img src={greenTick} alt="green-tick-circle" />
              )}
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

export default DragNdrop;
