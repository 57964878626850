//import { useEffect } from "react";
import styles from "./SecondaryNavigation.module.css";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { navigationActions } from "../../data/navigation-slice";
import { useEffect } from "react";

const SecondaryNavigation = () => {
  //const gqlData = useSelector((state) => state.gqlData);
  //const payRateGroup = gqlData?.profile?.payRateGroup;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const selectedSecondaryList = useSelector(
    (state) => state.navigation.selectedSecondaryList
  );
  const selectedNavigation = useSelector(
    (state) => state.navigation.selectedNavigation
  );
  
  useEffect(() => {
    dispatch(navigationActions.setSelectedSecondaryList());
  }, [selectedNavigation[1]]);

  const navigationChange = (e) => {
    let navPath = selectedSecondaryList.find(
      (nav) => nav.label === e.target.value
    ).absoluteLink;
    navigate(navPath);
  };

  let defaultValue = selectedNavigation[1].label;

  return (
    <section className={styles.secondaryNavigation}>
      <select onChange={navigationChange} value={defaultValue}>
        {selectedSecondaryList.map((nav, index) => (
          /* !(
            (nav.denySalaryWorker == "true" &&
              payRateGroup == "Salary") ||
            (nav.denyHourlyWorker == "true" &&
              payRateGroup == "Hourly")
          ) &&*/
          <option key={index}>{nav.label}</option>)
        )}
      </select>
    </section>
  );
};

export default SecondaryNavigation;
