
import Loader from "./Loader";
import useFetchData from "../../hooks/useFetchData";
import ErrorHanding from "../../pages/ErrorHandling/ErrorHanding";
import "../../styles/profile.css";
import styles from "./Profile.module.css";
import {  useSelector } from "react-redux";
import Favourites from "./Favourites";
import Profile from "./Profile";
import APIDowntimeError from "../../pages/ErrorHandling/APIDowntimeError";


export default function ProfileWrapper() {
  
  const isWorkDaydown = useSelector(
    (state) => state.auth.workdayApiState
    );
  
  
  console.log('isWorkDaydown >>>#',isWorkDaydown);
  
  

  const { loading, error, data } = useFetchData("/profile", "page_profile");

  if(isWorkDaydown == true)
  {
    return <div className={`${styles.aucontainer} col-12`}><APIDowntimeError/><Favourites data={data} /></div>
  }
  
  

  if (loading) return <Loader />;
  if (error) return <ErrorHanding error={error} />;



  return (
    <>
    {(isWorkDaydown == false?<Profile></Profile>:<></>)}
    </>
    
  );
}
