import { Tab } from "@mgmresorts/mgm-ui";
import { useEffect, useState } from "react";
import Loader from "../../components/global/Loader";
import useFetchData from "../../hooks/useFetchData";
import ErrorHanding from "../../pages/ErrorHandling/ErrorHanding";
import "../../styles/profile.css";
import styles from "./Profile.module.css";

import { useLocation } from "react-router-dom";
import Favourites from "./Favourites";
import PersonalInfo from "./PersonalInfo";
import ProfileUserInfo from "./ProfileUserInfo";

import {GET_PROFILE_DETAILS} from "../../features/api-wrapper/apiQueries";
import {apiMiddlewareClient} from "../../features/api-wrapper/apiMiddlewareWrapper";
import { useQuery } from "@apollo/client";
import { useDispatch } from "react-redux";
import { updateGqlData } from "../../data/gqlDataReducer";



export default function Profile() {
  const dispatch = useDispatch();
  const location = useLocation();
  const isFav = location.state;
  let [selectedTab, setSelectedTab] = useState(0);
  
  
  useEffect(() => {
    if (isFav && isFav.isFavouriteTab) {
      setSelectedTab(1);
    }
  }, [isFav]);

  const handleTab = (index) => {
    setSelectedTab(index);
  };

  const { loading, error, data } = useFetchData("/profile", "page_profile");

 
  
  const { loading:loading2, error:error2, data:data2 } = useQuery(GET_PROFILE_DETAILS, {
    client: apiMiddlewareClient,
  });
  console.log('gql data2 >>>>#',data2);
  dispatch(updateGqlData(data2));

  if (loading || loading2) return <Loader />;
  if (error || error2) return <ErrorHanding error={error} />;



  return (
    <>
      {data2?(<div className={`${styles.aucontainer} col-12`}>
        <ProfileUserInfo />
        <div>
          <div className={styles.mobilenav}>
            <select
              onChange={(event) => handleTab(parseInt(event.target.value))}
            >
              <option value="0" selected={selectedTab == 0}>
                {data[2].data.dictionaryLables?.personal_info_tab}
              </option>

              <option value="1" selected={selectedTab == 1}>
                {data[2].data.dictionaryLables?.favorites_tab}
              </option>
            </select>
          </div>
          <div className={styles.tabHolder}>
            <Tab
              label={data[2].data.dictionaryLables?.personal_info_tab}
              className={`${styles.tabItem} ${
                selectedTab === 0 ? styles.active : ""
              }`}
              onClick={() => handleTab(0)}
            />{" "}
            <Tab
              label={data[2].data.dictionaryLables?.favorites_tab}
              className={`${styles.tabItem} ${
                selectedTab === 1 ? styles.active : ""
              }`}
              onClick={() => handleTab(1)}
            />
            <Tab />
          </div>
          <>
            {selectedTab == 0 && <PersonalInfo />}
            {selectedTab == 1 && <Favourites data={data} />}
          </>
        </div>
      </div>):<></>}
    </>
  );
}
