import styles from "./CarouselBlock.module.css";
import { useEffect, useState } from "react";
import { Carousel } from "react-responsive-carousel";
import { CardContainer } from "@mgmresorts/mgm-ui";
// import { useState } from "react";
import { useSelector } from "react-redux";
import CarouselFiftyFiftyPhoto from "./CarouselFiftyFiftyPhoto";
import TrendingTopicsGroup from "./TrendingTopicsGroup";
import arrowLeft from "../../assets/images/chevron-left.svg";

const CarouselBlock = (props) => {
  const [isToggle, setIsToggle] = useState(false);
  const oneColumnEnabled=false;
  const globalProperties = useSelector(
    (state) => state.globalNotification?.globalProperties
  );

  // once width parameter comes through
  const carouselTT = props.data?.enableCta;
  // const carouselTT = false;

  const [deviceSize, changeDeviceSize] = useState(window.innerWidth);
  const [isMobile, setIsMobile] = useState(deviceSize);
  const [width, setWidth] = useState(carouselTT && !isMobile ? 'fullWidth' : 'partWidth');

  useEffect(() => {
    if (carouselTT && !isMobile) {
      setWidth("partWidth");
    } else {
      setWidth("fullWidth");
    }
  });
  useEffect(() => {
    let indicatorArea = document.querySelector(".control-dots");
    let leftArrow = document.querySelector('.carourselTTArrowLeft');
    let rightArrow = document.querySelector('.carourselTTArrowRight');
    if (document.querySelector('.carourselTTArrowLeft') && document.querySelector(".control-dots") && document.querySelector('.carourselTTArrowRight')) {
              indicatorArea.insertBefore(leftArrow, indicatorArea.firstChild);
        indicatorArea.appendChild(rightArrow);
    }
  },[isToggle]);

  useEffect(() => { 
    const resizeW = () => changeDeviceSize(window.innerWidth);
    window.addEventListener("resize", resizeW); // Update the width on resize
    // if (deviceSize < 1224) {
    if (deviceSize < 1400 && carouselTT) {
      setIsMobile(true);
    } else if ((deviceSize >= 1400 && carouselTT)) {
      setIsMobile(false);
    } else if (deviceSize < 1200 && !carouselTT) {
      setIsMobile(true);
    } else if (deviceSize >= 1200 && !carouselTT) {
      setIsMobile(false);
    }
    return () => window.removeEventListener("resize", resizeW);

  },[deviceSize])
  

  return (
    <div className={`${styles.carouselBlock} ${isToggle && styles.carouselBlockDisplayBlock}`}>
      <CardContainer
        className={props.data?.text_carousel?styles.cardTextContainer:styles.cardContainer}
        style={{ borderRadius: "8px", margin: "0", padding: "0" }}
      >
        <div className={`${styles.cardContainerInner} ${isToggle && !isMobile && carouselTT ? styles.fullWidth : styles[width]}`}>
          {!isToggle ? (
            <Carousel
              className={carouselTT ? styles.carouselTT : styles.carouselNoTT}
              showArrows={true}
              autoPlay={true}
              infiniteLoop={true}
              showStatus={false}
              showThumbs={false}
              interval={10000}
              // dynamicHeight={true}
              // axis={"vertical"}

              renderArrowPrev={(clickHandler, hasPrev) => {
                return (
                  <div
                    className={`${
                      hasPrev ? "absolute" : "hidden"
                    } ${ !carouselTT ? "as" : ""} carourselTTArrowLeft`}
                    onClick={clickHandler}
                  >
                    <img src={arrowLeft} />
                  </div>
                );
              }}
              renderArrowNext={(clickHandler, hasNext) => {
                return (
                  <div
                    className={`${
                      hasNext ? "absolute" : "hidden"
                    } ${ !carouselTT ? "as" : ""} carourselTTArrowRight`}
                    onClick={clickHandler}
                  >
                    <img src={arrowLeft} />
                  </div>
                );
              }}
            >
              {props.data.children.map((item, index) => (
                <CarouselFiftyFiftyPhoto
                  carouselTT={carouselTT}
                  isMobile={isMobile}
                  oneColumnEnabled={oneColumnEnabled}
                  enableText={props.data?.text_carousel}
                  key={index}
                  data={item}
                  setToggle={() => {
                    setIsToggle(true);
                  }}
                />
              ))}
            </Carousel>
          ) : (
            <div className={styles.hideWrapper}>
              <div className="col-12">
                <div
                  className="row row-h pb-1"
                  style={{ alignItems: "center", textAlign: "right" }}
                >
                  <h3 className={`col-8 text-left ${styles.title}`}>
                    {props.data.children[0].heading}
                  </h3>
                  <div className="col-4">
                    <div className={`${styles.showHolder} text-right`}>
                      <button onClick={() => setIsToggle(!isToggle)}>
                        <span className={styles.textUnderline}>
                          {globalProperties?.show}
                        </span>
                        &nbsp;<span className={styles.eyeHolder}></span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}{" "}
        </div>
        {carouselTT && !isToggle && !isMobile &&
          <TrendingTopicsGroup data={props?.data?.trendingTopicsGroup} isMobile={isMobile} setToggle={() => {
              setIsToggle(true);
            }}/>
        }
      </CardContainer>
      <div>
        {carouselTT && !isToggle && isMobile &&
          <TrendingTopicsGroup data={props?.data?.trendingTopicsGroup} isMobile={isMobile} setToggle={() => {
              setIsToggle(true);
            }}/>
        }
      </div>
    </div>
  );
};

export default CarouselBlock;
