import { useEffect, useRef } from "react";
import { createPortal } from "react-dom";
import useKeyDown from "../../hooks/useKeyDown";
import UploadEDR from "./UploadEDR";
import style from "./UploadEDRModal.module.css";

const Backdrop = (props) => {
  return <div className={style.backdrop} onClick={props.onClose} />;
};

export const UploadEDRDialogue = (props) => {
  const modalRef = useRef();

  useEffect(() => {
    modalRef.current.focus();
  }, []);

  const handleTabKey = (event) => {
    const focusableElements = modalRef?.current.querySelectorAll(
      "input, select, label[data-testid='browseBtn'], button"
    );
    const firstElement = focusableElements && focusableElements[0];
    const lastElement =
      focusableElements && focusableElements[focusableElements.length - 1];
    // console.log('firstElement lastElement', firstElement, lastElement);
    if (!event.shiftKey) {
      if (focusableElements[focusableElements.length - 1] === event.target) {
        event.preventDefault();
        firstElement.focus();
      }
    }
    // redirect first shift + tab to last input
    else {
      if (focusableElements[0] === event.target) {
        event.preventDefault();
        lastElement.focus();
      }
    }
  };

  useKeyDown("Tab", handleTabKey);
  useKeyDown("Escape", props.onClose);

  return (
    <div
      className={`${style.modal} ${style.uploadEDRModal}`}
      role="dialog"
      aria-modal="true"
      aria-hidden={props.isOpen}
    >
      <div className={style.innerHolder} tabIndex={-1} ref={modalRef}>
        <div className={style.coreHolder}>
          <button
            className={style.cross}
            onClick={props.onClose}
            role="button"
            data-testid="modal-icon"
            aria-label="close"
          ></button>
          <div className={style.head}>Upload Menu</div>
        </div>
        <UploadEDR responseEDR={props.responseEDR} onClose={props.onClose} />
      </div>
    </div>
  );
};
const portalElement = document.getElementById("portal");

const UploadEDRModal = (props) => {
  return (
    <>
      {createPortal(<Backdrop onClose={props.onClose} />, portalElement)}
      {createPortal(
        <UploadEDRDialogue
          onClose={props.onClose}
          responseEDR={props.responseEDR}
          isOpen={props.isOpen}
        >
          {props.children}
        </UploadEDRDialogue>,
        portalElement
      )}
    </>
  );
};

export default UploadEDRModal;
