import { Link } from "react-router-dom";
import mgmnewsstyle from "./MgmNews.module.css";
import MgmNewsCard from "./MgmNewsCard";
import { useQuery } from "@apollo/client";
import { GET_MGM_NEWS } from "../../features/api-wrapper/apiQueries";
import { apiMiddlewareClient } from "../../features/api-wrapper/apiMiddlewareWrapper";
import moment from "moment";
import APIError from "../ErrorHandling/APIError";
import Loader from "../../components/global/Loader";
import { useSelector } from "react-redux";
//import useFetchData from "../../hooks/useFetchData";

const MgmNews = (props) => {
  const {
    dictionary,
    halfWidth,
    links_dictionary: linksDictionary,
  } = props.data || {};
  const gqlData = useSelector((state) => state.gqlData);
  const salaried = gqlData.profile && gqlData.profile.payRateGroup;
  const totalRecords = halfWidth ? 1 : 2;
  //const { data: csGlobalData } = useFetchData("", "globals");
  const csGlobalData = useSelector((state) => state.globalNotification);

  const viewAll = linksDictionary.find((item) => item.name == "view_all");
  const readMoreUrl = dictionary.find((item) => item.name == "news_tile_read_more_url");

  const { loading, error, data } = useQuery(GET_MGM_NEWS, {
    client: apiMiddlewareClient,
  });

  if (loading) return <Loader />;
  if (error)
    return (
      <div style={{ marginTop: "2rem" }}>
        <APIError error={error} />
      </div>
    );

  return (
    <div className={mgmnewsstyle.offerHolder}>
      <div
        className={`${mgmnewsstyle.offerHolderSub} ${mgmnewsstyle[salaried]}`}
      >
        <div className={mgmnewsstyle.toptextholder}>
          <div className={mgmnewsstyle.subTextHolder}>
            <div className={mgmnewsstyle.offerTitle}>{dictionary[0].value}</div>
            <div className={mgmnewsstyle.offerLink}>
              {viewAll && (
                <Link to={viewAll?.link?.href}>{viewAll?.link?.title}</Link>
              )}
            </div>
          </div>
        </div>

        <div className={mgmnewsstyle.allCardScrollingContainer}>
          <div className={mgmnewsstyle.allCardHolder}>
            {data.mgmNews &&
              data.mgmNews.length > 0 &&
              data.mgmNews.slice(0, totalRecords).map((news, index) => {
                const dateValue = moment
                  .utc(news.newsPublishedDate)
                  .format("MMM D, YYYY");
                return (
                  <MgmNewsCard
                    news={news}
                    key={index}
                    salaried={salaried}
                    image={news.images[0].imageURL}
                    header={dateValue}
                    cardtitle={news.newsTitle}
                    content={news.newsSummary}
                    selectedIndex={index}
                    csGlobalData={csGlobalData}
                    readMoreUrl={readMoreUrl}
                    {...news}
                  />
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MgmNews;
