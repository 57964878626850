import { useQuery } from "@apollo/client";
import {Icon } from "@mgmresorts/mgm-ui";
import moment from "moment";
import { Fragment, useEffect, useState } from "react";
import { createPortal } from "react-dom";
import { apiMiddlewareClient } from "../../features/api-wrapper/apiMiddlewareWrapper";
import { GET_BENEFITS } from "../../features/api-wrapper/apiQueries";
import useWindowDimensions from "../../hooks/useScreenDetails";
import APIError from "../../pages/ErrorHandling/APIError";
import Loader from "../global/Loader";
import style from "./ViewCurrentBenefitsModal.module.css";
import InnerHtml from "./InnerHtml";
import { useSelector } from "react-redux";

const Backdrop = (props) => {
  return <div className={style.backdrop} onClick={props.onClose} />;
};

export const ViewCurrentBenefitsDialogue = (props) => {
  const [dictionaryLabel, setDictionaryLabel] = useState();
  const gqlData = useSelector((state) => state.gqlData);
  const payRateGroup = gqlData?.profile?.payRateGroup;
  const [isMobile] = useWindowDimensions();
  const {
    error,
    loading,
    data: benefits,
  } = useQuery(GET_BENEFITS, {
    // variables: { effectiveDate: "2024-07-08-07:00" },
    variables: { effectiveDate: moment(new Date()).format("YYYY-MM-DD") },
    fetchPolicy: "no-cache",
    client: apiMiddlewareClient,
  });

  useEffect(() => {
    if (props.csData && props.csData[0]?.dictionary) {
      let dictionaryLables = {};
      const dictionary = props.csData[0].dictionary;
      if (Array.isArray(dictionary)) {
        for (let i = 0; i < dictionary.length; i++) {
          dictionaryLables[dictionary[i].name] =
            dictionary[i].value || dictionary[i].html_value;
        }
      }
      setDictionaryLabel(dictionaryLables);
    }
  }, [props]);

  if (error)
    return (
      <div className={style.modalError}>
        <div className={style.coreHolder}>
        <button className={style.closeButton} onClick={props.onClose}>
              <Icon name="symbol-x" size="small" variant="outlined"/>
            </button>
          {/* <div
            className={style.cross}
            onClick={props.onClose}
            role="button"
          ></div> */}
          <APIError error={error} />
        </div>

      </div>
    );

  return (
    <div>
      {benefits && benefits.getBenefits && (
        <div className={style.modal}>
          <div
            className={
              benefits &&
                benefits.getBenefits &&
                benefits.getBenefits.length > 0
                ? style.innerHolder
                : style.noBenefitInnerHolder
            }
          >
            <div className={style.coreHolder}>
              {/* <div className={style.crossParent}>
              <div
                className={style.cross}
                onClick={props.onClose}
                role="button"
              ></div>
              </div> */}
               <button className={style.closeButton} onClick={props.onClose}>
              <Icon name="symbol-x" size="small" variant="outlined"/>
            </button>
              <div className={style.head}>
                {benefits &&
                  benefits.getBenefits &&
                  benefits.getBenefits.length > 0
                  ? dictionaryLabel?.current_benefits_heading
                  : dictionaryLabel?.not_enrolled_heading_hourly}
              </div>
              {!isMobile ? (
                benefits &&
                  benefits.getBenefits &&
                  benefits.getBenefits.length > 0 ? (
                  <div className={style.scrollContainer}>
                    <div className={style.viewCurrentBenefitTable}>
                      <table>
                        <thead>
                          <tr>
                            <th style={{ width: "40%" }}>
                              {dictionaryLabel?.benefit_plan}
                            </th>
                            <th style={{ width: "20%" }}>
                              {dictionaryLabel?.coverage_begin_date}
                            </th>
                            <th style={{ width: "20%" }}>
                              {dictionaryLabel?.deduction_begin_date}
                            </th>
                            <th style={{ width: "30%" }}>
                              {dictionaryLabel?.coverage}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {benefits &&
                            benefits.getBenefits.map((bene, index) => {
                              return (
                                <tr key={index}>
                                  <td style={{ width: "40%" }}>
                                    {bene.benefitPlan}
                                  </td>
                                  <td style={{ width: "20%" }}>
                                    {moment
                                      .utc(bene.coverageBeginDate)
                                      .format("MM/DD/YYYY")}
                                  </td>
                                  <td style={{ width: "20%" }}>
                                    {moment
                                      .utc(bene.deductionBeginDate)
                                      .format("MM/DD/YYYY")}
                                  </td>
                                  <td style={{ width: "30%" }}>
                                    {bene.coverage}
                                  </td>
                                </tr>
                              );
                            })}
                          {benefits &&
                            benefits.getBenefits.length == 0 &&
                            !loading && (
                              <tr>
                                <th
                                  style={{ width: "100%" }}
                                  colSpan="4"
                                  rowSpan="4"
                                  align="center"
                                  className={style.loadBenefits}
                                >
                                  {dictionaryLabel?.no_benefits}
                                </th>
                              </tr>
                            )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                ) : (
                  <InnerHtml
                    description={
                      payRateGroup == "Hourly"
                        ? dictionaryLabel?.not_enrolled_message_hourly
                        : dictionaryLabel?.not_enrolled_message_salary
                    }
                    style={style.innerHtml}
                  ></InnerHtml>
                )
              ) : benefits &&
                benefits.getBenefits &&
                benefits.getBenefits.length > 0 ? (
                <div className={style.viewCurrentBenefitTableMob}>
                  <div className={style.benefitsTable}>
                    {benefits ? (
                      benefits.getBenefits.map((bene, index) => {
                        return (
                          <Fragment key={index}>
                            <div className={style.benefitsPlanHeader}>
                              <div className={style.benefitsHeader}>
                                {bene.benefitPlan}
                              </div>
                            </div>
                            <div className={style.benefitsRow}>
                              <div className={style.benefitLabel}>
                                {dictionaryLabel?.coverage_begin_date}
                              </div>
                              <div className={style.benefitValue}>
                                {moment
                                  .utc(bene.coverageBeginDate)
                                  .format("MM/DD/YYYY")}
                              </div>
                            </div>
                            <div className={style.benefitsRow}>
                              <div className={style.benefitLabel}>
                                {dictionaryLabel?.deduction_begin_date}
                              </div>
                              <div className={style.benefitValue}>
                                {moment
                                  .utc(bene.deductionBeginDate)
                                  .format("MM/DD/YYYY")}
                              </div>
                            </div>
                            <div className={style.benefitsRow}>
                              <div className={style.benefitLabel}>
                                {dictionaryLabel?.coverage}
                              </div>
                              <div className={style.benefitValue}>
                                {bene.coverage}
                              </div>
                            </div>
                          </Fragment>
                        );
                      })
                    ) : (
                      <div className={style.loadBenefits}>
                        <Loader />
                      </div>
                    )}
                    {benefits &&
                      benefits.getBenefits.length == 0 &&
                      !loading && (
                        <div className={style.loadBenefits}>
                          {dictionaryLabel?.no_benefits}
                        </div>
                      )}
                  </div>
                </div>
              ) : (
                <InnerHtml
                  description={
                    payRateGroup == "Hourly"
                      ? dictionaryLabel?.not_enrolled_message_hourly
                      : dictionaryLabel?.not_enrolled_message_salary
                  }
                  style={style.innerHtml}
                ></InnerHtml>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
const portalElement = document.getElementById("portal");

const ViewCurrentBenefitsModal = (props) => {
  return (
    <>
      {createPortal(<Backdrop onClose={props.onClose} />, portalElement)}
      {createPortal(
        <ViewCurrentBenefitsDialogue
          onClose={props.onClose}
          csData={props.csData}
        >
          {props.children}
        </ViewCurrentBenefitsDialogue>,
        portalElement
      )}
    </>
  );
};

export default ViewCurrentBenefitsModal;
