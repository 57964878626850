import { Icon } from "@mgmresorts/mgm-ui";
import styles from "./CarouselArrows.module.css";
import { useState } from "react";

const CarouselArrows = (props) => {
  const { title, onClick, totalRecords, itemsPerSlide = 1 } = props;
  const [indexValue, setIndexValue] = useState(0);

  return (
    <div>
      <a
        role="button"
        className={`${styles.carouselNavArrowP} ${
          indexValue == 0 && styles.disable
        }`}
        title={title?.prev}
        onClick={(e) => {
          e.preventDefault();
          onClick(e, "prev");
          setIndexValue(indexValue - 1);
        }}
        href=""
      >
        <Icon name="chevron-left" size="small" />
      </a>
      <a
        role="button"
        className={`${styles.carouselNavArrowN} ${
          indexValue == totalRecords.length - parseInt(itemsPerSlide) &&
          styles.disable
        }`}
        title={title?.next}
        onClick={(e) => {
          e.preventDefault();
          onClick(e, "next");
          setIndexValue(indexValue + 1);
        }}
        href=""
      >
        <Icon name="chevron-right" size="small" />
      </a>
    </div>
  );
};

export default CarouselArrows;
