import { useMutation } from "@apollo/client";
import { Button } from "@mgmresorts/mgm-ui";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setLangChange,
  setPreferredLanguage,
  setPreferredLanguageTemp,
} from "../../data/gqlDataReducer";
import { apiMiddlewareClient } from "../../features/api-wrapper/apiMiddlewareWrapper";
import { SET_PREFERRED_LANGUAGE } from "../../features/api-wrapper/apiQueries";
import WithFetchGlobal from "../../hoc/WithFetchGlobal";
import ChooseCta from "../../hooks/ChooseCta";
import APIError from "../../pages/ErrorHandling/APIError";
import InnerHtml from "../dynamic/InnerHtml";
import styles from "./Footer.module.css";
import LanguageSelection from "./LanguageSelection";
import Loader from "./Loader";
import Modal from "./ui/Modal";

const Footer = (props) => {
  const dispatch = useDispatch();
  let data = props.data;
  console.log("data >>>", data);
  let [showPopup, setShowPopup] = useState(false);
  let [modalContent, setModalContent] = useState(null);
  const gqlData = useSelector((state) => state.gqlData);
  const preferredLanguage = useSelector(
    (state) => state.gqlData?.profile?.preferredLanguage
  );
  const preferredLanguageTemp = useSelector(
    (state) => state.gqlData?.profile?.preferredLanguageTemp
  );
  const hasLangChanged = useSelector((state) => state.gqlData?.profile?.hasLangChanged);
  console.log("gqldata footer >>##",gqlData)
  console.log("preferredLanguage >>##", preferredLanguage);
  console.log("preferredLanguageTemp >>##", preferredLanguageTemp);
  console.log("hasLangChanged >>##", hasLangChanged);
  const globalNotification = useSelector((state) => state.globalNotification?.translationDisclaimer);
  // const globalProperties = useSelector(
  //   (state) => state.globalNotification?.globalProperties
  // );
  const [isCloseBtnShown, setIsCloseBtnShown] = useState(true);
  const [isModalBtnShown, setIsModalBtnShown] = useState(false);
  const [isTDEditMode, setIsTDEditMode] = useState(false);
  //const [contactUs,setContactUs] = useState(false);
  
  /*useEffect(()=>{
    console.log('contactUs ?????????????????????????',contactUs);
    if(contactUs)
    {
      setShowPopup(false);
    }
  },[contactUs])*/
  useEffect(() => {
    if (hasLangChanged) {
      if (preferredLanguageTemp != "en-us") {
        setIsTDEditMode(true);
        openModal([
          globalNotification?.heading,
          globalNotification?.description,
        ]);
        setIsCloseBtnShown(false);
        setIsModalBtnShown(true);
      }
      else {
        dispatch(setPreferredLanguage(preferredLanguageTemp));
      }
      dispatch(setLangChange(false));
    }
  }, [hasLangChanged, preferredLanguageTemp]);

  

  const openModal = (args) => {
    console.log("args, ", args);
    setModalContent(args);
    setShowPopup(true);
  };
  const onPopupClose = () => {
    setShowPopup(false);
  };

  let languageSection = data[0]?.data?.languageSection;
  let linkSectionOne = data[0]?.data?.linkSectionOne;
  let linkSectionTwo = data[0]?.data?.linkSectionTwo;
  let copyrightSection = data[0]?.data?.copyrightSection;
  

  const popularLinks = [
    linkSectionOne?.links?.filter(
      (item, index) => index < Math.ceil(linkSectionOne?.links?.length / 2)
    ),
    linkSectionOne.links.filter(
      (item, index) => index >= Math.ceil(linkSectionOne?.links?.length / 2)
    ),
  ];
  const [mutateFunction, { loading, error }] = useMutation(
    SET_PREFERRED_LANGUAGE,
    {
      client: apiMiddlewareClient,
      fetchPolicy: "no-cache",
    }
  );
  const updateLang = (lang)=>{
    mutateFunction({
      variables: { language: lang },
    });
  }
  
  useEffect(()=>{
    console.log('preferredLanguageTemp @@@@@@@@@@',preferredLanguageTemp);
    if(preferredLanguageTemp && preferredLanguageTemp =="en-us")
    {
      updateLang(preferredLanguageTemp);
    }
  },[preferredLanguageTemp])

  if (loading) return <Loader styling="inline" />;
  if (error) return <APIError error={error} />;
  return (
    <footer>
      <div className={`row ${styles.footer}`}>
        {languageSection && (
          <div className={`col-xs-12 col-sm-2 ${styles.chooseLang}`}>
            <p className={styles.para}>{languageSection.heading}</p>
            <LanguageSelection
              languageSection={languageSection}
              source="footer"
            />
          </div>
        )}
        {linkSectionOne && (
          <div className={`col-xs-12 col-sm-4 ${styles.popularResources}`}>
            <p className={styles.para}>{linkSectionOne.heading}</p>
            <div className={`row ${styles.popularFlex}`}>
              {popularLinks.map((links, index) => (
                <div className="col" key={index}>
                  <ul key={index}>
                    {links.map((item, subIndex) => (
                      <li key={subIndex}>
                        <ChooseCta
                          key={subIndex}
                          data={item?.cta}
                          inverted="inverted"
                          type="text_link"
                          style={styles.textLink}
                          args={[
                            item?.cta?.modalContent
                              ? item?.cta?.modalContent?.heading
                              : "",
                            item?.cta?.modalContent
                              ? item?.cta?.modalContent?.content
                              : "",
                          ]}
                          openModal={openModal}
                          size="small"
                        />
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
          </div>
        )}
        {linkSectionTwo && (
          <div className={`col-xs-12 col-sm-3 ${styles.lookingAssist}`}>
            <p className={styles.para}>{linkSectionTwo.heading}</p>
            <ul>
              {linkSectionTwo?.links?.map((item, index) => (
                <li key={index}>
                  <ChooseCta
                    data={item?.cta}
                    inverted="inverted"
                    type="text_link"
                    style={styles.textLink}
                    args={[
                      item?.cta?.modalContent
                        ? item?.cta?.modalContent?.heading
                        : "",
                      item?.cta?.modalContent
                        ? item?.cta?.modalContent?.content
                        : "",
                    ]}
                    openModal={openModal}
                    size="small"
                  />
                </li>
              ))}
            </ul>
          </div>
        )}
        {copyrightSection && (
          <div className={`col-xs-12 col-sm-3 ${styles.copyRight}`}>
            <a
              href={copyrightSection?.logo?.link}
              target="_blank"
              rel="noreferrer"
            >
              <img
                className={styles.copyRightImg}
                alt={copyrightSection?.logo?.altText}
                src={copyrightSection?.logo?.url}
              />
            </a>
            <InnerHtml 
              description={copyrightSection?.copyrightText}
              style={styles.copyPara}
            />
            <ul>
              {copyrightSection?.links?.map((item, index) => (
                <li key={index}>
                  <ChooseCta
                    data={item?.cta}
                    inverted="inverted"
                    type="text_link"
                    style={styles.textLink}
                    args={[
                      item?.cta?.modalContent
                        ? item?.cta?.modalContent?.heading
                        : "",
                      item?.cta?.modalContent
                        ? item?.cta?.modalContent?.content
                        : "",
                    ]}
                    openModal={openModal}
                    size="small"
                  />
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>

      {showPopup && (
        <Modal className={styles.modalContents}
          showCloseBtn={isCloseBtnShown}
          heading={modalContent != null ? modalContent[0] : ""}
          onClose={() => {
            onPopupClose();

            /* updated the code based on backdrop click.
               So backdrop can be clicked based on TDEditMode as true or false
             */
            if (isTDEditMode) {
              console.log(setIsTDEditMode);

              //it is bydefault cancelled
              dispatch(setPreferredLanguageTemp(preferredLanguageTemp));
              
              //reset
              setIsCloseBtnShown(true);
              setIsModalBtnShown(false);
            }
          }}
        >
          <InnerHtml  trackAnchorClick = {()=>{setShowPopup(false)}}
            description={modalContent != null ? modalContent[1] : ""}
            style={styles.modalBody}
          />
          <div
            style={{
              display: "flex",
              marginTop: "30px",
              justifyContent: "right",
              marginBottom: "16px",
            }}
          >
            {isModalBtnShown && (
              <>
                <Button
                  onClick={() => {
                    // dispatch(setPreferredLanguage("en-us"));
                    dispatch(setPreferredLanguageTemp(preferredLanguageTemp));
                    onPopupClose();
                    
                    setIsCloseBtnShown(true);
                    setIsModalBtnShown(false);
                  }}
                  style={{ marginRight: "10px" }}
                  variant="primary"
                >
                  {globalNotification?.cancel_button_label}
                </Button>
                <Button
                  variant="primary"
                  onClick={() => {
                    dispatch(setPreferredLanguage(preferredLanguageTemp));
                    onPopupClose();
                    updateLang(preferredLanguageTemp)
                    setIsCloseBtnShown(true);
                    setIsModalBtnShown(false);
                  }}
                >
                  {globalNotification?.ok_button_label}
                </Button>
              </>
            )}
          </div>
        </Modal>
      )}
    </footer>
  );
};

export default WithFetchGlobal(Footer);

