import styles from "./HeroText.module.css";
import useWindowDimensions from "../../hooks/useScreenDetails";
import ChooseCta from "../../hooks/ChooseCta";
import InnerHtml from "../dynamic/InnerHtml";
import { useEffect, useState } from "react";
import Modal from "../global/ui/Modal";
import { Snackbar } from "@mgmresorts/mgm-ui";
import { useLocation } from "react-router-dom";
import use401K from "../dynamic/use401K";

const HeroText = (props) => {
  //console.log("HERO TEXT> ", props.data);
  //const ctaType = props.data?.ctaType || "primary";
  const [isMobile] = useWindowDimensions();
  const [savedModal, setSavedModal] = useState(false);
  const [modalData, setModalData] = useState({});
  const [plainModalContent, setPlainModalContent] = useState();
  const [isSnackbarEnabled, setIsSnackbarEnabled] = useState(false);
  const [isSnackbarAvailable, setIsSnackbarAvailable] = useState(false);
  const [sbShowForAnimate, setSbShowForAnimate] = useState(false);

  let [showPopup, setShowPopup] = useState(false);

  let [selectedModal, setSelectedModal] = useState();

  const location = useLocation();
  const cLocation = location.pathname.split("/").pop();

  const finishButton = true;
  const handleModalOpen = (childData) => {
    setSavedModal(childData);
  };
  const handleModalData = (childData) => {
    console.log("handleModalData >>>>>>>>>>> ", childData);
    let content = childData?.nameValue?.filter(
      (item) => item.name === "confirm_modal_content"
    )[0]?.value
      ? childData?.nameValue?.filter(
          (item) => item.name == "confirm_modal_content"
        )[0]?.value
      : childData?.nameValue?.filter(
          (item) => item.name == "confirm_modal_content"
        )[0]?.html_value;
    let plainContent = content.replace(/<[^>]*>/g, "");
    setIsSnackbarEnabled(plainContent.length < 120);
    setIsSnackbarAvailable(plainContent.length < 120);
    setPlainModalContent(plainContent);
    setModalData(childData);
  };

  console.log("isSavedModal", savedModal, modalData);
  const openModal = () => {
    let modal = props.data?.cta?.modalContent;
    if (modal != null && modal != undefined) {
      setSelectedModal({
        heading: modal.heading,
        body: modal.content,
      });
    } else {
      setSelectedModal({ heading: "", body: "" });
    }
    setShowPopup(true);
  };

  const onPopupClose = () => {
    setShowPopup(false);
  };

  useEffect(() => {
    //this is to add the animation class
    if (isSnackbarAvailable) {
      setSbShowForAnimate(true); //just show - no animation
      const timer = setTimeout(() => {
        setSbShowForAnimate(false); //start animation to hide from opacity 1 to 0
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [isSnackbarAvailable]);

  useEffect(() => {
    if (!sbShowForAnimate) {
      const timer = setTimeout(() => {
        setIsSnackbarAvailable(false);
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [sbShowForAnimate]);

  // for 401K - enrollment flag
  const ENROLLED_TEXT = "401_k_enrolled";
  const NOT_ENROLLED_TEXT = "401_k_not_enrolled";
  const csEnrollmentFlag = props.data?.custom_flags;

  //undefined, enrolled, not_enrolled
  let isUserEnrolled = undefined;
  let apiData;
  //if (cLocation === "401-k-savings-plan") {
    apiData = use401K();

    isUserEnrolled = apiData?.data?.get401KDetails?.isUserEnrolled;
  //}

  return (
    <>
      {(cLocation != "401-k-savings-plan" ||
        (cLocation === "401-k-savings-plan" &&
          isUserEnrolled &&
          csEnrollmentFlag == ENROLLED_TEXT) ||
        (cLocation === "401-k-savings-plan" &&
          isUserEnrolled === false &&
          csEnrollmentFlag == NOT_ENROLLED_TEXT)) && (
        <section className={`${styles.heroText}`}>
          {/* <div className={styles.titleBar}> */}
            <div className={`${styles.titleSection} `}>
              <h2 data-cslp={props.data?.$?.heading}>{props.data.heading}</h2>
              {props.data?.description && (
                <InnerHtml
                  description={props.data?.description}
                  style={styles.innerHtml}
                  hash={props.data?.$?.description}
                />
              )}
            </div>
            {props.data?.ctaEnabled && (
              <div className={styles.buttonHolder}>
                <ChooseCta
                  data={props.data?.cta}
                  type="primary"
                  size={isMobile ? "small" : "large"}
                  fullWidth={true}
                  handleModalOpen={handleModalOpen}
                  handleModalData={handleModalData}
                  openModal={openModal}
                />
              </div>
            )}
          {/* </div> */}

          {showPopup && (
            <Modal onClose={onPopupClose} heading={selectedModal.heading}>
              <InnerHtml description={selectedModal.body} />
            </Modal>
          )}
          {!isSnackbarEnabled ? (
            <>
              {savedModal && (
                <Modal
                  onClose={() => setSavedModal(false)}
                  heading={
                    modalData?.nameValue.filter(
                      (item) => item.name == "confirm_modal_heading"
                    )[0]?.value
                  }
                  finishButton={finishButton}
                  finishLabel={
                    modalData?.nameValue.filter(
                      (item) => item.name == "confirm_modal_finish_button_label"
                    )[0]?.value
                      ? modalData.nameValue.filter(
                          (item) =>
                            item.name == "confirm_modal_finish_button_label"
                        )[0].value
                      : modalData.nameValue.filter(
                          (item) =>
                            item.name == "confirm_modal_finish_button_label"
                        )[0].html_value
                  }
                >
                  <InnerHtml
                    description={
                      modalData?.nameValue.filter(
                        (item) => item.name == "confirm_modal_content"
                      )[0]?.value
                        ? modalData.nameValue.filter(
                            (item) => item.name == "confirm_modal_content"
                          )[0].value
                        : modalData.nameValue.filter(
                            (item) => item.name == "confirm_modal_content"
                          )[0].html_value
                    }
                  />
                </Modal>
              )}
            </>
          ) : (
            <Snackbar
              // className={`snackbar-box-profile `}
              className={`${styles.snackbar} ${
                sbShowForAnimate ? styles.show : styles.hide
              } `}
              open={isSnackbarAvailable}
              message={plainModalContent}
              variant="two-line"
            />
          )}
        </section>
      )}
    </>
  );
};

export default HeroText;
