import { TextLink } from "@mgmresorts/mgm-ui";
import { useEffect, useState } from "react";
import Loader from "../../components/global/Loader";
import useFetchData from "../../hooks/useFetchData";
import ErrorHanding from "../../pages/ErrorHandling/ErrorHanding";
import "../../styles/profile.css";
import styles from "./ProfileUserInfo.module.css";

import { useSelector } from "react-redux";

const ProfileUserInfo = () => {
  const gqlData = useSelector((state) => state.gqlData);
  const [visible, setVisible] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [snackbar, setSnackbar] = useState(false);
  console.log('gqldata 3>>>#',gqlData);
  let phoneNum =
    gqlData.personalInformation && gqlData.personalInformation.homePhone
      ? gqlData.personalInformation.homePhone.replace("+1", "")
      : "";

  let r = /(\D+)/g,
    npa = "",
    nxx = "",
    last4 = "";
  phoneNum = phoneNum ? phoneNum.replace(r, "") : "";
  npa = phoneNum ? phoneNum.substr(0, 3) : "";
  nxx = phoneNum ? phoneNum.substr(3, 3) : "";
  last4 = phoneNum ? phoneNum.substr(6, 4) : "";
  phoneNum = phoneNum ? "(" + npa + ")" + " " + nxx + "-" + last4 : "";

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 1280);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  });
 
  useEffect(() => {
    const timer = setTimeout(() => {
      setSnackbar(false);
    }, 3000);

    return () => clearTimeout(timer);
  }, [snackbar]);

  const { loading, error, data } = useFetchData("/profile", "page_profile");
  if (loading) return <Loader />;
  if (error) return <ErrorHanding error={error} />;

  const Details = (data) => {
    return (
      <div className={styles.reports}>
        <div className={styles.reportsInner}>
          <div className="reportsto-text">
            {data[1].data.dictionaryLables?.reports_to}
          </div>
          <div className="supervisorname-text">
            {gqlData.supervisorDetails ? gqlData.supervisorDetails?.name : ""}
          </div>
          <div className="supervisordetails-txt d-none">
            {data[1].data.dictionaryLables?.employee_id}{" "}
            {gqlData.supervisorDetails
              ? gqlData.supervisorDetails.employeeId
              : ""}
          </div>
          <div className="supervisordetails-txt">
            {gqlData.supervisorDetails
              ? gqlData.supervisorDetails.businessTitle.trim()
              :""}
            ,{"  "}
            <div className="d-inline pl-1">
              {gqlData.supervisorDetails
                ? gqlData.supervisorDetails.location
                : ""}
            </div>
          </div>
          <div className="supervisor-email">
            {gqlData.supervisorDetails ? (
              <a
                target="_top"
                href={`mailto:${gqlData.supervisorDetails.workEmail}`}
              >
                {gqlData.supervisorDetails.workEmail}
              </a>
            ) : (
              ""
            )}
          </div>
          {gqlData.supervisorDetails && (
            <div className="supervisor-ph">
              {gqlData.supervisorDetails ? (
                <a
                  target="_top"
                  href={`tel:${gqlData.supervisorDetails.workPhoneNumber}`}
                >
                  {gqlData.supervisorDetails.workPhoneNumber}
                </a>
              ) : (
                ""
              )}
            </div>
          )}
        </div>
      </div>
    );
  };

  const HideShowButton = () => {
    return (
      <div className={styles.mobileShow}>
        <div>
          <div style={{ display: visible ? "block" : "none" }}>
            {Details(data)}
          </div>
          <div className={styles.hideShowHolder}>
            <TextLink
              iconRight="chevron-down"
              variant="small"
              onClick={() => setVisible(!visible)}
            >
              {visible ? "Hide" : "Show"}
            </TextLink>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className={`${styles.aucontainer} col-12`}>
      <div className={styles.profileBar}>
        <div className={styles.profileDetails}>
          <div className={styles.profileIcon}>
            <span>
              <button>
                {gqlData.profile
                  ? gqlData.profile.fullName
                      .match(/(\b\S)?/g)
                      .join("")
                      .match(/(^\S|\S$)?/g)
                      .join("")
                      .toUpperCase()
                  : ""}
              </button>
            </span>
          </div>
          <div className={styles.info}>
            <div className={styles.infoname}>
              {gqlData.profile ? gqlData.profile.fullName : ""}
            </div>
            <div className={styles.personalsubtitle}>
              {data[1].data.dictionaryLables?.employee_id}{" "}
              {gqlData.profile ? gqlData.profile.employeeId : ""}
            </div>
            {gqlData.profile && (
              <div className={styles.personalsubtitle}>
                {gqlData.profile.businessTitle
                  ? gqlData.profile.businessTitle.trim()
                  : ""}
                {gqlData.profile.businessTitle &&
                gqlData.profile.primaryLocation
                  ? ", "
                  : ""}
                {gqlData.profile.primaryLocation
                  ? gqlData.profile.primaryLocation
                  : ""}
              </div>
            )}
          </div>
        </div>
        {isMobile ? HideShowButton() : Details(data)}
      </div>
    </div>
  );
};

export default ProfileUserInfo;
