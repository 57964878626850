import { useEffect } from "react";
import { apiMiddlewareClient } from "../../features/api-wrapper/apiMiddlewareWrapper";
import { useLazyQuery } from "@apollo/client";
import { GET_SAVING_PLAN_DETAILS } from "../../features/api-wrapper/apiQueries";
import moment from "moment";

const use401K = () => {
  const cLocation = location.pathname.split("/").pop();
  const [mutate401kFunction, { error, data }] = useLazyQuery(
    GET_SAVING_PLAN_DETAILS,
    {
      enabled: false,
      manual: true,
      fetchPolicy: "no-cache",
      client: apiMiddlewareClient,
    }
  );
  console.log(error);

  useEffect(() => {
    if (cLocation === "401-k-savings-plan") {
    mutate401kFunction({
      // variables: { date: "2024-08-01-01:00" },
      variables: { date: moment().format("Y-MM-DD-hh:mm") },
    });}
  }, []);

  return { error, data };
};

export default use401K;
