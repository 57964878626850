import { useEffect, useState } from "react";
import ConfirmationPopup from "../global/ConfirmPrompt";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import NewHireAccesDelayModal from "../global/NewHireAccesDelayModal";
import Axios from "axios";
import { getLocalStoreValue } from "../../utils/localStore";
import { MessagePopUp } from "../global/ConfirmPrompt";

const axiosBaseURL = Axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

const InnerHtml = (props) => {
  const language = useSelector(
    (state) => state.gqlData?.profile?.preferredLanguage
  );
  let lang = language === undefined || language === "EN" ? "en-us" : language;
  let { description, hash, style, ariaLabel,trackAnchorClick } = props;
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [error,setError] = useState();
  const [isContinue, setIsContinue] = useState(false);
  const [url, setUrl] = useState();
  const globalNotification = useSelector((state) => state.globalNotification);
  const { whiteListedDomain } = globalNotification || [];
  const [isNewHireModal, setNewHireModal] = useState(false);
  const isNewHire = useSelector((state) => state.gqlData?.profile?.newHire);

  const hidePopup = () => {
    setNewHireModal(false);
  };
  const hideMsgPopup = ()=>{
    setIsErrorModalOpen(false);
  }
  const onContainerClick = (e) => {
    e.preventDefault();
    let currentTag = e.target;
    let isNotFoundAnchor = true;
    console.log('currentTag ###',currentTag);
    while (
      currentTag.getAttribute("name") !== "inner-html-parent" &&
      isNotFoundAnchor
    ) {
      if (currentTag.tagName.toLowerCase() === "a") {
        isNotFoundAnchor = false;
        if(typeof trackAnchorClick == 'function')
        {
          trackAnchorClick(true);
          
        }
        if (currentTag.target === "_blank") {
          setUrl(currentTag.getAttribute("href"));
          if (
            currentTag.getAttribute("href").includes("title:") &&
            currentTag.getAttribute("href").includes("contentType:")
          ) {
            let splittedArr = currentTag
              .getAttribute("href")
              .split("{")
              .join("")
              .split("}")
              .join("")
              .split("&");
            let queryString = "?";
            for (let i = 0; i < splittedArr.length; i++) {
              let arr = splittedArr[i].split(":");
              queryString += `${arr[0]}=${arr[1]}`;
              if (i < splittedArr.length - 1) queryString += "&";
            }
            queryString += `&locale=${lang}`;
            let token = getLocalStoreValue("token");
            let apigee_token = getLocalStoreValue("apigee_token");
            axiosBaseURL
              .get(`${process.env.REACT_APP_CS_ASSET_URL}${queryString}`, {
                headers: {
                  Authorization: `Bearer ${apigee_token}`,
                  jwt: token,
                },
              })
              .then((response) => {
                if (response?.status == 200) {
                  if (response?.data) {
                    const blob = base64ToBlob(
                      response?.data,
                      response?.headers?.['content-type']
                    );
                    const _url = URL.createObjectURL(blob);
                    if (navigator.userAgent.indexOf('Mac OS X') != -1) {
                      window.location.assign(_url);
                    } else {
                      
                      window.open(_url, "_blank");
                    }
                    
                  }
                }
              })
              .catch((error) => {
                console.error("Error: ", error);
                setError(error);
                setIsErrorModalOpen(true);
              });
          } else if (isNewHire && url.includes("neocase")) {
            setNewHireModal(true);
          } else {
            let tempUrl = currentTag.getAttribute("href");
            whiteListedDomain.some((v) => {
              return tempUrl ? tempUrl.includes(v) : false;
            })
              ? window.open(tempUrl, "_blank")
              : setIsModalOpen(true);
          }
        } else {
          navigate(currentTag.getAttribute("href"));
        }
      }
      currentTag = currentTag.parentNode;
    }
  };

  const base64ToBlob = (base64, type = "application/octet-stream") => {
    const binStr = atob(base64);
    const len = binStr.length;
    const arr = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      arr[i] = binStr.charCodeAt(i);
    }
    return new Blob([arr], { type: type });
  };

  useEffect(() => {
    if (isContinue) {
      setIsModalOpen(false);
      setIsContinue(false);
      window.open(url, "_blank");
    }
  }, [isContinue]);

  return (
    <>
      <div
        name="inner-html-parent"
        onClick={(e) => onContainerClick(e)}
        data-cslp={hash}
        className={`${style} inner-html-parent`}
        aria-label={ariaLabel}
        dangerouslySetInnerHTML={{
          __html: description,
        }}
      ></div>
      {isModalOpen && (
        <ConfirmationPopup
          isModalOpen={isModalOpen}
          onContinue={setIsContinue}
          onClose={() => {
            setIsModalOpen(false);
          }}
        />
      )}
      {isErrorModalOpen && (<MessagePopUp onClose={hideMsgPopup} error={error}/>)}
      {isNewHireModal && <NewHireAccesDelayModal onClose={hidePopup} />}
    </>
  );
};

export default InnerHtml;
