import { useEffect } from "react";

export default (key, handler) => {
  useEffect(() => {
    const listener = (event) => {
      if (event.key == key) {
        handler(event);
      }
    };
    window.addEventListener("keydown", listener);
    return () => window.removeEventListener("keydown", listener);
  }, [handler, key]);
};
