import { Button } from "@mgmresorts/mgm-ui";
import { createPortal } from "react-dom";
import { useDispatch, useSelector } from "react-redux";
import styles from "./ConfirmPrompt.module.css";
import { setGoBack, setContinue, setConfirmationPopupVisibility } from "./../../data/notification-slice";
import { useEffect } from "react";

const Backdrop = (props) => {
  return <div className={styles.backdrop} onClick={props.onClose} />;
};
const ConfirmationDialogue = (props) => {
  const globalNotification = useSelector((state) => state.globalNotification);
  const { promptMessages } = globalNotification || {};
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setConfirmationPopupVisibility(true));
    return () => {
      dispatch(setConfirmationPopupVisibility(false));
    };
  }, [])
  return (
    <div className={styles.modal}>
      <div>
        <div className={styles.modalHeader}>
          <div className={`mb-2 ${styles.imgHolder}`}>
            <img
              src={props.fieldEdited ? promptMessages[1]?.icon?.url : promptMessages[0]?.icon?.url}
              alt={props.fieldEdited ? promptMessages[1]?.icon?.filename : promptMessages[0]?.icon?.filename}
            />
          </div>
          <h3 className="mb-2">{props.fieldEdited ? promptMessages[1]?.heading : promptMessages[0]?.heading}</h3>
        </div>
        <div
          dangerouslySetInnerHTML={{ __html: props.fieldEdited ? promptMessages[1]?.description : promptMessages[0]?.description }}
        ></div>
        <div className={styles.content}>{props.children}</div>
        <div className={` mt-4 ${styles.btnContainer}`}>
          <Button
            label={props.fieldEdited ? promptMessages[1]?.button_go_back_label : promptMessages[0]?.button_go_back_label}
            variant="secondary"
            className={`${styles.button} back`}
            onClick={() => {
              dispatch(setGoBack('back'))
              props.onClose(true);
            }}
          />
          <Button
            label={props.fieldEdited ? promptMessages[1]?.button_continue_label : promptMessages[0]?.button_continue_label}
            variant="primary"
            className={`${styles.button} continue`}
            onClick={() => {
              dispatch(setContinue('continue'))
              props.onContinue(true);
              props.onClose(true);
            }}
          />
        </div>
      </div>
    </div>
  );
};
const portalElement = document.getElementById("portal");

const ConfirmationPopup = (props) => {
  return (
    <>
      {createPortal(<Backdrop onClose={props.onClose} />, portalElement)}
      {createPortal(
        <ConfirmationDialogue
          onClose={props.onClose}
          heading={props.heading}
          onContinue={props.onContinue}
          fieldEdited={props.fieldEdited}
        >
          {props.children}
        </ConfirmationDialogue>,
        portalElement
      )}
    </>
  );
};

export default ConfirmationPopup;

const MessageDialogue = (props) => {
  const globalNotification = useSelector((state) => state.globalNotification);
  const { globalProperties } = globalNotification;
  
  return (<div className={styles.modal}>
    <div>
      <div>{globalProperties?.no_access_document_message?globalProperties?.no_access_document_message:props.error}</div>
      <div className={` mt-4 ${styles.btnContainer}`}>
        <Button
          label={globalProperties?.ok}
          variant="secondary"

          onClick={() => {

            props.onClose(true);
          }}
        />
      </div>
    </div>
  </div>)
}

const MessagePopUp = (props) => {
  //console.log('props.error >>>#',props.error.message);
  let errMsg = "";
  if (props.error && props.error.graphQLErrors && props.error.graphQLErrors[0]) {
    errMsg = props.error.graphQLErrors[0]?.message;
  }
  else if (props.error && props.error.message) {
    errMsg = props.error?.message;
  }
  return (<>
    {createPortal(<Backdrop onClose={props.onClose} />, portalElement)}
    {createPortal(<MessageDialogue error={errMsg} onClose={props.onClose}/>, portalElement)}

  </>)
}
export { MessagePopUp };
