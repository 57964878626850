import { apiMiddlewareClient } from "../../features/api-wrapper/apiMiddlewareWrapper";
import { GET_ATTENDANCE_DETAILS } from "../../features/api-wrapper/apiQueries";
import "../../styles/attendance.css";
import { useQuery } from "@apollo/client";
// import moment from "moment";
import APIError from "../../pages/ErrorHandling/APIError";
import Loader from "../global/Loader";
import { useNavigate } from "react-router-dom";

const AttendanceCard = (props) => {
  const navigate = useNavigate();
  const { loading, error, data } = useQuery(GET_ATTENDANCE_DETAILS, {
    client: apiMiddlewareClient,
  });
  if (loading) return <Loader />;
  if (error) return <APIError error={error} />;
  const attendanceData = data.attendanceLeaveBalance;
  props.apiData(attendanceData);

  const time_lefttitle =
    props?.attdata?.data?.dictionaryLables?.your_attendance;
  const time_lefttitle_subheading =
    props?.attdata?.data?.dictionaryLables?.balance_today;
  // const attendnc_avlDays =
  //   props?.attdata?.data?.dictionaryLables?.available_days;
  const time_disclaimer =
    props?.attdata?.data?.dictionaryLables?.all_employees_receive;

  const attendane_policy_url =
    props?.attdata?.data?.links_dictionary[0] &&
    props?.attdata?.data?.links_dictionary[0].link?.title;
  const attendane_policy_redirect =
    props?.attdata?.data?.links_dictionary[0] &&
    props?.attdata?.data?.links_dictionary[0].link?.href;
  const attendane_policy_target =
    props?.attdata?.data?.links_dictionary[0] &&
    props?.attdata?.data?.links_dictionary[0].open_in;

  // const new_start_date = moment(
  //   data.attendanceLeaveBalance[0]?.allocationStartDate
  // ).format("MM/DD/YYYY");
  // const new_end_date = moment(
  //   data.attendanceLeaveBalance[0]?.allocationEndDate
  // ).format("MM/DD/YYYY");
  // const attendnce_overlimit_heading =
  //   props?.attdata?.data?.dictionaryLables?.absences_over_limit?.replace(
  //     "{variable-overlimit}",
  //     Math.abs(data.attendanceLeaveBalance[0]?.remainingDays)
  //   );
  // const attendnce_overlimit_subheading =
  //   props?.attdata?.data?.dictionaryLables?.you_have_exceeded_your_permitted?.replace(
  //     "{variable-exceeded}",
  //     Math.abs(data.attendanceLeaveBalance[0]?.remainingDays)
  //   );

  const clickHandler = (e) => {
    e.preventDefault();
    if(attendane_policy_target == "new_window") {
      window.open(attendane_policy_redirect, "_blank");
    } else if(attendane_policy_target == "same_window") {
      navigate(attendane_policy_redirect);
    }
  };

  return (
    <section className={props.attendanceCard}>
      <div className="col-md-12 global_nopadding timeoff_leftsection_attendance">
        <p className="timeoff_header_attendance">{time_lefttitle}</p>
       
          <p className="allotment_header">
            {time_lefttitle_subheading} 
            {/* ({new_start_date}-{new_end_date}) */}
          </p>
       
        <div>
          {data.attendanceLeaveBalance.length > 0 
          // &&
          // attendanceData[props.selectedLocationTab]?.remainingDays >= 0 
          ? (
            <div>
              <div className="timeoff_attendance">
                <p className="timeoff_count">
                  {attendanceData[
                    props.selectedLocationTab
                  ]?.remainingDays.toFixed(1)}
                </p>
                {/* <p className="timeoff_days">{attendnc_avlDays}</p> */}
              </div>
            </div>
          ) : 
          // data.attendanceLeaveBalance.length > 0 ? (
          //   <div className="overtime_attendance">
          //     <div className="overlimit_heading">
          //       {attendnce_overlimit_heading}
          //     </div>
          //     <div
          //       className="overlimit_content"
          //       dangerouslySetInnerHTML={{
          //         __html: attendnce_overlimit_subheading,
          //       }}
          //     ></div>
          //   </div>
          // ) : 
          (
            ""
          )}
          {data.attendanceLeaveBalance.length > 0 &&
          attendanceData[props.selectedLocationTab]?.remainingDays >= 0 ? (
            <p className="attendance-border"></p>
          ) : (
            ""
          )}
          <p className="timeoff_disclaimer">{time_disclaimer}</p>
          <a
            className="timeoff_ptolink"
            href={attendane_policy_redirect}
            onClick={clickHandler}
            rel="noreferrer"
          >
            {attendane_policy_url}
          </a>
        </div>
      </div>
    </section>
  );
};

export default AttendanceCard;
