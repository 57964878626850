import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  recognitionSubmited: false,
};

const recognitionSlice = createSlice({
  name: "navigation",
  initialState,
  reducers: {
    setRecognitionSubmited(state, action) {
      state.recognitionSubmited = action.payload;
      console.log(
        "############################### - Recognition submited" +
          action.payload
      );
    },
    isRecognitionSubmited(state) {
      console.log(
        "############################### - isRecognitionSubmited" + state
      );
      return state.recognitionSubmited;
    },
  },
});

export const recognitionActions = recognitionSlice.actions;
export default recognitionSlice;
