import { Button } from "@mgmresorts/mgm-ui";
import styles from "./Recognition.module.css";
import useFetchData from "../../hooks/useFetchData";
import { getBadgeIcon, toTitleCase } from "./RecognizationUtility";

import { useQuery } from "@apollo/client";
import { apiMiddlewareClient } from "../../features/api-wrapper/apiMiddlewareWrapper";
import { useState, useEffect } from "react";
import { FEEDBACK_RE_GI } from "../../features/api-wrapper/apiQueries";
import FormModal from "../global/ui/FormModal";
import { authActions } from "../../data/authentication-slice";
import { useDispatch } from "react-redux";
import APIError from "../../pages/ErrorHandling/APIError";
const max_badge_count = 4;

const Recognition = (props) => {
  const [isFormModalOpen, setIsFormModalOpen] = useState(false);
  const [apiData, setapiData] = useState();
  const [badgesA, setBadgesA] = useState([]);

  const { data: badgeData } = useFetchData("", "recognition_badges");
  const dispatch = useDispatch();

  const {
    dictionary_with_icon: cs_icon,
    dictionaryLables: cs_text,
    links_dictionary: cs_link,
  } = props.data;
  console.log("called ###");
  const {
    loading,
    error,
    data: apiDataN,
  } = useQuery(FEEDBACK_RE_GI, {
    client: apiMiddlewareClient,
    fetchPolicy: "cache-first",
  });
  console.log(loading, error, apiDataN);
  console.log("props.data ###", props.data);
  useEffect(() => {
    if (apiDataN?.feedbackReceived?.recognitionMetrics?.length) {
      var badges = [...apiDataN.feedbackReceived.recognitionMetrics]
        .sort((a, b) => b.count - a.count)
        .slice(0, max_badge_count);

      setapiData(badges);
    }
  }, [apiDataN]);

  useEffect(() => {
    badgeData &&
      setBadgesA(
        badgeData[0]?.data?.badges?.map((d) => {
          return { ...d, icon: d?.icon?.filename };
        })
      );
    dispatch(authActions.setBadgeData(badgeData));
  }, [badgeData]);

  const openSubmitRecognitionForm = () => {
    setIsFormModalOpen(true);
  };

  return (
    <div>
      <div className={styles.recogBlock1}>
        <session style={{ width: "100%" }}>
          <div className={styles.myRecog}>
            <div className={styles.recogImp}>
              {/* <h2>Why is recognition so important?</h2> */}
              <h2>{cs_text?.left_column_heading}</h2>
              <p>
                <div
                  // className="overlimit_content"
                  dangerouslySetInnerHTML={{
                    __html: cs_text?.left_column_content,
                  }}
                />
              </p>
              {/* <br /> */}
              {/* <p className={styles.textUnderline}>How to give recognition</p> */}
            </div>

            <div className={`${styles.boxStyling} ${styles.giveRecognizeBox}`}>
              {/* <div style={{display:flex}}> */}
              <img className={styles.chatIcon} src={cs_icon[0]?.url} />
              <div className={styles.giveRBoxContentOuter}>
                <div className={styles.giveRBoxContent}>
                  {/* <h3>Give Recognition!</h3> */}
                  <h3>{cs_icon[0]?.value}</h3>
                  {/* <p>Lorem ipsum dolor amet. Lorem ipsum dolor amet.</p> */}
                  <p>
                    <div
                      // className="overlimit_content"
                      dangerouslySetInnerHTML={{
                        __html: cs_text?.recognition_someone_content,
                      }}
                    />
                  </p>
                </div>
                {/* </div> */}
                {isFormModalOpen && cs_link && (
                  <FormModal
                    isFormModalOpen={isFormModalOpen}
                    heading={cs_text?.recognize_someone_form_heading}
                    formURL={cs_link[0]?.link?.href}
                    onClose={() => {
                      setIsFormModalOpen(false);
                    }}
                  />
                )}
                {cs_link && (
                  <Button
                    variant="primary"
                    className={styles.recogSomeoneButton}
                    onClick={openSubmitRecognitionForm}
                  >
                    {cs_link[0]?.link?.title}
                  </Button>
                )}
              </div>
            </div>
          </div>
        </session>

        <session>
          <div className={styles.myRecogStatus}>
            {/* <h2>My Recognition</h2> */}
            <h2>{cs_text.right_column_heading}</h2>
            <div className={styles.recogTileContainer}>
              {/* //todo: Api call */}
              {error ? (
                <APIError error={error} />
              ) : (
                <>
                  {apiData &&
                    apiData.map((d, index) => (
                      <RecogIconTiles
                        key={index}
                        number={d.count}
                        // badgeId={d.badgeID}
                        iconData={
                          badgesA &&
                          badgesA
                            .map((badge) => badge.name == d.badgeID && badge)
                            .filter((d) => d)[0]
                        }
                      />
                    ))}
                </>
              )}
            </div>
          </div>
        </session>
      </div>
    </div>
  );
};

const RecogIconTiles = (props) => {
  const { number, iconData } = props;

  //todo: spacing correct
  return (
    <div className={styles.rTiles}>
      <img src={getBadgeIcon(iconData?.icon)} className={styles.rTileIcon} />
      <p>{toTitleCase(iconData?.value)}</p>
      <p className={styles.number}>{number}</p>
    </div>
  );
};

export default Recognition;
