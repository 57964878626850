import { useEffect, useRef, useState } from "react";

import styles from "./customSelectDropdownWithIcon.module.css";
import { Icon } from "@mgmresorts/mgm-ui";

const CustomSelectDropDown = ({
  id,
  optionsList,
  label,
  onChange,
  isType2,
}) => {
  const [isOptionsOpen, setIsOptionsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState();
  const dropdownWrapperRef = useRef(null);
  const toggleOptions = () => {
    setIsOptionsOpen(!isOptionsOpen);
  };
  console.log("selectedOption ###", optionsList);
  const handleClickOutside = (e) => {
    if (!dropdownWrapperRef.current.contains(e.target)) {
      setIsOptionsOpen(false);
    }
  };

  useEffect(() => {
    if (isOptionsOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    }
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [isOptionsOpen]);

  const selectedValue = (event, index) => {
    event.preventDefault();
    setSelectedOption(index);
    onChange(index, optionsList[index]);
    setIsOptionsOpen(!isOptionsOpen);
  };

  let cDropdownStyle;
  if (isType2 == "TYPE_3") {
    //type 3
    cDropdownStyle =
      styles.customDropdownType2 + " " + styles.customDropdownType3;
  } else {
    if (isType2) {
      //type 2
      cDropdownStyle = styles.customDropdownType2;
    } else {
      //type 1
      cDropdownStyle = styles.customDropdownType1;
    }
  }
  // isType2 ? styles.customDropdownType2 : styles.customDropdownType1

  return (
    <div className={cDropdownStyle} id={id} ref={dropdownWrapperRef}>
      {
        <div className={styles.container}>
          <button
            type="button"
            onClick={toggleOptions}
            className={styles.dropDownButton}
          >
            <div className={styles.textWrap}>
              {typeof selectedOption != "undefined" && (
                <span>
                  <img
                    className={styles.icon}
                    src={optionsList[selectedOption]?.icon?.url}
                  />
                </span>
              )}
              {typeof selectedOption == "undefined"
                ? label
                : optionsList[selectedOption].name}
            </div>
            <div className={styles.arrow}>
              <Icon
                name={"chevron-up"}
                size="small"
                className={
                  isOptionsOpen ? styles.arrowDown : styles.arrowDownActive
                }
              />
            </div>
          </button>
          {isOptionsOpen && (
            <ul role="listbox" className={styles.customList}>
              {optionsList.map((data, index) => {
                return (
                  <li
                    className={styles.optionList}
                    key={index}
                    role="option"
                    tabIndex={0}
                    aria-selected={selectedOption === index}
                    onClick={(eve) => {
                      selectedValue(eve, index);
                    }}
                  >
                    <span>
                      <img className={styles.icon} src={data?.icon?.url} />
                    </span>
                    <span className={styles.iconLabel}>{data.name}</span>
                  </li>
                );
              })}
            </ul>
          )}
        </div>
      }
    </div>
  );
};

export default CustomSelectDropDown;
