import { useQuery } from "@apollo/client";
import moment from "moment";
import "moment/locale/es";
import InnerHtml from "../../components/dynamic/InnerHtml";
import Loader from "../../components/global/Loader";
import { apiMiddlewareClient } from "../../features/api-wrapper/apiMiddlewareWrapper";
import { GET_EDR_MENU } from "../../features/api-wrapper/apiQueries";
import APIError from "../ErrorHandling/APIError";
import styles from "./ViewEDRMenu.module.css";

const ViewEDRProperties = (props) => {
  const { dictionary_with_icon } = props.data;
  const { url, name } = dictionary_with_icon[1] || {};
  // const primaryLocation = useSelector(
  //   (state) => state.gqlData?.profile?.primaryLocation
  // );

  const { error, loading, data } = useQuery(GET_EDR_MENU, {
    variables: {
      currentDate: moment(new Date()).format("YYYY-MM-DD"),
      // propertyName: primaryLocation,
    },
    fetchPolicy: "no-cache",
    client: apiMiddlewareClient,
  });

  const openPDF = (index) => {
    const binaryString = window.atob(data.getEDRMenu[index].file);
    const bytes = new Uint8Array(binaryString.length);
    const mappedData = bytes.map((byte, i) => binaryString.charCodeAt(i));
    const file = new Blob([mappedData], {
      type: "application/pdf",
    });
    //Build a URL from the file
    const fileURL = URL.createObjectURL(file);
    //Open the URL on new Window
    const pdfWindow = window.open();
    pdfWindow.document.title = `${data.getEDRMenu[index].startDate} - ${data.getEDRMenu[index].endDate}`;
    pdfWindow.location.href = fileURL;
  };

  if (loading) return <Loader />;
  if (error)
    return (
      <div className="mt-3 api-error">
        <APIError error={error} />
      </div>
    );

  return (
    <>
      {data && data.getEDRMenu.length > 0 ? (
        data.getEDRMenu.map((viewItems, index) => {
          const starteDatee = moment
            .utc(viewItems.startDate)
            .format("MM/DD/YY");
          const endDatee = moment.utc(viewItems.endDate).format("MM/DD/YY");
          return (
            <div
              role="button"
              key={index}
              className={styles.viewEDRMenu}
              onClick={() => {
                openPDF(index);
              }}
            >
              <div>
                <div className={styles.loc}>{viewItems.propertyName}</div>
                <div
                  className={`${styles.dateRange} ${
                    (viewItems.startDate == "" || viewItems.endDate == "") &&
                    styles.noDateRange
                  }`}
                >
                  {starteDatee == "" || endDatee == ""
                    ? "Menu not available, please check back later."
                    : starteDatee == endDatee
                    ? starteDatee
                    : `${starteDatee} - ${endDatee}`}
                </div>
              </div>
              <div className={`${styles.viewEDRIcon} text-right`}>
                <button
                  onClick={() => {
                    openPDF(index);
                  }}
                >
                  <img src={url} alt={name} className="left-arrow" />
                </button>
              </div>
            </div>
          );
        })
      ) : (
        <div className={`${styles.noMenuFoundContainer} mt-4 mb-3`}>
          <div className={styles.imgWrapper}>
            <img
              src={dictionary_with_icon[2]?.url}
              alt={dictionary_with_icon[2]?.name}
            />
          </div>
          <InnerHtml
            description={dictionary_with_icon[2]?.value}
            style={styles.noMenuFound}
          />
        </div>
      )}
    </>
  );
};

export default ViewEDRProperties;
