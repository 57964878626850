// import { Icon } from "@mgmresorts/mgm-ui";
// import RecognizationReceivedModal from "./RecognizationReceivedModal";
// import { useState } from "react";
// import { useEffect, useState } from "react";
// import useFetchData from "../../hooks/useFetchData";
import { Icon } from "@mgmresorts/mgm-ui";
import InitialsAvatar from "react-initials-avatar";
import { formatDate, getBadgeIcon, toTitleCase } from "./RecognizationUtility";

import styles from "./RecognitionReGiContent.module.css";

const RecognitionReGiContent = (props) => {
  const {
    fname: heading,
    feedbackDate: date,
    feedback: content,
    iconData,
    language,
  } = props;
  const noOfRecipient = heading?.split(',')?.length;

  return (
    <div className={`${styles.recReceivedContent}`}>
      <div style={{ display: "flex" }}>
        <div className={styles.rTileIcon}>
          {/* to be updated with correct recognition given and ; to ,*/}
          {noOfRecipient && noOfRecipient > 1 ? <div className={styles.rTileIcon}>
            <div className={`${styles.threePeople}`}>
              <Icon
                name="three-people-plus"
                variant="filled"
                className={`${styles.threePeopleImg}`}
              />
            </div>
          </div> : <InitialsAvatar className={styles.InitialsAvatar} name={heading} />}

        </div>
        <div style={{ width: "100%", alignContent: "center" }}>
          <h5>{heading}</h5>
          <p className={styles.subHeading}>{formatDate(date, language)}</p>
        </div>
      </div>

      <p className={styles.content}>{content}</p>
      <div className={styles.rTilesBigFooter}>
        <div className={styles.footerRight}>
          {iconData && <><img src={getBadgeIcon(iconData?.icon)} />
            <p className={styles.footerRightText}>
              {toTitleCase(iconData?.value)}
            </p></>}
        </div>
      </div>
    </div>
  );
};

export default RecognitionReGiContent;
