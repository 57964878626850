import { Button,Icon } from "@mgmresorts/mgm-ui";
import { useEffect, useState } from "react";
import ReactDOM from "react-dom";
// import closeIcon from "../../../assets/images/symbol-x-black.svg";
import styles from "./QuickActionModal.module.css";

const Backdrop = (props) => {
  return <div className={styles.backdrop} onClick={props.onClose} />;
};

const ModalOverlay = (props) => {
  const showCloseBtn =
    props.showCloseBtn != undefined ? props.showCloseBtn : true;

  return (
    <div className={styles.modal}>
      <div>
        <div className={styles.modalHeader}>
          <h3>{props.heading}</h3>
          {showCloseBtn && (
            <button className={styles.closeButton} onClick={props.onClose} aria-label="close">
              <Icon name="symbol-x" size="small" variant="outlined"/>
            </button>
          )}
        </div>
        <div className={styles.content}>{props.children}</div>
      </div>
    </div>
  );
};

const portalElement = document.getElementById("overlays");

const QuickActionModal = (props) => {

  const [dictionaryLabel, setDictionaryLabel] = useState();
  useEffect(() => {
    if (
      props.selectedModal &&
      props.selectedModal.btnGroup &&
      props.selectedModal.btnGroup.length > 0
    ) {
      let dictionaryLables = {};
      const dictionary = props.selectedModal.btnGroup;
      if (Array.isArray(dictionary)) {
        for (let i = 0; i < dictionary.length; i++) {
          dictionaryLables[dictionary[i].name] =
            dictionary[i].value || dictionary[i].html_value;
        }
      }
      setDictionaryLabel(dictionaryLables);
    }
  }, [props.selectedModal]);

  return (
    <>
      {ReactDOM.createPortal(
        <Backdrop onClose={props.onClose} />,
        portalElement
      )}
      {ReactDOM.createPortal(
        <ModalOverlay
          onClose={props.onClose}
          heading={props.heading}
          showCloseBtn={props.showCloseBtn}
        >
          {props.children}
          {props.finishButton && (
            <div className={styles.buttonHolder}>
              <Button onClick={props.onClose} variant="primary">
                {props.finishLabel}
              </Button>
            </div>
          )}
          {props.selectedModal &&
            props.selectedModal.btnGroup &&
            props.selectedModal.btnGroup.length > 0 && (
              <div className={styles.btnGroup}>
                {dictionaryLabel?.cancel_button_label && (
                  <Button
                    onClick={props.onClose}
                    size="large"
                    variant="secondary"
                  >
                    {dictionaryLabel?.cancel_button_label}
                  </Button>
                )}
                {dictionaryLabel?.visit_workday_button_label && (
                  <Button
                    onClick={() => {
                      window.open(
                        dictionaryLabel.visit_workday_button_url,
                        "_blank"
                      );
                    }}
                    size="large"
                    className={styles.modalPrimaryBtn}
                    variant="primary"
                  >
                    {dictionaryLabel?.visit_workday_button_label}
                  </Button>
                )}
              </div>
            )}
        </ModalOverlay>,
        portalElement
      )}
    </>
  );
};

export default QuickActionModal;
