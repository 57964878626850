//import { useEffect } from "react";
import styles from "./TertiaryNavigation.module.css";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { navigationActions } from "../../data/navigation-slice";
import { useEffect } from "react";

const TertiaryNavigation = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const selectedTertiaryList = useSelector(
    (state) => state.navigation.selectedTertiaryList
  );
  const selectedNavigation = useSelector(
    (state) => state.navigation.selectedNavigation
  );
  
  useEffect(() => {
    dispatch(navigationActions.setSelectedTertiaryList());
  }, [selectedNavigation[2]]);

  const navigationChange = (e) => {
    let navPath = selectedTertiaryList.find((nav)=>nav.label===e.target.value).absoluteLink;
    navigate(navPath);
  }

  let defaultValue = selectedNavigation[2].label;

  return (
    <section className={styles.tertiaryNavigation}>
      <select onChange={navigationChange} value={defaultValue}>
        {selectedTertiaryList.map((nav, index) => (
          <option key={index}>{nav.label}</option>
        ))}
      </select>
    </section>
  );
};

export default TertiaryNavigation;
