import PhoneIcon from "../../assets/phone-analog-icon.svg";
import InnerHtml from "../dynamic/InnerHtml";
// import { Icon } from "@mgmresorts/mgm-ui";

import styles from "./SavingsPlan401K_Contact.module.css";

export const SavingsPlan401K_Contact = (props) => {
  console.log(props);
  const { leftCta, leftCtaEnabled, leftReference } = props.data;

  console.log(leftCta, leftCtaEnabled, leftReference);
  const data = leftReference[0];

  return (
    <session>
      <div className={styles.contactDetails}>
        <img className={styles.phoneIcon} src={PhoneIcon} />
        {/* no icon of phone-analog-circle */}
        {/* <Icon
            name={"phone-analog-circle"}
            color="#373AE5"
            size="small"
            className="left-arrow"
          /> */}

        <h2>{data?.heading}</h2>
        {/* <p className={styles.contactText}>{data?.description}</p> */}
        <InnerHtml
          description={data?.description}
          // hash={modalContent?.$?.description}
        />
      </div>
    </session>
  );
};

export default SavingsPlan401K_Contact;
