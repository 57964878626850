// import React from 'react';
import { useQuery } from "@apollo/client";
import { useDispatch } from 'react-redux';
import { updateGqlFTOData } from '../../data/gqlFTODataReducer';
import {GET_PAST_SIX_MONTH_FTO} from "../../features/api-wrapper/apiQueries";
import { apiMiddlewareClient } from "../../features/api-wrapper/apiMiddlewareWrapper";
import APIError from "../../pages/ErrorHandling/APIError";
 
function GraphQLComponent() {

  const dispatch = useDispatch();

  const { loading, error, data } = useQuery(GET_PAST_SIX_MONTH_FTO,{
    client: apiMiddlewareClient,
  });
  

  if (loading) return <p>Loading...</p>;
 
  if (error) return <APIError error={error} />;

  dispatch(updateGqlFTOData(data));

 
  return (
    <div>
      
    </div>
  );
}
 
export default GraphQLComponent;