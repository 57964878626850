import { Icon } from "@mgmresorts/mgm-ui";
import "moment/locale/es";
import { useState } from "react";
import InnerHtml from "../../components/dynamic/InnerHtml";
import Modal from "../../components/global/ui/Modal";
import styles from "./ViewEDRMenu.module.css";
import ViewEDRProperties from "./ViewEDRProperties";

const ViewEDRMenu = (props) => {
  const { dictionaryLables, links_dictionary, dictionary_with_icon } =
    props.data;
  const { url, value } = dictionary_with_icon[0] || {};
  const { trailing_icon, name_value } = links_dictionary[0];

  let [showPopup, setShowPopup] = useState(false);
  let [selectedModal, setSelectedModal] = useState();

  const openModal = () => {
    let nameValue = {};
    for (let i = 0; i < name_value.length; i++) {
      nameValue[name_value[i].name] =
        name_value[i].value || name_value[i].html_value;
    }
    setSelectedModal({
      heading: nameValue.view_your_menu_label || "",
      body: nameValue.view_your_menu_description || "",
    });
    setShowPopup(true);
  };

  const onPopupClose = () => {
    setShowPopup(false);
  };

  return (
    <div className={styles.scheduleContainer}>
      <div className={styles.toptextholder}>
        <div className={styles.subTextHolder}>
          <div className={styles.offerTitle}>{dictionaryLables?.heading}</div>
        </div>
      </div>

      <div
        className={styles.mySCheduleBoardContainer}
        role="button"
        onClick={openModal}
      >
        <div className={styles.schedule}>
          <div className={styles.profileContainer}>
            <h4 className={styles.ScheduleDay}>
              <img src={url} alt="chef-hat" />
            </h4>

            <div className={styles.myScheduleBlock}>
              <div className={styles.Time}>{value}</div>
              <p className={styles.propertyName}>
                {dictionaryLables.property_name}
              </p>
              <p className={styles.propertyLabel}>
                {dictionaryLables.properties_label}
              </p>
            </div>
          </div>
          <div className={`${styles.viewEDRIcon} text-right`}>
            <Icon
              name={trailing_icon?.icon}
              color="#00012B"
              size="small"
              className="left-arrow"
              onClick={openModal}
            />
          </div>
        </div>
      </div>
      {showPopup && (
        <Modal
          onClose={onPopupClose}
          heading={selectedModal.heading}
          size="medium"
        >
          <InnerHtml
            style={styles.viewEDRDescription}
            description={selectedModal.body}
          />
          <ViewEDRProperties {...props} />
        </Modal>
      )}
    </div>
  );
};

export default ViewEDRMenu;
