import { useLazyQuery } from "@apollo/client";
import moment from "moment";
import "moment/locale/es";
import { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Loader from "../../components/global/Loader";
import { apiMiddlewareClient } from "../../features/api-wrapper/apiMiddlewareWrapper";
import { GET_MY_SCHEDULE } from "../../features/api-wrapper/apiQueries";
import APIError from "../ErrorHandling/APIError";
import MyScheduleMessage from "../Pay/MySchedule/MyScheduleMessage";
import styles from "./MyScheduleBoard.module.css";

export const getScheduleDateRange = (
  startDate,
  endDate,
  type = "days",
  shiftText
) => {
  let fromDate = moment(new Date(startDate));
  let toDate = moment(new Date(endDate));
  let diff = toDate.diff(fromDate, type);
  let range = [];
  for (let i = 0; i <= diff; i++) {
    const myScheduleObj = {
      endTime: "",
      endTime12: "",
      endTime24: "",
      isPrevious:
        new Date(moment(startDate).add(i, type).format("MM/DD/YYYY")) <
        new Date(moment().format("MM/DD/YYYY")),
      isWaitingTime: false,
      leaves: shiftText,
      isToday: moment(startDate).add(i, type).isSame(moment(), "day"),
      location: "",
      notes: null,
      role: "",
      site: "",
      startDate: moment(startDate).add(i, type).format("YYYY-MM-DD"),
      startTime: "",
      startTime12: "",
      startTime24: "",
    };
    range.push(myScheduleObj);
  }
  return range;
};

const MyScheduleBoard = (props) => {
  const { dictionaryLables, links_dictionary, dictionary_with_icon } =
    props.data;
  const { link } = links_dictionary[0];
  const [myScheduleLatest, setMyScheduleLatest] = useState();
  const [messageType, setMessageType] = useState();
  const [getMySchedule, { loading, error, data: myScheduleData }] =
    useLazyQuery(GET_MY_SCHEDULE, {
      fetchPolicy: "no-cache",
      client: apiMiddlewareClient,
    });

  const language = useSelector(
    (state) => state.gqlData?.profile?.preferredLanguage
  );

  if (language) {
    moment.locale(language);
  }

  const globalProperties = useSelector(
    (state) => state.globalNotification?.globalProperties
  );

  useEffect(() => {
    getMySchedule({ variables: {} });
  }, []);

  function formatData(dayWiseScheduleData) {
    const groupedAPIData = dayWiseScheduleData.reduce((acc, value) => {
      if (!acc[value.startDate]) {
        acc[value.startDate] = [];
      }
      acc[value.startDate].push(value);
      return acc;
    }, {});
    const res = Object.entries(groupedAPIData).map(([heading, data]) => ({
      heading:
        globalProperties?.short_day[
          new Date(moment(heading).format("MM/DD/YYYY")).getDay()
        ],
      data: data[0],
      noOfShifts: data.length,
    }));
    return res;
  }

  useEffect(() => {
    if (myScheduleData && myScheduleData?.mySchedule) {
      if (
        myScheduleData?.mySchedule.isAckMessageAvailable ||
        myScheduleData?.mySchedule.isMessageAvailable
      ) {
        return setMessageType(
          myScheduleData?.mySchedule.isAckMessageAvailable &&
            myScheduleData?.mySchedule.isMessageAvailable
            ? "acknowledge"
            : myScheduleData?.mySchedule.isAckMessageAvailable
            ? "acknowledge"
            : "review"
        );
      } else {
        const today = new Date();
        const yesterday = new Date(today.setDate(today.getDate() - 1));
        const dayAfterTomorrow = new Date(today.setDate(today.getDate() + 3));
        const myscheduleDefaultArray = getScheduleDateRange(
          yesterday,
          dayAfterTomorrow,
          "days",
          dictionaryLables?.no_shift_schduled
        );
        if (
          myScheduleData?.mySchedule?.dayWiseSchedule &&
          myScheduleData?.mySchedule?.dayWiseSchedule.length > 0
        ) {
          const scheduleResponse = myScheduleData?.mySchedule;
          let dayWiseScheduleData = scheduleResponse.dayWiseSchedule.map(
            (schedule) => {
              const startTime = schedule.startTime.split(" ");
              const endTime = schedule.endTime.split(" ");
              return {
                ...schedule,
                isWaitingTime: false,
                startTime24: moment(startTime[1], ["h:mm A"]).format("HH:mm"),
                endTime24: moment(endTime[1], ["h:mm A"]).format("HH:mm"),
                startTime12: moment(startTime[1], ["HH:mm"]).format("hh:mm A"),
                endTime12: moment(endTime[1], ["HH:mm"]).format("hh:mm A"),
                isPrevious:
                  new Date(moment(schedule.startDate).format("MM/DD/YYYY")) <
                  new Date(moment().format("MM/DD/YYYY")),
              };
            }
          );
          // delete date from myscheduleDefaultArray array if date exist
          dayWiseScheduleData.forEach((element) => {
            myscheduleDefaultArray.forEach((el, idx) => {
              if (
                moment(element.startDate).format("YYYY-MM-DD") === el.startDate
              ) {
                delete myscheduleDefaultArray[idx];
              }
            });
          });
          const consolidatedData = dayWiseScheduleData.concat(
            myscheduleDefaultArray.filter((el) => el != null)
          );
          const res = formatData(consolidatedData);
          const filteredArray = res.filter((item) => {
            return !item.data.isPrevious;
          });
          setMyScheduleLatest(filteredArray);
        } else {
          const res = formatData(myscheduleDefaultArray);
          const filteredArray = res.filter((item) => {
            return !item.data.isPrevious;
          });
          setMyScheduleLatest(filteredArray);
        }
      }
    }
  }, [myScheduleData]);

  const locationDetails = (row) => {
    return (
      <>
        {row.location && (
          <div className={styles.locationWrapper}>
            <div className={styles.Loc}>{row.location}</div>
          </div>
        )}
        {(row.site || row.role) && (
          <div className={styles.floor}>
            {row.site}
            {row.role && row.site ? `| ${row.role}` : row.role ? row.role : ""}
          </div>
        )}
      </>
    );
  };

  if (loading) return <Loader />;
  if (error)
    return (
      <div className="api-error">
        <APIError error={error} />
      </div>
    );
  
  return (
    <Fragment>
      <div className={styles.scheduleContainer}>
        <div className={styles.toptextholder}>
          <div className={styles.subTextHolder}>
            <div className={styles.offerTitle}>{dictionaryLables.heading}</div>
            <div className={styles.scheduleLink}>
              {link && <Link to={link.href}>{link.title}</Link>}
            </div>
          </div>
        </div>

        {dictionary_with_icon && messageType && (
          <MyScheduleMessage
            data={{
              messageType: messageType,
              url: dictionary_with_icon[messageType == "acknowledge" ? 1 : 0]
                ?.url,
              heading:
                dictionary_with_icon[messageType == "acknowledge" ? 1 : 0]
                  ?.value,
              description:
                messageType == "acknowledge"
                  ? dictionaryLables.VR_notification_acknowledge_description
                  : dictionaryLables.VR_notification_review_description,
              link: {
                label:
                  links_dictionary[messageType == "acknowledge" ? 2 : 1]?.link
                    ?.title,
                url: links_dictionary[messageType == "acknowledge" ? 2 : 1]
                  ?.link?.href,
                openIn:
                  links_dictionary[messageType == "acknowledge" ? 2 : 1]
                    ?.open_in,
              },
            }}
          ></MyScheduleMessage>
        )}

        <div className={styles.mySCheduleBoardContainer}>
          {myScheduleLatest &&
            myScheduleLatest.length > 0 &&
            myScheduleLatest.slice(0, 1).map((mySchedule, index) => {
              const { heading, data, noOfShifts } = mySchedule;
              return (
                <div className={styles.schedule} key={index}>
                  <div className={styles.cardContainerInner}>
                    <div className={styles.profileContainer}>
                      <div className={styles.profileIcon}>
                        {heading && (
                          <button className={styles.ScheduleDay}>
                            {heading.toUpperCase()}
                          </button>
                        )}
                      </div>
                      <div className={styles.myScheduleBlock}>
                        {data && (
                          <div key={index}>
                            {!data.overnight ? (
                              <Fragment>
                                <div className={styles.Time}>
                                  {data.leaves
                                    ? data.leaves
                                    : `${data.startTime24} to ${data.endTime24}`}
                                </div>
                                <div className={styles.Date}>
                                  {moment(data.startDate).format("MMM D, YYYY")}
                                </div>
                                {locationDetails(data)}{" "}
                              </Fragment>
                            ) : (
                              <Fragment>
                                <div className={styles.Time}>
                                  {data.startTime24} {dictionaryLables?.start}{" "}
                                  <span className={styles.pipe}>| </span>
                                  <span className={styles.overnight}>
                                    {dictionaryLables?.overnight}
                                  </span>
                                </div>
                                <div className={styles.Date}>
                                  {moment
                                    .utc(data.startDate)
                                    .format("MMM D, YYYY")}
                                </div>
                                <div className={styles.Time}>
                                  {data.endTime24} {dictionaryLables?.end}
                                </div>
                                {locationDetails(data)}
                              </Fragment>
                            )}
                          </div>
                        )}
                        {noOfShifts > 1 && (
                          <div className={styles.noOfShifts}>
                            <div className={styles.shiftCount}>
                              {noOfShifts}
                            </div>
                            <span>{dictionaryLables?.shifts_label}</span>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </Fragment>
  );
};

export default MyScheduleBoard;
